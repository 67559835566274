import React from "react";
import { Card, Row, Col, Icon } from "antd";
import Tooltip from "react-png-tooltip";
import MedidasTigo from "./MedidasTigo";
import StrokedGaugeTigo from "./StrokedGaugeTigo";
import TablaTigo from "./TablaTigo";
import CumplimientoCorteGauge from "./CumplimientoCorteGauge";

const EmbudoTigo = (props) => {
    return (
        <Row
            gutter={[0, 24]}
            type="flex"
            justify="space-between"
            style={{
                width: "95%",
                height: "auto",
                margin: "auto",
                marginBottom: "25px",
            }}
        >
            <Col xs={24} lg={16}>
                <Card
                    title={
                        <div style={{ fontSize: "18px" }}>
                            <Icon type="funnel-plot" /> Embudo
                        </div>
                    }
                    extra={
                        <div align="right" fontSize="12px">
                            <Tooltip
                                className="GreyTooltip"
                                tooltip={<Icon type="info-circle" />}
                            >
                                <p align="left" style={{ marginBottom: "0em" }}>
                                    - Monto en millones ($) y número (#). <br />
                                </p>
                            </Tooltip>
                        </div>
                    }
                >
                    <MedidasTigo
                        fecha={props.fecha}
                        supervisor={props.supervisor}
                        punto={props.punto}
                    />
                </Card>
            </Col>
            <Col xs={24} lg={7}>
                <Card
                    title={
                        <div style={{ fontSize: "18px" }}>
                            <Icon type="check" /> Cumplimiento Mes
                        </div>
                    }
                    className="card"
                    extra={
                        <div align="right" fontSize="12px">
                            <Tooltip
                                className="GreyTooltip"
                                tooltip={<Icon type="info-circle" />}
                            >
                                <p align="left" style={{ marginBottom: "0em" }}>
                                    - Cumplimiento de presupuesto (Mes y dia).{" "}
                                    <br />
                                </p>
                            </Tooltip>
                        </div>
                    }
                >
                    {/* <EmbudoTigo /> */}
                    <Col xs={24}>
                        <StrokedGaugeTigo
                            fecha={props.fecha}
                            supervisor={props.supervisor}
                            punto={props.punto}
                        />
                    </Col>
                    <Col xs={24}>
                        <CumplimientoCorteGauge
                            fecha={props.fecha}
                            supervisor={props.supervisor}
                            punto={props.punto}
                        />
                    </Col>
                </Card>
            </Col>
            {props.tipoCargo.includes("JEFE TIGO") &&
                props.supervisor === "" &&
                props.punto === "" && (
                    <Col xs={24}>
                        <TablaTigo fecha={props.fecha} />
                    </Col>
                )}
        </Row>
    );
};

export default EmbudoTigo;
