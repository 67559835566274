import React from "react";
import { QueryRenderer } from "@cubejs-client/react";
import { Spin, Empty } from "antd";
import { Pie } from "react-chartjs-2";
import cubejsApi from "../../../cubejsApiBQ";
import "chartjs-plugin-labels";

const COLORS_SERIES = ["#FF6492", "#141446", "#7A77FF"];

const pieRender = ({ resultSet }) => {
    const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 1,
    });
    const data = {
        labels: resultSet.categories().map((c) => c.category),
        datasets: resultSet.series().map((s) => ({
            label: s.title,
            data: s.series.map((r) => r.value),
            backgroundColor: COLORS_SERIES,
            hoverBackgroundColor: COLORS_SERIES,
        })),
    };
    const options = {
        plugins: {
            labels: {
                fontColor: "#fff",
            },
        },
        tooltips: {
            callbacks: {
                label: function (tooltipItem, data) {
                    var dataset = data.datasets[tooltipItem.datasetIndex];
                    var meta = dataset._meta[Object.keys(dataset._meta)[0]];
                    var total = meta.total;
                    var currentValue = dataset.data[tooltipItem.index];
                    var percentage = parseFloat(
                        ((currentValue / total) * 100).toFixed(1)
                    );
                    return (
                        formatter.format(currentValue) +
                        " (" +
                        percentage +
                        "%)"
                    );
                },
                title: function (tooltipItem, data) {
                    return data.labels[tooltipItem[0].index];
                },
            },
        },
    };
    if (resultSet.categories().length === 0) {
        return (
            <Empty
                description={
                    <span>Este mes no tienes solicitudes en procesos.</span>
                }
            />
        );
    } else {
        return <Pie data={data} options={options} width={80} height={80} />;
    }
};

const renderChart = (Component) => ({ resultSet, error }) =>
    (resultSet && <Component resultSet={resultSet} />) || <Spin />;

const PieRender = (props) => (
    <QueryRenderer
        query={{
            renewQuery: true,
            measures: ["ConsultaPie.monto"],
            filters: [
                {
                    dimension: "ConsultaPie.fechaCorte",
                    operator: "contains",
                    values: props.fecha !== "" ? [props.fecha] : [],
                },
                {
                    dimension: "Usuarios.idCedula",
                    operator: "contains",
                    values: props.cedula !== "" ? [props.cedula] : [],
                },
                {
                    dimension: "ConsultaPie.zona",
                    operator: "contains",
                    values: props.zona !== "" ? [props.zona] : [],
                },
            ],
            dimensions: ["ConsultaPie.tipoDesembolso"],
            segments: [],
        }}
        cubejsApi={cubejsApi}
        render={renderChart(pieRender)}
    />
);

export default PieRender;
