import React from "react";
import { QueryRenderer } from "@cubejs-client/react";
import { Spin, Row, Typography } from "antd";
import NumberFormat from "react-number-format";
import cubejsApi from "../../../cubejsApiBQ";

const { Title } = Typography;
var color;
var valor;

const porcentajesMicrocredito = ({ resultSet, props }) => {
    const name = props.porcentajes;
    valor = resultSet.loadResponse.data[0][name[props.index]];
    if (valor === null) {
        valor = "-";
        color = "#769995";
    } else if (valor < 0) color = "#FF615E";
    else if (valor > 0) color = "#30B359";
    else color = "#769995";
    return (
        <Row gutter={[0, 0]} type="flex" justify="center">
            <Title
                style={{
                    fontSize: "16px",
                    color: color,
                }}
            >
                <NumberFormat
                    value={valor}
                    decimalScale={1}
                    displayType={"text"}
                    thousandSeparator={true}
                    suffix={"%"}
                />
            </Title>
        </Row>
    );
};

const renderChart = (Component, props) => ({ resultSet, error }) =>
    (resultSet && <Component resultSet={resultSet} props={props} />) || (
        <Spin />
    );

const PorcentajesMicrocredito = (props) => {
    const measures = [
        "ResumenMicrocredito.porcentajeRecogido",
        "ResumenMicrocredito.porcentajeComparacionDesembolso",
        "ResumenMicrocredito.porcentajeComparacionConsulta",
    ];

    return (
        <QueryRenderer
            query={{
                renewQuery: true,
                measures: measures,
                timeDimensions: [],
                filters: [
                    {
                        dimension: props.fechaCorte,
                        operator: "contains",
                        values: props.fecha !== "" ? [props.fecha] : [],
                    },
                    {
                        dimension: "Usuarios.idCedula",
                        operator: "contains",
                        values: props.cedula !== "" ? [props.cedula] : [],
                    },
                    {
                        dimension: "Usuarios.zona",
                        operator: "contains",
                        values: props.zona !== "" ? [props.zona] : [],
                    },
                ],
                dimensions: [],
            }}
            cubejsApi={cubejsApi}
            render={renderChart(porcentajesMicrocredito, props)}
        />
    );
};

export default PorcentajesMicrocredito;
