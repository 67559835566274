import React from "react";
import { QueryRenderer } from "@cubejs-client/react";
import { Spin, Row, Col, Card, Typography } from "antd";
import NumberFormat from "react-number-format";
import cubejsApi from "../../../cubejsApiBQ";

import "../../../styles/boxRender.css";
import CantidadesMicrocredito from "./CantidadesMicrocredito";
import PorcentajesMicrocredito from "./PorcentajesMicrocredito";

const { Title, Text } = Typography;

const COLORS_SERIES = [
    "#b1abe9",
    "#b1abe9",
    "#8dc1ca",
    "#b1abe9",
    "#b1abe9",
    "#8dc1ca",
    "#5cc8d6",
    "#80c6b8",
    "#98c3a6",
    "#96c593",
];
const PREFIX = ["$", "$", "#", "$", "$", "#"];

const medidasMicrocredito = ({
    resultSet,
    props,
    fechaCorte,
    porcentajes,
    cantidades,
}) => {
    const NAME_SERIES = [
        "Radicados",
        "Aprobados",
        "Consultas",
        "Valor Recogido",
        "Desembolsos",
        "Pendiente Por Tramite",
    ];

    return (
        <Row gutter={[0, 20]} type="flex" justify="space-around">
            {resultSet.seriesNames().map((s, index) => {
                return (
                    <Col key={index} xs={24} lg={7}>
                        <Card
                            size="small"
                            className="topCard"
                            style={{
                                borderTop: "3px solid " + COLORS_SERIES[index],
                            }}
                            bodyStyle={{ padding: "5px 10px 0px 10px" }}
                        >
                            <Row style={{ marginBottom: "5px" }}>
                                <Col>
                                    <Text className="cardTitle">
                                        {NAME_SERIES[index]}
                                    </Text>
                                </Col>
                            </Row>
                            <Row
                                gutter={0}
                                type="flex"
                                justify="space-between"
                                align="middle"
                            >
                                <Col xs={10}>
                                    <Title
                                        style={{
                                            fontSize: "16px",
                                        }}
                                    >
                                        <NumberFormat
                                            value={resultSet.totalRow()[s.key]}
                                            decimalScale={1}
                                            displayType={"text"}
                                            thousandSeparator={true}
                                            prefix={PREFIX[index]}
                                        />
                                    </Title>
                                </Col>
                                <Col xs={3}>
                                    <CantidadesMicrocredito
                                        {...props}
                                        index={index}
                                        cantidades={cantidades}
                                        fechaCorte={fechaCorte}
                                        cedula={props.cedula}
                                        zona={props.zona}
                                    />
                                </Col>
                                <Col xs={6}>
                                    <PorcentajesMicrocredito
                                        {...props}
                                        index={index}
                                        porcentajes={porcentajes}
                                        fechaCorte={fechaCorte}
                                        cedula={props.cedula}
                                        zona={props.zona}
                                    />
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                );
            })}
        </Row>
    );
};

const renderChart = (
    Component,
    props,
    fechaCorte,
    porcentajes,
    cantidades
) => ({ resultSet, error }) =>
    (resultSet && (
        <Component
            resultSet={resultSet}
            props={props}
            fechaCorte={fechaCorte}
            porcentajes={porcentajes}
            cantidades={cantidades}
        />
    )) || <Spin />;

const MedidasMicrocredito = (props) => {
    const measures = [
        "ResumenMicrocredito.valorRadicados",
        "ResumenMicrocredito.valorAprobados",
        "ResumenMicrocredito.consultasViables",
        "ResumenMicrocredito.valorRecogido",
        "ResumenMicrocredito.valorDesembolsos",
        "ResumenMicrocredito.pendientesTramite",
    ];
    const fechaCorte = "ResumenMicrocredito.fechaCorte";
    const cantidades = [
        "ResumenMicrocredito.cantidadRadicados",
        "ResumenMicrocredito.cantidadAprobados",
        "",
        "",
        "ResumenMicrocredito.cantidadDesembolsos",
        "",
    ];
    const porcentajes = [
        "",
        "",
        "ResumenMicrocredito.porcentajeComparacionConsulta",
        "ResumenMicrocredito.porcentajeRecogido",
        "ResumenMicrocredito.porcentajeComparacionDesembolso",
        "",
    ];

    return (
        <QueryRenderer
            query={{
                renewQuery: true,
                measures: measures,
                timeDimensions: [],
                filters: [
                    {
                        dimension: fechaCorte,
                        operator: "contains",
                        values: props.fecha !== "" ? [props.fecha] : [],
                    },
                    {
                        dimension: "Usuarios.idCedula",
                        operator: "contains",
                        values: props.cedula !== "" ? [props.cedula] : [],
                    },
                    // {
                    //   dimension: "ResumenMicrocredito.plaza",
                    //   operator: "contains",
                    //   values: [props.plazas]
                    // }
                    {
                        dimension: "Usuarios.zona",
                        operator: "contains",
                        values: props.zona !== "" ? [props.zona] : [],
                    },
                ],
                dimensions: [],
            }}
            cubejsApi={cubejsApi}
            render={renderChart(
                medidasMicrocredito,
                props,
                fechaCorte,
                porcentajes,
                cantidades
            )}
        />
    );
};

export default MedidasMicrocredito;
