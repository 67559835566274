import React from "react";
import { QueryRenderer } from "@cubejs-client/react";
import { Spin, Row, Typography } from "antd";
import NumberFormat from "react-number-format";
import cubejsApi from "../../../cubejsApiBQ";

const { Title } = Typography;

var valor;

const segundaColumnaTigo = ({ resultSet, props }) => {
    const name = props.segunda;
    valor = resultSet.loadResponse.data[0][name[props.index]];
    if (valor === null) {
        valor = "-";
    }
    return (
        <Row gutter={[0, 0]} type="flex" justify="center">
            <Title
                style={{
                    fontSize: "13px",
                }}
            >
                <NumberFormat
                    value={valor}
                    decimalScale={1}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"#"}
                />
            </Title>
        </Row>
    );
};

const renderChart = (Component, props) => ({ resultSet, error }) =>
    (resultSet && <Component resultSet={resultSet} props={props} />) || (
        <Spin />
    );

const SegundaColumnaTigo = (props) => {
    const measures = [
        "RESUMENTigo.cantAprobadasCompra",
        "RESUMENTigo.cantAprobadasSinCompra",
        "RESUMENTigo.cantRechazadas",
        "RESUMENTigo.cantFinanciadoPunto",
        "RESUMENTigo.cantFinanciadoIsla",
        "RESUMENTigo.cantFinanciado",
        "RESUMENTigo.cantConsultas",
    ];

    return (
        <QueryRenderer
            query={{
                renewQuery: true,
                measures: measures,
                timeDimensions: [],
                filters: [
                    {
                        dimension: props.fechaCorte,
                        operator: "contains",
                        values: props.fecha !== "" ? [props.fecha] : [],
                    },
                    {
                        dimension: "RESUMENTigo.supervisor",
                        operator: "contains",
                        values:
                            props.supervisor !== "" ? [props.supervisor] : [],
                    },
                    {
                        dimension: "RESUMENTigo.punto",
                        operator: "contains",
                        values: props.punto !== "" ? [props.punto] : [],
                    },
                ],
                dimensions: [],
            }}
            cubejsApi={cubejsApi}
            render={renderChart(segundaColumnaTigo, props)}
        />
    );
};

export default SegundaColumnaTigo;
