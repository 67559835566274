import React from "react";
import { QueryRenderer } from "@cubejs-client/react";
import { Spin } from "antd";
import NumberFormat from "react-number-format";
import cubejsApi from "../../../cubejsApiBQ";

const totalTableRender = ({ resultSet, props }) => {
    var c = resultSet;
    Object.values(c.loadResponse.data[0]).unshift("Total");
    return (
        <table className="ant-table-fixed" style={{ width: "max-content" }}>
            <tfoot>
                <tr>
                    <td
                        style={{
                            width: "100px",
                        }}
                    >
                        <span
                            style={{
                                fontSize: "16px",
                            }}
                        >
                            Total
                        </span>
                    </td>
                    {Object.values(c.loadResponse.data[0]).map(
                        (element, index) => {
                            if (
                                element === null &&
                                (index === 1 || index === 3)
                            )
                                return (
                                    <td
                                        key={index}
                                        style={{
                                            width: "100px",
                                        }}
                                    >
                                        0.0%
                                    </td>
                                );
                            if (index === 1 || index === 3)
                                return (
                                    <td
                                        style={{
                                            width: "100px",
                                        }}
                                        key={index}
                                    >
                                        <NumberFormat
                                            value={element}
                                            decimalScale={1}
                                            displayType={"text"}
                                            thousandSeparator={true}
                                            suffix={"%"}
                                        />
                                    </td>
                                );
                            else
                                return (
                                    <td
                                        style={{
                                            width: "100px",
                                        }}
                                        key={index}
                                    >
                                        <NumberFormat
                                            value={element}
                                            decimalScale={1}
                                            displayType={"text"}
                                            thousandSeparator={true}
                                            prefix={"$"}
                                        />
                                    </td>
                                );
                        }
                    )}
                </tr>
            </tfoot>
        </table>
    );
};

const renderChart = (Component, props) => ({ resultSet, error }) =>
    (resultSet && <Component resultSet={resultSet} props={props} />) || (
        <Spin />
    );

const TotalTableRender = (props) => (
    <QueryRenderer
        query={{
            renewQuery: true,
            dimensions: [],
            timeDimensions: [],
            segments: [],
            measures: [
                "BaseResumenColocacion.montoEstadoDesembolsado",
                "BaseResumenColocacion.porcentajeDesembolsado",
                "BaseResumenColocacion.promedioDesembolso",
                "BaseResumenColocacion.variacionRespectoAlPromedio",
            ],
            filters: [
                {
                    dimension: "BaseResumenColocacion.fechaCorte",
                    operator: "contains",
                    values: props.fecha !== "" ? [props.fecha] : [],
                },
                {
                    dimension: "Usuarios.idCedula",
                    operator: "contains",
                    values: props.cedula !== "" ? [props.cedula] : [],
                },
                {
                    dimension: "BaseResumenColocacion.zona",
                    operator: "contains",
                    values: props.zona !== "" ? [props.zona] : [],
                },
            ],
        }}
        cubejsApi={cubejsApi}
        render={renderChart(totalTableRender, props)}
    />
);

export default TotalTableRender;
