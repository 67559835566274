import React from "react";
import { QueryRenderer } from "@cubejs-client/react";
import { Spin, Col } from "antd";
import Chart from "react-apexcharts";
import cubejsApi from "../../../cubejsApiBQ";
import FaltaGauge from "./FaltaGauge";

const gaugeMicrocredito = ({ resultSet, props, faltan }) => {
    const LABELS = [
        "Monto",
        "Cantidades de operaciones",
        "Profundización Seguros",
    ];

    return (
        <div
            style={{
                width: "100%",
            }}
        >
            {resultSet.seriesNames().map((s, index) => {
                const options = {
                    chart: {
                        offsetY: 40,
                        toolbar: {
                            show: false,
                        },
                    },
                    plotOptions: {
                        radialBar: {
                            startAngle: -135,
                            endAngle: 135,
                            hollow: {
                                margin: 0,
                                size: "67%",
                                background: "#fff",
                                image: undefined,
                                imageOffsetX: 0,
                                imageOffsetY: 0,
                                position: "front",
                            },
                            track: {
                                strokeWidth: "70%",
                                margin: 0, // margin is in pixels
                                dropShadow: {
                                    enabled: true,
                                    top: -1,
                                    left: 0,
                                    bottom: -1,
                                    blur: 3,
                                    opacity: 0.35,
                                },
                            },

                            dataLabels: {
                                showOn: "always",
                                name: {
                                    offsetY: 100,
                                    show: true,
                                    color: "#888",
                                    fontSize: "18px",
                                },
                                value: {
                                    formatter: function (val) {
                                        return parseInt(val) + "%";
                                    },
                                    offsetY: -10,
                                    color: "#111",
                                    fontSize: "20px",
                                    show: true,
                                },
                            },
                        },
                    },
                    fill: {
                        type: "gradient",
                        gradient: {
                            shade: "dark",
                            type: "horizontal",
                            shadeIntensity: 0.5,
                            gradientToColors: ["#91CECF"],
                            inverseColors: true,
                            opacityFrom: 1,
                            opacityTo: 1,
                            stops: [0, 100],
                        },
                    },
                    stroke: {
                        lineCap: "round",
                    },
                    labels: [LABELS[index]],
                };
                var series;
                if (resultSet.totalRow()[s.key] === null) {
                    series = [0];
                } else {
                    series = [resultSet.totalRow()[s.key]];
                }

                return (
                    <Col key={index} xs={24} lg={8}>
                        <Chart
                            style={{ margin: "-60px" }}
                            options={options}
                            series={[series]}
                            height="250px"
                            type="radialBar"
                        />
                        <FaltaGauge
                            {...props}
                            index={index}
                            faltan={faltan}
                            fecha={props.fecha}
                            cedula={props.cedula}
                            zona={props.zona}
                            plazas={props.plazas}
                        ></FaltaGauge>
                    </Col>
                );
            })}
        </div>
    );
};

const renderChart = (Component, props, faltan) => ({ resultSet, error }) =>
    (resultSet && (
        <Component resultSet={resultSet} props={props} faltan={faltan} />
    )) || <Spin />;

const GaugeMicrocredito = (props) => {
    const faltan = [
        "ResumenMicrocredito.faltaMonto",
        "ResumenMicrocredito.faltaCantidadOperaciones",
        "ResumenMicrocredito.faltaSeguros",
    ];
    return (
        <QueryRenderer
            query={{
                measures: [
                    "ResumenMicrocredito.porcentajePresupuestoValor",
                    "ResumenMicrocredito.porcentajePresupuestoCantidad",
                    "ResumenMicrocredito.porcentajeProfundizacionSeguros",
                ],
                filters: [
                    {
                        dimension: "ResumenMicrocredito.fechaCorte",
                        operator: "contains",
                        values: props.fecha !== "" ? [props.fecha] : [],
                    },
                    {
                        dimension: "Usuarios.idCedula",
                        operator: "contains",
                        values: props.cedula !== "" ? [props.cedula] : [],
                    },
                    {
                        dimension: "ResumenMicrocredito.plaza",
                        operator: "contains",
                        values: props.plazas !== "" ? [props.plazas] : [],
                    },
                    {
                        dimension: "Usuarios.zona",
                        operator: "contains",
                        values: props.zona !== "" ? [props.zona] : [],
                    },
                ],
                dimensions: [],
            }}
            cubejsApi={cubejsApi}
            render={renderChart(gaugeMicrocredito, props, faltan)}
        />
    );
};

export default GaugeMicrocredito;
