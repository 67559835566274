import React from "react";
import { QueryRenderer } from "@cubejs-client/react";
import NumberFormat from "react-number-format";
import { Row, Col, Typography, Card } from "antd";
import { Spin } from "antd";
import cubejsApi from "../../../cubejsApiBQ";

import "../../../styles/boxRender.css";

const { Text } = Typography;

// const COLORS_SERIES = ["#64abd8", "#a279c3", "#6286d3", "#c96a9c"];
const NAME_SERIES = [
    "ICV_30Sin360",
    "ICV_30Con360",
    "ICV_60Sin360",
    "ICV_60Con360",
];

const icv = ({ resultSet, props }) => {
    return (
        <Row gutter={[0, 0]} type="flex" justify="space-around">
            {resultSet.seriesNames().map((s, index) => {
                return (
                    <Col key={index} xs={10} lg={11}>
                        <Card
                            size="small"
                            className="topCard"
                            style={
                                {
                                    // borderTop: "3px solid " + COLORS_SERIES[index]
                                }
                            }
                            bodyStyle={{ padding: "10px 10px 10px 10px" }}
                        >
                            <Row
                                gutter={0}
                                type="flex"
                                justify="center"
                                align="middle"
                            >
                                <Col xs={20} lg={20}>
                                    <Text style={{ fontSize: "14px" }}>
                                        {NAME_SERIES[index]}
                                    </Text>
                                </Col>
                                <Col xs={20} lg={20}>
                                    <Text
                                        style={{
                                            fontSize: "16px",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        <NumberFormat
                                            value={resultSet.totalRow()[s.key]}
                                            decimalScale={1}
                                            displayType={"text"}
                                            thousandSeparator={true}
                                            suffix={"%"}
                                        />
                                    </Text>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                );
            })}
        </Row>
    );
};

const renderChart = (Component, props) => ({ resultSet, error }) =>
    (resultSet && <Component resultSet={resultSet} props={props} />) || (
        <Spin />
    );

const ICV = (props) => {
    return (
        <QueryRenderer
            query={{
                measures: [
                    "ResumenCarteraHistorico.ICV30Sin360",
                    "ResumenCarteraHistorico.ICV30Con360",
                    "ResumenCarteraHistorico.ICV60Sin360",
                    "ResumenCarteraHistorico.ICV60Con360",
                ],
                timeDimensions: [],
                dimensions: [],
                segments: [],
                filters: [
                    {
                        dimension: "ResumenCarteraHistorico.fechaCorte",
                        operator: "contains",
                        values: props.fecha !== "" ? [props.fecha] : [],
                    },
                    {
                        dimension: "ResumenCarteraHistorico.zona",
                        operator: "contains",
                        values: props.fecha !== "" ? [props.zona] : [],
                    },
                    {
                        dimension: "ResumenCarteraHistorico.tipoProducto",
                        operator: "contains",
                        values: ["credipoliza"],
                    },
                ],
            }}
            cubejsApi={cubejsApi}
            render={renderChart(icv, props)}
        />
    );
};

export default ICV;
