import React, { useState } from "react";
import Tooltip from "react-png-tooltip";
import { Icon, Card, Row, Col } from "antd";
import SupervisorBar from "../components/tigo/SupervisorBar";
import PuntoBar from "../components/tigo/PuntoBar";
import CascaderTigo from "../components/tigo/CascaderTigo";
import EmbudoTigo from "../components/tigo/embudo/EmbudoTigo";
import RankingTigo from "../components/tigo/ranking/RankingTigo";
import RegionBar from "../components/tigo/RegionBar";
import FechaActualizacion from "../components/FechaActualizacion";

const TIGO = (props) => {
    const [supervisor, setSupervisor] = useState("");
    const [punto, setPunto] = useState("");
    const [region, setRegion] = useState("");
    const [fecha, setFecha] = useState("");

    return (
        <div
            style={{
                width: "85%",
                margin: "auto",
                marginTop: "30px",
                // background: "#fafafa"
            }}
        >
            {/* ---------------------- Barras Busqueda ---------------------- */}
            <Row
                gutter={{ xs: 8, sm: 16, md: 24 }}
                type="flex"
                justify="space-around"
            >
                <Col xs={24} lg={6}>
                    <SupervisorBar
                        setSupervisor={setSupervisor}
                        region={region}
                        nombre={props.nombre}
                        tipoCargo={props.tipoCargo}
                        setPunto={setPunto}
                    />
                </Col>
                <Col xs={24} lg={6}>
                    <PuntoBar
                        setPunto={setPunto}
                        supervisor={supervisor}
                        region={region}
                    />
                </Col>
                <Col xs={24} lg={6}>
                    <RegionBar
                        setRegion={setRegion}
                        setPunto={setPunto}
                        setSupervisor={setSupervisor}
                    />
                </Col>
                <Col xs={24} lg={4}>
                    <CascaderTigo setFecha={setFecha} fecha={fecha} />
                </Col>
            </Row>

            {/* ---------------------- EMBUDO ---------------------- */}

            <EmbudoTigo
                fecha={fecha}
                supervisor={supervisor}
                punto={punto}
                tipoCargo={props.tipoCargo}
            />

            {/* ---------------------- Ranking Supervisor ---------------------- */}

            <Card
                title={
                    <div style={{ fontSize: "18px" }}>
                        <Icon type="dot-chart" /> Ranking Supervisor
                    </div>
                }
                className="card"
                style={{
                    width: "95%",
                    height: "auto",
                    margin: "auto",
                    marginBottom: "25px",
                }}
                extra={
                    <div align="right" fontSize="12px">
                        <Tooltip
                            className="GreyTooltip"
                            tooltip={<Icon type="info-circle" />}
                        >
                            <p align="left" style={{ marginBottom: "0em" }}>
                                - Monto en millones ($) y número (#). <br />
                            </p>
                        </Tooltip>
                    </div>
                }
            >
                <RankingTigo
                    fecha={fecha}
                    supervisor={supervisor}
                    tipo={"supervisor"}
                />
            </Card>

            {/* ---------------------- Ranking Punto ---------------------- */}

            {supervisor !== "" && (
                <Card
                    title={
                        <div style={{ fontSize: "18px" }}>
                            <Icon type="dot-chart" /> Ranking Punto
                        </div>
                    }
                    className="card"
                    style={{
                        width: "95%",
                        height: "auto",
                        margin: "auto",
                        marginBottom: "25px",
                    }}
                    extra={
                        <div align="right" fontSize="12px">
                            <Tooltip
                                className="GreyTooltip"
                                tooltip={<Icon type="info-circle" />}
                            >
                                <p align="left" style={{ marginBottom: "0em" }}>
                                    - Monto en millones ($) y número (#). <br />
                                </p>
                            </Tooltip>
                        </div>
                    }
                >
                    <RankingTigo
                        fecha={fecha}
                        punto={punto}
                        supervisor={supervisor}
                        tipo={"punto"}
                    />
                </Card>
            )}

            {/* Fecha de actualizacion */}

            {/* <Row
        gutter={[0, 0]}
        type="flex"
        justify="end"
        style={{
          width: "95%",
          margin: "auto",
          marginTop: "-10px"
        }}
      >
        <FechaActualizacion tipoProceso="TIGO" />
      </Row> */}
        </div>
    );
};

export default TIGO;
