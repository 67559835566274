import React, { Component } from "react";
import {
    Steps,
    Button,
    message,
    Icon,
    Row,
    Col,
    Typography,
    Card,
    Modal,
    Progress,
} from "antd";
import DropZone from "./DropZone";
import Checkbox from "./CheckBox";
import Transfer from "./Transfer";
import firebase from "../../firebase";
import CsvIcon from "../../img/csv.svg";

const { Text } = Typography;
const { Step } = Steps;

const steps = [
    {
        title: "Carga",
        content: "Primera Pagina",
        icon: "upload",
    },
    {
        title: "Seleccion",
        content: "Aca se seleccionan las tablas que se van a llenar",
        icon: "cloud-server",
    },
    {
        title: "Automatch",
        content:
            "Aca se relaciona el archivo csv con las tablas de la base de datos",
        icon: "pull-request",
    },
    {
        title: "Completado",
        content: "Confirmacion y envio de datos",
        icon: "file-done",
    },
];

class Pasos extends Component {
    constructor(props) {
        super(props);
        this.state = {
            current: 0,
            tablas: {},
            file: new Uint8Array(),
            isFile: false,
            progress: 0,
            visibleModal: false,
        };
    }

    upload() {
        const file = this.state.file;
        // const uid = firebase.auth().currentUser.uid;
        const uid = "4IQYPhAGN2Xl64M4y3YJB6UoomC2";
        // Path where the file will be saved in the bucket
        const storageRef = firebase.storage().ref(`${uid}/${uid}.csv`);
        let metadata = {
            contentType: "text/csv",
        };
        // Upload file formatted as csv

        var uploadTask = storageRef.put(file, metadata);

        // Listen for state changes, errors, and completion of the upload.
        uploadTask.on(
            firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
            (snapshot) => {
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                var progress =
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                this.setState({ progress });
            },
            function (error) {}
        );
        this.setState({ visibleModal: true });
    }

    next() {
        const current = this.state.current + 1;
        this.setState({ current });
    }

    prev() {
        const current = this.state.current - 1;
        this.setState({ current });
    }

    setTablas(estado) {
        this.setState({ tablas: estado });
    }

    setIsFile(estado) {
        this.setState({ isFile: estado });
    }

    setFile(estado) {
        this.setState({ file: estado });
    }

    render() {
        const { current } = this.state;
        return (
            <div>
                <Card
                    style={{
                        height: "auto",
                        borderRadius: "3px",
                        boxShadow: "5px 5px 10px 1px rgba(204,204,204,1)",
                    }}
                >
                    <Row
                        gutter={0}
                        type="flex"
                        justify="center"
                        style={{ paddingTop: "0px", height: "auto" }}
                    >
                        <Steps current={current}>
                            {steps.map((item, index) => (
                                <Step
                                    key={index}
                                    title={item.title}
                                    icon={<Icon type={item.icon} />}
                                />
                            ))}
                        </Steps>

                        {!this.state.isFile && current === 0 ? (
                            <Col
                                xs={24}
                                className="steps-content"
                                style={{ paddingTop: "0px", height: "300px" }}
                            >
                                <DropZone
                                    setIsFile={this.setIsFile.bind(this)}
                                    setFile={this.setFile.bind(this)}
                                />
                            </Col>
                        ) : this.state.isFile && current === 0 ? (
                            <Col
                                xs={24}
                                className="steps-content"
                                style={{
                                    paddingTop: "70px",
                                    height: "300px",
                                }}
                            >
                                <div>
                                    <img
                                        src={CsvIcon}
                                        alt="icono csv"
                                        width="150px"
                                    />
                                    <Modal
                                        visible={this.state.visibleModal}
                                        closable={false}
                                        centered
                                        title="Subiendo archivo"
                                        width="300px"
                                        bodyStyle={{
                                            display: "flex",
                                            justifyContent: "center",
                                        }}
                                        footer={[
                                            <div key="submit">
                                                {this.state.progress ===
                                                    100 && (
                                                    <Button
                                                        type="primary"
                                                        onClick={() =>
                                                            this.next()
                                                        }
                                                    >
                                                        OK
                                                    </Button>
                                                )}
                                            </div>,
                                        ]}
                                    >
                                        <Progress
                                            type="circle"
                                            percent={this.state.progress}
                                        />
                                    </Modal>
                                </div>
                            </Col>
                        ) : current === 1 ? (
                            <Col
                                xs={24}
                                className="steps-content"
                                style={{ paddingTop: "30px", height: "300px" }}
                            >
                                <Text style={{ fontSize: "16px" }}>
                                    Seleccione los campos que desea aplicar:
                                </Text>
                                <Checkbox
                                    setTablas={this.setTablas.bind(this)}
                                />
                            </Col>
                        ) : current === 2 ? (
                            <Col
                                xs={24}
                                className="steps-content"
                                style={{
                                    paddingTop: "30px",
                                    paddingBottom: "20px",
                                }}
                            >
                                <Transfer tablas={this.state.tablas} />
                            </Col>
                        ) : (
                            <Col xs={24} className="steps-content">
                                {steps[current].content}
                            </Col>
                        )}
                    </Row>
                </Card>
                <Row
                    gutter={0}
                    type="flex"
                    justify="center"
                    style={{ paddingTop: "0px", height: "auto" }}
                >
                    <Col className="steps-action">
                        {this.state.isFile && current === 0 && (
                            <Button
                                type="primary"
                                onClick={() => this.upload()}
                            >
                                Cargar Archivo
                            </Button>
                        )}
                        {current > 0 && (
                            <Button onClick={() => this.prev()}>
                                Anterior
                            </Button>
                        )}
                        {current > 0 && current < steps.length - 1 && (
                            <Button
                                style={{ marginLeft: 8 }}
                                type="primary"
                                onClick={() => this.next()}
                            >
                                Siguiente
                            </Button>
                        )}
                        {current === steps.length - 1 && (
                            <Button
                                style={{ marginLeft: 8 }}
                                type="primary"
                                onClick={() =>
                                    message.success("Proceso completado!")
                                }
                            >
                                Terminar
                            </Button>
                        )}
                    </Col>
                </Row>
            </div>
        );
    }
}

export default Pasos;
