import React, { useState } from "react";
import { Link } from "react-router-dom";
import { withRouter, Redirect } from "react-router";
import { Layout, Menu, Modal, Button } from "antd";
import InfoRender from "./InfoRender";
import logo2 from "../../img/logo2.png";
import avatar from "../../img/avatar.png";
import info from "../../img/info.png";
import "../../styles/header.css";
import PenFechaRender from "./FechaCartRender";
import firebase from "../../firebase";

const { SubMenu } = Menu;

// const _onClick = () => {
//   // window.location.reload(false);
// };

const Header = (props) => {
    const [showPerfil, setShowPerfil] = useState(false);
    const [visible, setVisible] = useState(true);
    const _onPressSingOut = () => {
        props.setLogin({ login: true });
        window.location.reload(true);
        firebase.auth().signOut();
    };
    const handleOk = (e) => {
        setVisible(false);
    };

    return (
        <Layout.Header
            style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "stretch",
                padding: "0 10px",
                width: "100%",
                marginBottom: "10px",
            }}
        >
            {!props.tipoCargo.includes("ASESOR COMERCIAL") &&
                !props.tipoCargo.includes("ASESOR SERVICIO") &&
                !props.tipoCargo.includes("COORDINADOR") && (
                    <Redirect to="/prestamos" />
                )}
            {(props.tipoCargo.includes("ASESOR COMERCIAL") ||
                props.tipoCargo.includes("ASESOR SERVICIO") ||
                props.tipoCargo.includes("COORDINADOR")) && (
                <Redirect to="/tarjetas" />
            )}

            {props.tipoCargo.includes("JEFE TIGO") && <Redirect to="/tigo" />}

            {(props.tipoCargo.includes("JEFE DE NEGOCIO MICROCREDITO") ||
                props.tipoCargo.includes("ASESOR MICROCREDITO")) && (
                <Redirect to="/microcredito" />
            )}

            <div
                style={{
                    margin: "0px",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                }}
            >
                <div style={{ marginTop: "-2px" }}>
                    <img width="30px" src={logo2} alt="logo2" />
                </div>
                <h2
                    style={{
                        color: "#fff",
                        margin: 0,
                        marginRight: "1em",
                        marginLeft: "0.5em",
                        lineHeight: "64px",
                    }}
                >
                    DICo
                </h2>
                {false &&
                    (props.tipoCargo.includes("ASESOR COMERCIAL") ||
                        props.tipoCargo.includes("ASESOR SERVICIO") ||
                        !props.cargo) && (
                        <Modal
                            width="75%"
                            centered
                            closable={false}
                            visible={
                                visible &&
                                props.location.pathname === "/tarjetas"
                            }
                            footer={[
                                <Button
                                    key="submit"
                                    type="primary"
                                    onClick={handleOk}
                                >
                                    OK
                                </Button>,
                            ]}
                            style={{
                                margin: "0px",
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                            }}
                        >
                            <div style={{}}>
                                <img width="100%" src={info} alt="info" />
                            </div>
                        </Modal>
                    )}
                <Menu
                    className="xs"
                    theme="dark"
                    mode="horizontal"
                    selectedKeys={[props.location.pathname]}
                    style={{
                        lineHeight: "64px",
                    }}
                >
                    {!props.tipoCargo.includes("ASESOR COMERCIAL") &&
                        !props.tipoCargo.includes("ASESOR SERVICIO") &&
                        !props.tipoCargo.includes("COORDINADOR") &&
                        !props.tipoCargo.includes("JEFE TIGO") &&
                        !props.tipoCargo.includes(
                            "JEFE DE NEGOCIO MICROCREDITO"
                        ) &&
                        !props.tipoCargo.includes("ASESOR MICROCREDITO") && (
                            <Menu.Item key="/prestamos">
                                <Link to="/prestamos">Prestamos</Link>
                            </Menu.Item>
                        )}
                    {(props.tipoCargo.includes("ASESOR COMERCIAL") ||
                        props.tipoCargo.includes("ASESOR SERVICIO") ||
                        props.tipoCargo.includes("ZONA") ||
                        props.tipoCargo.includes("COORDINADOR")) && (
                        <Menu.Item
                            key="/tarjetas"
                            //   disabled={props.location.pathname === "/tarjetas"}
                        >
                            <Link to="/tarjetas">Tarjetas</Link>
                        </Menu.Item>
                    )}

                    {(props.tipoCargo.includes(
                        "JEFE DE NEGOCIO MICROCREDITO"
                    ) ||
                        props.tipoCargo.includes("ASESOR MICROCREDITO")) && (
                        <Menu.Item key="/microcredito">
                            <Link to="/microcredito">Microcredito</Link>
                        </Menu.Item>
                    )}

                    {props.tipoCargo.includes("JEFE TIGO") && (
                        <Menu.Item key="/tigo">
                            <Link to="/tigo">TIGO</Link>
                        </Menu.Item>
                    )}

                    {/* 
          {
            <Menu.Item key="/cdt">
              <Link to="/cdt">CDT</Link>
            </Menu.Item>
          }
          */}
                    {props.tipoCargo.includes("ZONA") &&
                        props.zona === "Antioquia Norte" && (
                            <Menu.Item key="/polizas">
                                <Link to="/polizas">Pólizas</Link>
                            </Menu.Item>
                        )}
                    <Menu.Item
                        key="/gestion"
                        //disabled={props.location.pathname === "/gestion"}
                    >
                        <Link to="/gestion">Gestión</Link>
                    </Menu.Item>
                    <Menu.Item
                        key="/campañas"
                        //disabled={props.location.pathname === "/gestion"}
                    >
                        <Link to="/campañas">Campañas</Link>
                    </Menu.Item>
                </Menu>
            </div>
            <div style={{ height: "64px", zIndex: "10" }}>
                <Menu mode="inline" theme="dark">
                    <SubMenu
                        title={
                            <span
                                className="submenu-title-wrapper subt"
                                style={{
                                    fontSize: "20px",
                                }}
                            >
                                <PenFechaRender
                                    setPenUltFecha={props.setPenUltFecha.bind(
                                        this
                                    )}
                                />
                                <InfoRender
                                    setIsReady={props.setIsReady.bind(this)}
                                    setZona={props.setZona.bind(this)}
                                    setRegion={props.setRegion.bind(this)}
                                    setNombre={props.setNombre.bind(this)}
                                    setCargo={props.setCargo.bind(this)}
                                    setTipoCargo={props.setTipoCargo.bind(this)}
                                    user={props.user}
                                />

                                <img
                                    style={{
                                        marginLeft: "0.5em",
                                        marginRight: "0em",
                                    }}
                                    src={avatar}
                                    alt="avatar"
                                    className="avatar responsive"
                                />
                            </span>
                        }
                    >
                        <Menu.Item onClick={() => setShowPerfil(!showPerfil)}>
                            Perfil
                        </Menu.Item>
                        {showPerfil && (
                            <Menu.Item disabled>{props.nombre}</Menu.Item>
                        )}
                        {showPerfil && (
                            <Menu.Item disabled>{props.zona}</Menu.Item>
                        )}
                        <Menu.Item>
                            <a
                                href="https://forms.gle/GFDPx2RR3UWG7fDc6"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Preguntas Frecuentes
                            </a>
                        </Menu.Item>
                        <Menu.Item onClick={_onPressSingOut}>
                            Cerrar Sesión
                        </Menu.Item>
                    </SubMenu>
                </Menu>
            </div>
            {/* <Avatar size={50} icon="user" /> */}
        </Layout.Header>
    );
};

export default withRouter(Header);
