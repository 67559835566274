import React from "react";
import { QueryRenderer } from "@cubejs-client/react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Spin } from "antd";
import cubejsApi from "../../../cubejsApiBQ";
import NumberFormat from "react-number-format";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  head: {
    background: "#E3E3E3",
  },
});

const colNames = [
  "Zona",
  "Asesor",
  "Valor Desem.",
  "Cant. Desem.",
  "Cumpl. Colocacion ($)",
  "Cumpl. Colocacion (#)",
  "Ind Cart Ven 30",
  "Ind Cart Total",
];

function compare(a, b) {
  const canalA = a["ResumenMicrocredito.zona"].toUpperCase();
  const canalB = b["ResumenMicrocredito.zona"].toUpperCase();

  let comparison = 0;
  if (canalA > canalB) {
    comparison = 1;
  } else if (canalA < canalB) {
    comparison = -1;
  }
  return comparison;
}

const TablaRender = ({ resultSet }) => {
  const cols = resultSet.tableColumns().map((c) => c.key);
  const rows = resultSet.tablePivot().map((s) => s);

  rows.sort(compare);

  const classes = useStyles();
  return (
    <div>
      <Table className={classes.table} aria-label="simple table">
        <TableHead className={classes.head}>
          <TableRow>
            {colNames.map((colNames, index) => (
              <TableCell
                key={index}
                align="justify"
                style={{
                  color: "#3D3D3D",
                }}
              >
                {colNames}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((rows, index) => {
            var fontWeight = "normal";
            if (index === 8) fontWeight = "bold";
            return (
              <TableRow key={index}>
                {cols.map((cols, index) => {
                  if (index > 1) {
                    return (
                      <TableCell
                        key={index}
                        align="justify"
                        style={{
                          fontWeight: fontWeight,
                        }}
                      >
                        <NumberFormat
                          value={rows[cols]}
                          decimalScale={2}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={
                            index === 3 || index === 5 || index === 7 ? "$" : ""
                          }
                          suffix={index === 2 || index === 4 ? "%" : ""}
                        />
                      </TableCell>
                    );
                  }
                  return (
                    <TableCell
                      key={index}
                      align="justify"
                      style={{
                        width: index === 0 ? 117 : 0,
                        fontWeight: fontWeight,
                      }}
                    >
                      {rows[cols]}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
};

const renderChart = (Component) => ({ resultSet, error }) =>
  (resultSet && <Component resultSet={resultSet} />) || <Spin />;

const TablaRankingAsesor = (props) => {
  return (
    <QueryRenderer
      query={{
        renewQuery: true,
        measures: [
          "ResumenMicrocredito.valorDesembolsos",
          "ResumenMicrocredito.cantidadDesembolsos",
          "ResumenMicrocredito.porcentajePresupuestoValor",
          "ResumenMicrocredito.porcentajePresupuestoCantidad",
          "ResumenMicrocredito.indicadorCarteraVencida30",
          "ResumenMicrocredito.indicadorCarteraMoraTotal",
        ],
        dimensions: ["ResumenMicrocredito.zona", "Usuarios.nombre"],
        filters: [
          {
            dimension: "ResumenMicrocredito.fechaCorte",
            operator: "contains",
            values: props.fecha !== "" ? [props.fecha] : [],
          },
          {
            dimension: "Usuarios.idCedula",
            operator: "contains",
            values: props.cedula !== "" ? [props.cedula] : [],
          },
          {
            dimension: "Usuarios.region",
            operator: "contains",
            values: props.zona !== "" ? [props.zona] : [],
          },
        ],
      }}
      cubejsApi={cubejsApi}
      render={renderChart(TablaRender)}
    />
  );
};

export default TablaRankingAsesor;
