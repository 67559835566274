import React from "react";
import { QueryRenderer } from "@cubejs-client/react";
import NumberFormat from "react-number-format";
import { Row, Col, Typography } from "antd";
import { Spin } from "antd";
import cubejsApi from "../../../cubejsApiBQ";
import EfectividadZonal from "./EfectividadZonal";
import EfectividadRegional from "./EfectividadRegional";
import EfectividadNacional from "./EfectividadNacional";

const { Title } = Typography;

const NAME_SERIES = [
    "- Monto Colocación Mes: ",
    "- Colocación Mes: ",
    "- Cumplimiento Meta Mes: ",
    "- Indicador de Efectividad: ",
];

const PREFIX_SERIES = ["$", "#", "", ""];
const SUFFIX_SERIES = ["", "", "%", "%"];

const efectividadRender = ({ resultSet, props }) => {
    return (
        <div>
            {resultSet.seriesNames().map((s, index) => {
                return (
                    <Row
                        gutter={0}
                        type="flex"
                        justify="space-between"
                        align="middle"
                        key={index}
                    >
                        <Col xs={20}>
                            <Title
                                style={{
                                    fontSize: "12px",
                                }}
                            >
                                {NAME_SERIES[index]}
                            </Title>
                        </Col>
                        <Col xs={4}>
                            <Title
                                style={{
                                    fontSize: "12px",
                                }}
                            >
                                <NumberFormat
                                    value={resultSet.totalRow()[s.key]}
                                    decimalScale={1}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={PREFIX_SERIES[index]}
                                    suffix={SUFFIX_SERIES[index]}
                                />
                            </Title>
                        </Col>
                    </Row>
                );
            })}
            <div className="bottomCard">
                <EfectividadZonal {...props} />
                <EfectividadRegional {...props} />
                <EfectividadNacional {...props} />
            </div>
        </div>
    );
};

const renderChart = (Component, props) => ({ resultSet, error }) =>
    (resultSet && <Component resultSet={resultSet} props={props} />) || (
        <Spin />
    );

const EfectividadRender = (props) => {
    return (
        <QueryRenderer
            query={{
                renewQuery: true,
                measures: [
                    "BaseResumenColocacion.sumaAproYdesem",
                    "BaseResumenColocacion.cantidadSumaAproYdesem",
                    "BaseResumenColocacion.cumplimientoDeLaMeta",
                    "BaseResumenColocacion.indicadorEfectividad",
                ],
                timeDimensions: [],
                dimensions: [],
                segments: [],
                filters: [
                    {
                        dimension: "BaseResumenColocacion.fechaCorte",
                        operator: "contains",
                        values: props.fecha !== "" ? [props.fecha] : [],
                    },
                    {
                        dimension: "Usuarios.idCedula",
                        operator: "contains",
                        values: props.cedula !== "" ? [props.cedula] : [],
                    },
                    {
                        dimension: "BaseResumenColocacion.zona",
                        operator: "contains",
                        values: props.zona !== "" ? [props.zona] : [],
                    },
                ],
            }}
            cubejsApi={cubejsApi}
            render={renderChart(efectividadRender, props)}
        />
    );
};

export default EfectividadRender;
