import React, { Component } from "react";
// import "antd/dist/antd.css";
import { Col, Row, Card } from "antd";
import DragAndDrop from "./dragAndDrop/DragAndDrop";
class Trans extends Component {
    initialState = {
        base: ["PE Ratio", "Rev. Growth", "Employees", "Price", "COGS"],
        elementsB: [],
        elementsC: [],
        elementsD: [],
        dragSource: null,
        dragTarget: null,
    };
    constructor(props) {
        super(props);
        this.init();
        this.state = this.initialState;
        this.onDrop = this.onDrop.bind(this);
        this.onDragStart = this.onDragStart.bind(this);
        this.onDragEnter = this.onDragEnter.bind(this);
        this.onDragOver = this.onDragOver.bind(this);
        this.onDragLeave = this.onDragLeave.bind(this);
        this.onDragExit = this.onDragExit.bind(this);
        this.onDragEnd = this.onDragEnd.bind(this);
        this.isDragSource = this.isDragSource.bind(this);
        this.isValidDragTarget = this.isValidDragTarget.bind(this);
        this.isDragTarget = this.isDragTarget.bind(this);
        this.moveElement = this.moveElement.bind(this);
    }

    init() {
        require("../../styles/DragAndDrop.css");
    }

    onDrop(e) {
        if (this.isValidDragTarget(this.state.dragTarget)) {
            e.preventDefault();
            const elementId = e.dataTransfer.getData("text/plain");
            this.moveElement(elementId);
        }
    }

    moveElement(id) {
        const sourceElements = this.state[this.state.dragSource].filter(
            (e) => e !== id
        );
        this.setState((state) => ({
            [this.state.dragTarget]: [...state[this.state.dragTarget], id],
            [this.state.dragSource]: sourceElements,
            dragSource: null,
            dragTarget: null,
        }));
    }

    onDragStart(source) {
        this.setState({ dragSource: source });
    }

    onDragEnter(e, id) {
        if (this.isValidDragTarget(id)) {
            console.log("set target: " + id);

            console.log(this.state[id].length);
            if (this.state[id].length === 1 && id !== "base") {
                this.setState({ dragTarget: null });
            } else {
                this.setState({ dragTarget: id });
            }
            //Permite que solo B reciba
            // if (id === "elementsB") {
            //   this.setState({ dragTarget: id });
            // } else {
            //   this.setState({ dragTarget: null });
            // }
        }
    }

    onDragOver(e, id) {
        if (this.isDragTarget(id)) {
            e.preventDefault(); // activate drop zone
        }
    }

    onDragLeave(e, id) {
        if (id === this.state.dragTarget) {
            this.setState({ dragTarget: null });
        }
    }

    onDragExit() {}

    onDragEnd() {
        this.setState({ dragSource: null, dragTarget: null });
    }

    isValidDragTarget(id) {
        return id !== this.state.dragSource;
    }

    isDragTarget(id) {
        return id === this.state.dragTarget && this.state.dragTarget !== null;
    }

    isDragSource(id) {
        return id === this.state.dragSource;
    }
    render() {
        return (
            <Row
                type="flex"
                justify="space-between"
                style={{ width: "90%", margin: "auto" }}
            >
                <Col span={11}>
                    <Card title="Cargador">
                        <Row type="flex" justify="space-between">
                            <p>texto</p>
                            <DragAndDrop
                                elements={this.state.elementsB}
                                id={"elementsB"}
                                onDrop={this.onDrop}
                                onDragStart={this.onDragStart}
                                onDragEnter={this.onDragEnter}
                                onDragOver={this.onDragOver}
                                onDragLeave={this.onDragLeave}
                                onDragExit={this.onDragExit}
                                onDragEnd={this.onDragEnd}
                                isDragTarget={this.isDragTarget}
                                isDragSource={this.isDragSource}
                            />
                        </Row>
                        <Row type="flex" justify="space-between">
                            <p>texto</p>
                            <DragAndDrop
                                elements={this.state.elementsC}
                                id={"elementsC"}
                                onDrop={this.onDrop}
                                onDragStart={this.onDragStart}
                                onDragEnter={this.onDragEnter}
                                onDragOver={this.onDragOver}
                                onDragLeave={this.onDragLeave}
                                onDragExit={this.onDragExit}
                                onDragEnd={this.onDragEnd}
                                isDragTarget={this.isDragTarget}
                                isDragSource={this.isDragSource}
                            />
                        </Row>
                        <Row type="flex" justify="space-between">
                            <p>texto</p>
                            <DragAndDrop
                                elements={this.state.elementsD}
                                id={"elementsD"}
                                onDrop={this.onDrop}
                                onDragStart={this.onDragStart}
                                onDragEnter={this.onDragEnter}
                                onDragOver={this.onDragOver}
                                onDragLeave={this.onDragLeave}
                                onDragExit={this.onDragExit}
                                onDragEnd={this.onDragEnd}
                                isDragTarget={this.isDragTarget}
                                isDragSource={this.isDragSource}
                            />
                        </Row>
                    </Card>
                </Col>
                <Col span={11}>
                    <Card title="Not detected">
                        <DragAndDrop
                            elements={this.state.base}
                            id={"base"}
                            onDrop={this.onDrop}
                            onDragStart={this.onDragStart}
                            onDragEnter={this.onDragEnter}
                            onDragOver={this.onDragOver}
                            onDragLeave={this.onDragLeave}
                            onDragExit={this.onDragExit}
                            onDragEnd={this.onDragEnd}
                            isDragTarget={this.isDragTarget}
                            isDragSource={this.isDragSource}
                        />
                    </Card>
                </Col>
                {/* <div>
                <pre>{JSON.stringify(this.state, null, 1)}</pre>
              </div> */}
            </Row>
        );
    }
}

export default Trans;
