import React from "react";
import { QueryRenderer } from "@cubejs-client/react";
import { Spin } from "antd";
import cubejsApi from "../../cubejsApiBQ";

const fechaRender = ({ resultSet, props }) => {
  var dataSource = [];
  resultSet.loadResponse.data.map((result, index) => {
    return (dataSource[index] = result[
      "ResumenCarteraHistorico.fechaCorte"
    ].slice(0, -9));
  });
  props.setPenUltFecha(dataSource[dataSource.length - 1]);
  return null;
};

const renderChart = (Component, props) => ({ resultSet, error }) =>
  (resultSet && <Component resultSet={resultSet} props={props} />) || <Spin />;

const FechaRender = (props) => (
  <QueryRenderer
    query={{
      dimensions: ["ResumenCarteraHistorico.fechaCorte"],
      segments: [],
      measures: [],
      filters: [],
      renewQuery: true,
    }}
    cubejsApi={cubejsApi}
    render={renderChart(fechaRender, props)}
  />
);

export default FechaRender;
