import React from "react";
import { QueryRenderer } from "@cubejs-client/react";
import { Spin } from "antd";
import { AutoComplete } from "antd";
import cubejsApi from "../../cubejsApiBQ";

const cascaderBarRender = ({ resultSet, props }) => {
    var dataSource = [];
    resultSet.loadResponse.data.map((result, index) => {
        return (dataSource[index] = result[
            "BaseResumenColocacion.fechaCorte"
        ].slice(0, -9));
    });

    if (props.fecha === "") props.setFecha(dataSource[dataSource.length - 1]);

    const _onSelect = (value) => {
        props.setFecha(value);
    };
    return (
        <AutoComplete
            style={{
                width: "100%",
                marginBottom: "25px",
            }}
            size="large"
            dataSource={dataSource}
            defaultValue={dataSource[dataSource.length - 1]}
            onSelect={(value, option) => _onSelect(value, option)}
        />
    );
};

const renderChart = (Component, props) => ({ resultSet, error }) =>
    (resultSet && <Component resultSet={resultSet} props={props} />) || (
        <Spin />
    );

const CascaderBarRender = (props) => (
    <QueryRenderer
        query={{
            dimensions: ["BaseResumenColocacion.fechaCorte"],
            timeDimensions: [],
            segments: [],
            measures: [],
            filters: [
                {
                    dimension: "BaseResumenColocacion.tipoCifra",
                    operator: "contains",
                    values: ["Solicitudes"],
                },
            ],
            order: {
                // "BaseResumenColocacion.fechaCorte": "asc"
            },
            renewQuery: true,
            timezone: "America/Bogota",
        }}
        cubejsApi={cubejsApi}
        render={renderChart(cascaderBarRender, props)}
    />
);

export default CascaderBarRender;
