import React from "react";
import { QueryRenderer } from "@cubejs-client/react";
import { Spin } from "antd";
import { Table } from "antd";
import cubejsApi from "../../../cubejsApiBQ";
import TotalTable from "../tabla/TotalTablaCdt";

const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 1,
});
const formatterPercent = new Intl.NumberFormat("en-US", {
    style: "percent",
    minimumFractionDigits: 1,
});
// const formattertim = new Intl.DateTimeFormat("en-US", {
//   year: "numeric",
//   month: "numeric",
//   day: "numeric"
// });

const tableCdt = ({ resultSet, props }) => {
    var c = resultSet;
    // const update = val => {
    //   return parseInt(val.replace(/,|\.|\$|%/g, ""));
    // };

    resultSet.loadResponse.data.map((result, index) => {
        if (
            (typeof result["ResumenActividadCdt.valorVencimientoTotal"] &&
                typeof result["ResumenActividadCdt.valorRenovaciones"] &&
                typeof result["ResumenActividadCdt.valorNuevoTotal"]) ===
            "number"
        ) {
            c.loadResponse.data[index][
                "ResumenActividadCdt.porcentajeRenovacion"
            ] = formatterPercent.format(
                result["ResumenActividadCdt.porcentajeRenovacion"] / 100
            );
            c.loadResponse.data[index][
                "ResumenActividadCdt.valorVencimientoTotal"
            ] = formatter.format(
                result["ResumenActividadCdt.valorVencimientoTotal"]
            );
            c.loadResponse.data[index][
                "ResumenActividadCdt.valorRenovaciones"
            ] = formatter.format(
                result["ResumenActividadCdt.valorRenovaciones"]
            );
            c.loadResponse.data[index][
                "ResumenActividadCdt.valorNuevoTotal"
            ] = formatter.format(result["ResumenActividadCdt.valorNuevoTotal"]);
            c.loadResponse.data[index][
                "ResumenActividadCdt.valorCancelacionTotal"
            ] = formatter.format(
                result["ResumenActividadCdt.valorCancelacionTotal"]
            );
        }

        if (result["ResumenActividadCdt.fechaCorte"].length > 10) {
            c.loadResponse.data[index][
                "ResumenActividadCdt.fechaCorte"
            ] = result["ResumenActividadCdt.fechaCorte"].slice(0, -13);
        }
        return null;
    });
    let uniqueId = 0;
    return (
        <Table
            bordered
            size="small"
            pagination={{ pageSize: 10 }}
            rowKey={(record) => {
                if (!record.__uniqueId) record.__uniqueId = ++uniqueId;
                return record.__uniqueId;
            }}
            columns={resultSet.tableColumns().map((c) => ({
                ...c,
                dataIndex: c.key,
                width: 100,
            }))}
            dataSource={c.tablePivot()}
            scroll={{ x: "max-content" }}
            footer={() => {
                return (
                    <TotalTable
                        oficina={props.oficina}
                        cedula={props.cedula}
                        mesCorte={props.mesCorte}
                    />
                );
            }}
        />
    );
};

const renderChart = (Component, props) => ({ resultSet, error }) =>
    (resultSet && <Component resultSet={resultSet} props={props} />) || (
        <Spin />
    );

const TableCdt = (props) => (
    <QueryRenderer
        query={{
            dimensions: [
                "ResumenActividadCdt.fechaCorte",
                //"ResumenActividadCdt.oficina"
                // "ResumenActividadCdt.mesCorte"
            ],
            timeDimensions: [
                {
                    dimension: "ResumenActividadCdt.fechaCorte",
                },
            ],
            segments: [],
            measures: [
                "ResumenActividadCdt.valorVencimientoTotal",
                "ResumenActividadCdt.valorRenovaciones",
                "ResumenActividadCdt.valorNuevoTotal",
                "ResumenActividadCdt.valorCancelacionTotal",
                "ResumenActividadCdt.porcentajeRenovacion",
            ],
            order: {
                "ResumenActividadCdt.fechaCorte": "asc",
            },
            filters: [
                {
                    dimension: "ResumenActividadCdt.mesCorte",
                    operator: "contains",
                    values: props.mesCorte !== "" ? [props.mesCorte] : [],
                },
                {
                    dimension: "ResumenActividadCdt.oficina",
                    operator: "contains",
                    values: props.oficina !== "" ? [props.oficina] : [],
                },
                {
                    dimension: "Usuarios.idCedula",
                    operator: "contains",
                    values: props.cedula !== "" ? [props.cedula] : [],
                },
            ],
        }}
        cubejsApi={cubejsApi}
        render={renderChart(tableCdt, props)}
    />
);

export default TableCdt;
