import React from "react";
import { QueryRenderer } from "@cubejs-client/react";
import { Spin, Row, Col, Card, Typography } from "antd";
import NumberFormat from "react-number-format";
import cubejsApi from "../../../cubejsApiBQ";

import "../../../styles/boxRender.css";
import CantidadesActividad from "./CantidadesActividad";

const { Title, Text } = Typography;

const COLORS_SERIES = [
    "#b1abe9",
    "#b1abe9",
    "#8dc1ca",
    "#b1abe9",
    "#b1abe9",
    "#8dc1ca",
    "#5cc8d6",
    "#80c6b8",
    "#98c3a6",
    "#96c593",
];

const medidasCdt = ({ resultSet, props, mesCorte, cantidades }) => {
    const NAME_SERIES = [
        "Vencimiento Total",
        "Renovaciones",
        "Numero Total",
        "Cancelacion Total",
    ];

    return (
        <Row gutter={[0, 20]} type="flex" justify="space-around">
            {resultSet.seriesNames().map((s, index) => {
                return (
                    <Col key={index} xs={24} lg={9}>
                        <Card
                            size="small"
                            className="topCard"
                            style={{
                                borderTop: "3px solid " + COLORS_SERIES[index],
                            }}
                            bodyStyle={{ padding: "5px 10px 0px 10px" }}
                        >
                            <Row style={{ marginBottom: "5px" }}>
                                <Col>
                                    <Text className="cardTitle">
                                        {NAME_SERIES[index]}
                                    </Text>
                                </Col>
                            </Row>
                            <Row
                                gutter={0}
                                type="flex"
                                justify="space-between"
                                align="middle"
                            >
                                <Col xs={10}>
                                    <Title
                                        style={{
                                            fontSize: "16px",
                                        }}
                                    >
                                        <NumberFormat
                                            value={resultSet.totalRow()[s.key]}
                                            decimalScale={1}
                                            displayType={"text"}
                                            thousandSeparator={true}
                                            prefix={"$"}
                                        />
                                    </Title>
                                </Col>
                                <Col xs={4}>
                                    <CantidadesActividad
                                        {...props}
                                        index={index}
                                        mesCorte={mesCorte}
                                        cantidades={cantidades}
                                    />
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                );
            })}
        </Row>
    );
};

const renderChart = (Component, props, mesCorte, cantidades) => ({
    resultSet,
    error,
}) =>
    (resultSet && (
        <Component
            resultSet={resultSet}
            props={props}
            mesCorte={mesCorte}
            cantidades={cantidades}
        />
    )) || <Spin />;

const MedidasCdt = (props) => {
    const measures = [
        "ResumenActividadCdt.valorVencimientoTotal",
        "ResumenActividadCdt.valorRenovaciones",
        "ResumenActividadCdt.valorNuevoTotal",
        "ResumenActividadCdt.valorCancelacionTotal",
    ];
    const mesCorte = "ResumenActividadCdt.mesCorte";
    const cantidades = [
        "ResumenActividadCdt.cantidadVencimientoTotal",
        "ResumenActividadCdt.cantidadRenovaciones",
        "ResumenActividadCdt.cantidadNuevoTotal",
        "ResumenActividadCdt.cantidadCancelacionTotal",
    ];

    return (
        <QueryRenderer
            query={{
                renewQuery: true,
                measures: measures,
                timeDimensions: [],
                filters: [
                    {
                        dimension: mesCorte,
                        operator: "contains",
                        values: props.mesCorte !== "" ? [props.mesCorte] : [],
                    },
                    {
                        dimension: "Usuarios.idCedula",
                        operator: "contains",
                        values: props.cedula !== "" ? [props.cedula] : [],
                    },
                    {
                        dimension: "ResumenActividadCdt.oficina",
                        operator: "contains",
                        values: props.oficina !== "" ? [props.oficina] : [],
                    },
                ],
                dimensions: [],
            }}
            cubejsApi={cubejsApi}
            render={renderChart(medidasCdt, props, mesCorte, cantidades)}
        />
    );
};

export default MedidasCdt;
