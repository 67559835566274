import React from "react";
import { QueryRenderer } from "@cubejs-client/react";
import { Spin, Modal } from "antd";
import { AutoComplete } from "antd";
import cubejsApi from "../../cubejsApiBQ";

const searchBar = ({ resultSet, props }) => {
    var dataSource = [];
    var cedulas = [];
    var cargos = [];
    var nombre = "";
    var coordinador = "";
    resultSet.loadResponse.data.map((result, index) => {
        cedulas[index] = result["Usuarios.idCedula"];
        cargos[index] = result["Usuarios.cargo"];
        if (result["Usuarios.cargo"].includes("COORDINADOR")) {
            coordinador = " (Coordinador/a)";
        } else {
            coordinador = "";
        }
        return (dataSource[index] = result["Usuarios.nombre"] + coordinador);
    });
    if (
        !props.tipoCargo.includes("ZONA") &&
        !props.tipoCargo.includes("COORDINADOR")
    ) {
        nombre = props.nombre;
        const indexCedulas = cedulas[dataSource.indexOf(nombre)];
        const indexCargos = cargos[dataSource.indexOf(nombre)];
        props.setCedula(indexCedulas);
        props.setFiltroAsesor(indexCargos);
    }

    const _onSelect = (value) => {
        const indexCedulas = cedulas[dataSource.indexOf(value)];
        const indexCargos = cargos[dataSource.indexOf(value)];

        if (indexCargos.includes("COORDINADOR")) {
            props.setCoordinador(value.slice(0, -16));
        } else {
            props.setCedula(indexCedulas);
        }
        props.setFiltroAsesor(indexCargos);
    };
    if (props.tipoCargo.includes("ZONA")) {
        nombre =
            dataSource[
                dataSource.indexOf(props.coordinador + " (Coordinador/a)")
            ];
    }
    return (
        <AutoComplete
            style={{
                width: "100%",
                marginBottom: "25px",
            }}
            disabled={props.cargo}
            allowClear
            size="large"
            dataSource={dataSource}
            placeholder={
                props.tipoCargo.includes("COORDINADOR")
                    ? "Asesor"
                    : "Asesor o Coordinador"
            }
            defaultValue={dataSource[cedulas.indexOf(props.cedula)] || nombre}
            filterOption={(inputValue, option) => {
                return (
                    option.props.children
                        .toUpperCase()
                        .indexOf(inputValue.toUpperCase()) !== -1
                );
            }}
            onSelect={(value) => {
                _onSelect(value);
            }}
            onSearch={() => {
                props.setCedula("");
                props.setFiltroAsesor("");
                props.setPuntoVenta("");
                props.setCoordinador("");
            }}
        />
    );
};

const renderChart = (Component, props) => ({ resultSet, error }) => {
    return (
        (resultSet && <Component resultSet={resultSet} props={props} />) || (
            <Modal
                width="200px"
                centered
                closable={false}
                visible={true}
                footer={null}
            >
                <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                >
                    <h3>Cargando datos...</h3>
                    <Spin />
                </div>
            </Modal>
        )
    );
};

const SearchBar = (props) => {
    var filtros = [
        {
            dimension: "Usuarios.zona",
            operator: "contains",
            values: [props.zona],
        },
        {
            dimension: "Usuarios.cargo",
            operator: "notContains",
            values: ["ZONA"],
        },
        {
            dimension: "Usuarios.cargo",
            operator: "notEquals",
            values: ["SIN ASESOR"],
        },
        {
            dimension: "Usuarios.cargo",
            operator: "notEquals",
            values: ["ASESOR RETIRADO"],
        },
        {
            dimension: "Usuarios.producto",
            operator: "contains",
            values: ["crediuno"],
        },
    ];
    if (props.tipoCargo.includes("ZONA")) {
        filtros = filtros.concat({
            dimension: "Usuarios.puntoVenta",
            operator: "contains",
            values: [props.puntoVenta],
        });
    }
    if (props.tipoCargo.includes("COORDINADOR")) {
        filtros = filtros.concat(
            {
                dimension: "Usuarios.cargo",
                operator: "notContains",
                values: ["COORDINADOR"],
            },
            {
                dimension: "Usuarios.puntoVenta",
                operator: "contains",
                values: [props.puntoVenta],
            }
        );
    }
    if (!props.esComercial) {
        filtros = filtros.concat({
            dimension: "Usuarios.cargo",
            operator: "notContains",
            values: ["COMERCIAL"],
        });
    }
    if (!props.esServicio) {
        filtros = filtros.concat({
            dimension: "Usuarios.cargo",
            operator: "notContains",
            values: ["SERVICIO"],
        });
    }
    if (!props.esPartTime) {
        filtros = filtros.concat({
            dimension: "Usuarios.cargo",
            operator: "notContains",
            values: ["PART TIME"],
        });
    }
    if (!props.esFullTime) {
        filtros = filtros.concat({
            dimension: "Usuarios.cargo",
            operator: "notContains",
            values: ["FULL TIME"],
        });
    }

    return (
        <QueryRenderer
            query={{
                renewQuery: true,
                measures: [],
                timeDimensions: [],
                dimensions: [
                    "Usuarios.nombre",
                    "Usuarios.idCedula",
                    "Usuarios.cargo",
                ],
                segments: [],
                filters: filtros,
            }}
            cubejsApi={cubejsApi}
            render={renderChart(searchBar, props)}
        />
    );
};

export default SearchBar;
