import React from "react";
import { Card, Row, Col } from "antd";
import Tabla from "../cartera/tablaCartera/Tabla";
import Medidas from "../cartera/cards/Medidas";
import GaugeCartera from "./gaugeCartera/GaugeCartera";
const Cartera = props => {
    return (
        <Row gutter={[0, 24]} type="flex" justify="space-between">
            <Col xs={24} lg={15}>
                <Card
                    size="small"
                    className="card"
                    style={{ background: "#F2F2F2" }}
                >
                    <Medidas
                        fecha={props.fecha}
                        cedula={props.cedula}
                        zona={props.zona}
                        // plazas={props.plazas}
                    />
                </Card>
            </Col>
            <Col xs={24} lg={8}>
                <GaugeCartera
                    fecha={props.fecha}
                    cedula={props.cedula}
                    zona={props.zona}
                    // plazas={props.plazas}
                />
            </Col>
            {/* <Col xs={24} lg={24}>
                <Tabla
                // fecha={props.fecha}
                // cedula={props.cedula}
                // zona={props.zona}
                // plazas={props.plazas}
                />
            </Col> */}
        </Row>
    );
};

export default Cartera;
