import React, { Component } from "react";
import moment from "moment";
import "../../../styles/staticPage.css";
import "../../../styles/boxRender.css";
import { Table, Tag, Spin } from "antd";
import axios from "axios";

class Oferta extends Component {
    constructor(props) {
        super(props);

        this.state = {
            cliente: [],
        };
    }

    apiSearch = async () => {
        await axios
            .get(
                `https://us-central1-dicocf.cloudfunctions.net/functions/oferta/id?id=${this.props.id}`
            )
            .then((res) => {
                this.setState({
                    cliente: res.data,
                });
            });
    };

    componentDidMount = async () => {
        await this.apiSearch();
    };

    componentDidUpdate = async (prevProps) => {
        if (prevProps.id !== this.props.id) {
            await this.apiSearch();
        }
    };

    convert(fecha) {
        let newDate;
        moment().add();
        newDate = moment(fecha).add(1, "days").format("L");

        return newDate;
    }

    tagColor(resultado) {
        let color = "volcano";
        if (resultado === "En Negociacion") {
            color = "green";
        } else if (resultado === "En Gestion") {
            color = "blue";
        }
        return color;
    }

    render() {
        const columns = [
            {
                title: "Oferta",
                dataIndex: "SubProducto",
            },
            {
                title: "Estado",
                dataIndex: "ESTADO_OFERTA",
                render: (estado) => (
                    <span>
                        <Tag color={this.tagColor(estado)} key={estado}>
                            {estado.toUpperCase()}
                        </Tag>
                    </span>
                ),
            },
            {
                title: "Valor Oferta",
                dataIndex: "VALOR_OFERTA",
                render: (oferta) => (
                    <span>
                        <p style={{ marginBottom: "0px" }}>
                            $
                            {oferta
                                .toFixed(2)
                                .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                        </p>
                    </span>
                ),
            },
            {
                title: "Precio",
                dataIndex: "PRECIO",
                render: (precio) => (
                    <span>
                        <p style={{ marginBottom: "0px" }}>
                            $
                            {precio
                                .toFixed(2)
                                .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                        </p>
                    </span>
                ),
            },
            {
                title: "Tasa",
                dataIndex: "tasa",
                render: (tasa) => (
                    <p style={{ marginBottom: "0px" }}>{tasa + "%"}</p>
                ),
            },
            {
                title: "Fecha de Inicio",
                dataIndex: "FECHA_INI",
                render: (fecha_ini) => (
                    <p style={{ marginBottom: "0px" }}>
                        {this.convert(fecha_ini)}
                    </p>
                ),
            },
            {
                title: "Fecha Fin",
                dataIndex: "FECHA_FIN",
                render: (fecha_fin) => (
                    <p style={{ marginBottom: "0px" }}>
                        {this.convert(fecha_fin)}
                    </p>
                ),
            },
            {
                title: "Otros",
                dataIndex: "OTROS",
            },
        ];
        let uniqueId = 0;
        return (
            <div>
                {this.state.cliente ? (
                    <Table
                        columns={columns}
                        dataSource={this.state.cliente}
                        rowKey={(record) => {
                            if (!record.__uniqueId)
                                record.__uniqueId = ++uniqueId;
                            return record.__uniqueId;
                        }}
                        pagination={{
                            defaultPageSize: 5,
                            showSizeChanger: true,
                            pageSizeOptions: ["5", "10", "20"],
                        }}
                        size="small"
                    />
                ) : (
                    <Spin />
                )}
            </div>
        );
    }
}

export default Oferta;
