import React from "react";
import { QueryRenderer } from "@cubejs-client/react";
import NumberFormat from "react-number-format";
import { Row, Col, Typography, Card } from "antd";
import { Spin } from "antd";
import cubejsApi from "../../../cubejsApiBQ";
import CantRender from "./CantRender";
import PercentRender from "./PercentRender";

import "../../../styles/boxRender.css";

const { Title, Text } = Typography;

const COLORS_SERIES = ["#FF3C30", "#325A80", "#1890ff", "#00B344"];
const NAME_SERIES = ["Negados", "En estudio", "Aprobados", "Desembolsados"];

const boxRender = ({ resultSet, props }) => {
    return (
        <Row gutter={[0, 0]} type="flex" justify="space-between">
            {resultSet.seriesNames().map((s, index) => {
                return (
                    <Col key={index} xs={23} lg={5}>
                        <Card
                            size="small"
                            className="topCard"
                            style={{
                                borderTop: "3px solid " + COLORS_SERIES[index],
                            }}
                            bodyStyle={{ padding: "5px 10px 0px 10px" }}
                        >
                            <Row style={{ marginBottom: "5px" }}>
                                <Col>
                                    <Text className="cardTitle">
                                        {NAME_SERIES[index]}
                                    </Text>
                                </Col>
                            </Row>
                            <Row
                                gutter={0}
                                type="flex"
                                justify="space-between"
                                align="middle"
                            >
                                <Col xs={12}>
                                    <Title
                                        style={{
                                            fontSize: "16px",
                                        }}
                                    >
                                        <NumberFormat
                                            value={resultSet.totalRow()[s.key]}
                                            decimalScale={1}
                                            displayType={"text"}
                                            thousandSeparator={true}
                                            prefix={"$"}
                                        />
                                    </Title>
                                </Col>
                                <Col xs={3}>
                                    <CantRender {...props} index={index} />
                                </Col>
                                <Col xs={6}>
                                    <PercentRender {...props} index={index} />
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                );
            })}
        </Row>
    );
};

const renderChart = (Component, props) => ({ resultSet, error }) =>
    (resultSet && <Component resultSet={resultSet} props={props} />) || (
        <Spin />
    );

const BoxRender = (props) => {
    return (
        <QueryRenderer
            query={{
                renewQuery: true,
                measures: [
                    "BaseResumenColocacion.montoEstadoNegado",
                    "BaseResumenColocacion.montoEstadoEnEstudio",
                    "BaseResumenColocacion.montoEstadoAprobado",
                    "BaseResumenColocacion.montoEstadoDesembolsado",
                ],
                timeDimensions: [],
                dimensions: [],
                segments: [],
                filters: [
                    {
                        dimension: "BaseResumenColocacion.fechaCorte",
                        operator: "contains",
                        values: props.fecha !== "" ? [props.fecha] : [],
                    },
                    {
                        dimension: "Usuarios.idCedula",
                        operator: "contains",
                        values: props.cedula !== "" ? [props.cedula] : [],
                    },
                    {
                        dimension: "BaseResumenColocacion.zona",
                        operator: "contains",
                        values: props.zona !== "" ? [props.zona] : [],
                    },
                ],
                // timezone: "America/Bogota"
            }}
            cubejsApi={cubejsApi}
            render={renderChart(boxRender, props)}
        />
    );
};

export default BoxRender;
