import React, { useState } from "react";
import Tooltip from "react-png-tooltip";
import { Icon, Card, Row, Col, Collapse } from "antd";
import EmbudoMicrocredito from "../components/microcredito/embudo/EmbudoMicrocredito";
import CascaderMicrocredito from "../components/microcredito/CascaderMicrocredito";
import SearchMicrocredito from "../components/microcredito/SearchMicrocredito";
import Zona from "../components/microcredito/Zona";
import RankingMicrocredito from "../components/microcredito/ranking/RankingMicrocredito";
import GaugeMicrocredito from "../components/microcredito/embudo/GaugeMicrocredito";
import Cartera from "../components/microcredito/cartera/Cartera";
import Tabla from "../components/microcredito/ranking/Tabla";
import IndicadorPrincipal from "../components/microcredito/IndicadorPrincipal/IndicadorPrincipal";
import IndicadorCartera from "../components/microcredito/indicadorCartera/IndicadorCartera";
import RankingColumn from "../components/microcredito/ranking/RankingColumn";
import RankingColumnUsuario from "../components/microcredito/ranking/RankingColumnUsuario";

const { Panel } = Collapse;

const Microcredito = (props) => {
  const [plazas, setPlazas] = useState("");
  const [cedula, setCedula] = useState("");
  const [fecha, setFecha] = useState("");
  const [punto, setPunto] = useState("");

  return (
    <div
      style={{
        width: "85%",
        margin: "auto",
        marginTop: "30px",
        // background: "#fafafa"
      }}
    >
      {/* ---------------------- Barras Busqueda ---------------------- */}
      <Row
        gutter={{ xs: 8, sm: 16, md: 24 }}
        type="flex"
        justify="space-around"
      >
        <Col xs={24} lg={8}>
          <Zona
            punto={punto}
            cedula={cedula}
            setPunto={setPunto}
            setCedula={setCedula}
            setPlazas={setPlazas}
            cargo={props.cargo}
            nombre={props.nombre}
            zona={props.zona}
          />
        </Col>
        {/* <Col xs={24} lg={5}>
                    <Plazas
                        cargo={props.cargo}
                        nombre={props.nombre}
                        setPlazas={setPlazas}
                        setPunto={setPunto}
                        setCedula={setCedula}
                        cedula={cedula}
                        plazas={plazas}
                    />
                </Col> */}
        <Col xs={24} lg={8}>
          <SearchMicrocredito
            punto={punto}
            cedula={cedula}
            setPlazas={setPlazas}
            setCedula={setCedula}
            setPunto={setPunto}
            zona={props.zona}
            cargo={props.cargo}
            nombre={props.nombre}
          />
        </Col>
        <Col xs={24} lg={5}>
          <CascaderMicrocredito setFecha={setFecha} fecha={fecha} />
        </Col>
      </Row>

      {/* ---------------------- EMBUDO ---------------------- */}

      <Card
        title={
          <div style={{ fontSize: "18px" }}>
            <Icon type="funnel-plot" /> Embudo
          </div>
        }
        className="card"
        style={{
          width: "95%",
          height: "auto",
          margin: "auto",
          marginBottom: "25px",
        }}
        extra={
          <div align="right" fontSize="12px">
            <Tooltip
              className="GreyTooltip"
              tooltip={<Icon type="info-circle" />}
            >
              <p align="left" style={{ marginBottom: "0em" }}>
                - Monto en millones ($) y número (#). <br />
              </p>
            </Tooltip>
          </div>
        }
      >
        <EmbudoMicrocredito
          fecha={fecha}
          cedula={cedula}
          zona={props.zona}
          plazas={plazas}
        />
      </Card>

      {/* ---------------------- Indicadores Principales ---------------------- */}

      <Card
        title={
          <div style={{ fontSize: "18px" }}>
            <Icon type="rise" /> Indicadores Principales
          </div>
        }
        className="card"
        style={{
          width: "95%",
          height: "auto",
          margin: "auto",
          marginBottom: "25px",
        }}
        extra={
          <div align="right" fontSize="12px">
            <Tooltip
              className="GreyTooltip"
              tooltip={<Icon type="info-circle" />}
            >
              <p align="left" style={{ marginBottom: "0em" }}>
                - Cumplimiento Colocacion: Cumplimiento con respecto a las metas
                del mes, en valores, cantidades de operaciones y cantidades de
                seguros Voluntarios <br />
              </p>
            </Tooltip>
          </div>
        }
      >
        <IndicadorPrincipal
          fecha={fecha}
          cedula={cedula}
          zona={props.zona}
          plazas={plazas}
        />
      </Card>
      {/* ---------------------- Cumplimiento IndicadorPrincipal---------------------- */}

      <Card
        title={
          <div style={{ fontSize: "18px" }}>
            <Icon type="check" /> Cumplimiento Colocación y Seguros
          </div>
        }
        className="card"
        style={{
          width: "95%",
          height: "auto",
          margin: "auto",
          marginBottom: "25px",
        }}
        extra={
          <div align="right" fontSize="12px">
            <Tooltip
              className="GreyTooltip"
              tooltip={<Icon type="info-circle" />}
            >
              <p align="left" style={{ marginBottom: "0em" }}>
                - Cumplimiento Colocacion: Cumplimiento con respecto a las metas
                del mes, en valores, cantidades de operaciones y cantidades de
                seguros Voluntarios <br />
              </p>
            </Tooltip>
          </div>
        }
      >
        <GaugeMicrocredito
          fecha={fecha}
          cedula={cedula}
          zona={props.zona}
          plazas={plazas}
        />
      </Card>

      {/* ---------------------- Ranking Zona ---------------------- */}

      {/* {props.tipoCargo.includes("JEFE DE NEGOCIO MICROCREDITO") && (
        <Card
          title={
            <div style={{ fontSize: "18px" }}>
              <Icon type="dot-chart" /> Ranking Zona
            </div>
          }
          className="card"
          style={{
            width: "95%",
            height: "auto",
            margin: "auto",
            marginBottom: "25px",
          }}
          extra={
            <div align="right" fontSize="12px">
              <Tooltip
                className="GreyTooltip"
                tooltip={<Icon type="info-circle" />}
              >
                <p align="left" style={{ marginBottom: "0em" }}>
                  - Eje y: Rentabilidad (Millones): Ingresos generados a la
                  compañía por concepto de intereses de los desembolsos del mes.
                  / Eje x: Indicador de Efectividad: participación de los
                  desembolsos sobre las radicaciones del mes / Eje z: Indicador
                  Cartera Vencida 30: Cartera Vencida mayor a 30 días sobre la
                  cartera total.
                  <br />
                </p>
              </Tooltip>
            </div>
          }
        >
          <RankingMicrocredito
            fecha={fecha}
            cedula={cedula}
            zona={props.zona}
            region={props.region}
            plazas={plazas}
            tipo="zona"
          />
        </Card>
      )} */}
      {/* ---------------------- Ranking Zona ---------------------- */}
      {props.tipoCargo.includes("JEFE DE NEGOCIO MICROCREDITO") && (
        <Card
          title={
            <div style={{ fontSize: "18px" }}>
              <Icon type="dot-chart" /> Ranking Zona
            </div>
          }
          className="card"
          style={{
            width: "95%",
            height: "auto",
            margin: "auto",
            marginBottom: "25px",
          }}
          extra={
            <div align="right" fontSize="12px">
              <Tooltip
                className="GreyTooltip"
                tooltip={<Icon type="info-circle" />}
              >
                <p align="left" style={{ marginBottom: "0em" }}>
                  - Eje Vertical Izquierdo: Indicador Cartera 30: Cartera
                  Vencida mayor a 30 días sobre la cartera total.
                  <br />
                  -Eje Vertical Derecho 1: Indicador Cartera Total: Total
                  Cartera Vencida sobre cartera total.
                  <br />
                  -Eje Vertical Derecho 2: Presupuesto Crecimiento: Cartera
                  Vigente sobre Presupuesto Crecimiento Cartera.
                  <br />
                </p>
              </Tooltip>
            </div>
          }
        >
          <RankingColumn
            fecha={fecha}
            cedula={cedula}
            zona={props.zona}
            region={props.region}
            plazas={plazas}
          />
        </Card>
      )}
      {/* ---------------------- Ranking Asesor ---------------------- */}
      <Card
        title={
          <div style={{ fontSize: "18px" }}>
            <Icon type="dot-chart" /> Ranking Asesor
          </div>
        }
        className="card"
        style={{
          width: "95%",
          height: "auto",
          margin: "auto",
          marginBottom: "25px",
        }}
        extra={
          <div align="right" fontSize="12px">
            <Tooltip
              className="GreyTooltip"
              tooltip={<Icon type="info-circle" />}
            >
              <p align="left" style={{ marginBottom: "0em" }}>
                - Eje vertical: Indicador Cartera 30: Cartera Vencida mayor a 30
                días sobre la cartera total. <br /> Eje vertical: Indicador
                Cartera Total: Total Cartera Vencida sobre cartera total. <br />
                Eje vertical secundario: Presupuesto Crecimiento: Cartera
                Vigente sobre Presupuesto Crecimiento Cartera.
                <br />
              </p>
            </Tooltip>
          </div>
        }
      >
        <RankingColumnUsuario
          fecha={fecha}
          cedula={cedula}
          zona={props.zona}
          region={props.region}
          plazas={plazas}
        />
        <Collapse>
          <Panel header="Detalles Ranking Asesor">
            <Tabla
              fecha={fecha}
              cedula={cedula}
              zona={props.zona}
              region={props.region}
              plazas={plazas}
            />
          </Panel>
        </Collapse>
      </Card>

      {/* ---------------------- Ranking ---------------------- */}

      {/* <Card
        title={
          <div style={{ fontSize: "18px" }}>
            <Icon type="dot-chart" /> Ranking Asesor
          </div>
        }
        className="card"
        style={{
          width: "95%",
          height: "auto",
          margin: "auto",
          marginBottom: "25px",
        }}
        extra={
          <div align="right" fontSize="12px">
            <Tooltip
              className="GreyTooltip"
              tooltip={<Icon type="info-circle" />}
            >
              <p align="left" style={{ marginBottom: "0em" }}>
                - Eje y: Rentabilidad (Millones): Ingresos generados a la
                compañía por concepto de intereses de los desembolsos del mes. /
                Eje x: Indicador de Efectividad: participación de los
                desembolsos sobre las radicaciones del mes / Eje z: Indicador
                Cartera Vencida 30: Cartera Vencida mayor a 30 días sobre la
                cartera total. <br />
              </p>
            </Tooltip>
          </div>
        }
      >
        <RankingMicrocredito
          fecha={fecha}
          cedula={cedula}
          zona={props.zona}
          region={props.region}
          plazas={plazas}
          tipo="asesor"
        />
      </Card> */}

      {/* ---------------------- Cartera Micro ---------------------- */}

      <Card
        title={
          <div style={{ fontSize: "18px" }}>
            <Icon type="solution" /> Cartera
          </div>
        }
        className="card"
        style={{
          width: "95%",
          height: "auto",
          margin: "auto",
          marginBottom: "25px",
        }}
        extra={
          <div align="right" fontSize="12px">
            <Tooltip
              className="GreyTooltip"
              tooltip={<Icon type="info-circle" />}
            >
              <p align="left" style={{ marginBottom: "0em" }}>
                - Principales cifras de cartera en Millones, con crecimietos
                comparados con respecto al mes anterior y cierre del año
                anterior.. <br />
              </p>
            </Tooltip>
          </div>
        }
      >
        <Cartera
          fecha={fecha}
          cedula={cedula}
          zona={props.zona}
          // plazas={plazas}
        />
      </Card>

      {/* ---------------------- Indicadores Cartera  ---------------------- */}

      <Card
        title={
          <div style={{ fontSize: "18px" }}>
            <Icon type="solution" /> Indicadores Cartera
          </div>
        }
        className="card"
        style={{
          width: "95%",
          height: "auto",
          margin: "auto",
          marginBottom: "25px",
        }}
        extra={
          <div align="right" fontSize="12px">
            <Tooltip
              className="GreyTooltip"
              tooltip={<Icon type="info-circle" />}
            >
              <p align="left" style={{ marginBottom: "0em" }}>
                - Monto en millones ($) y número (#). <br />
              </p>
            </Tooltip>
          </div>
        }
      >
        <IndicadorCartera
          fecha={fecha}
          cedula={cedula}
          zona={props.zona}
          plazas={plazas}
        />
      </Card>
    </div>
  );
};

export default Microcredito;
