import React from "react";
import { QueryRenderer } from "@cubejs-client/react";
import { Spin } from "antd";
import { AutoComplete } from "antd";
import cubejsApi from "../../cubejsApiBQ";

const zona = ({ resultSet, props }) => {
    var dataSource = [];
    var cedulas = [];
    var punto = "";
    resultSet.loadResponse.data.map((result, index) => {
        cedulas[index] = result["ResumenMicrocredito.identificacionAsesor"];
        return (dataSource[index] = result["ResumenMicrocredito.zona"]);
    });

    if (props.cedula !== "") {
        punto = dataSource[dataSource.length - 1];
        props.setPunto(punto);
    }
    return (
        <AutoComplete
            style={{
                width: "100%",
                marginBottom: "25px",
            }}
            disabled={props.cargo}
            allowClear
            size="large"
            dataSource={dataSource}
            placeholder="Zona"
            defaultValue={punto}
            filterOption={(inputValue, option) => {
                return (
                    option.props.children
                        .toUpperCase()
                        .indexOf(inputValue.toUpperCase()) !== -1
                );
            }}
            onSelect={(value) => {
                props.setPunto(value);
                props.setCedula(cedulas[dataSource.indexOf(value)]);
            }}
            onSearch={() => {
                props.setPunto("");
                props.setCedula("");
                props.setPlazas("");
            }}
        />
    );
};

const renderChart = (Component, props) => ({ resultSet, error }) =>
    (resultSet && <Component resultSet={resultSet} props={props} />) || (
        <Spin />
    );

const Zona = (props) => (
    <QueryRenderer
        query={{
            renewQuery: true,
            measures: [],
            timeDimensions: [],
            dimensions: [
                "ResumenMicrocredito.zona",
                "ResumenMicrocredito.identificacionAsesor",
            ],
            segments: [],
            order: {
                "ResumenMicrocredito.zona": "asc",
            },
            filters: [
                {
                    dimension: "ResumenMicrocredito.zona",
                    operator: "contains",
                    values: props.zona !== "" ? [props.zona] : [],
                },
                {
                    dimension: "ResumenMicrocredito.identificacionAsesor",
                    operator: "contains",
                    values: props.cedula !== "" ? [props.cedula] : [],
                },
            ],
        }}
        cubejsApi={cubejsApi}
        render={renderChart(zona, props)}
    />
);

export default Zona;
