import React, { useState } from "react";
import { ConfigProvider } from "antd";
import es_ES from "antd/es/locale/es_ES";
import { HashRouter as Router, Route, Redirect } from "react-router-dom";
import App from "../App";
import Libranza from "../pages/Libranza";
import Gestion from "../pages/Gestion";
import Crediuno from "../pages/Crediuno";
import Credipoliza from "../pages/Credipoliza";
import Microcredito from "../pages/Microcredito";
import CDT from "../pages/CDT";
import TIGO from "../pages/TIGO";

const AppRouter = () => {
    const [login, setLogin] = useState(false);
    var [isReady, setIsReady] = useState(false);
    var [zona, setZona] = useState("");
    var [region, setRegion] = useState("");
    var [user, setUser] = useState("");
    var [nombre, setNombre] = useState("");
    var [cargo, setCargo] = useState(false);
    var [tipoCargo, setTipoCargo] = useState("");
    var [penUltFecha, setPenUltFecha] = useState("");

    // const NoMatch = ({ location }) => (
    //   <div>
    //     <h3>
    //       No hay ruta para <code>{location.pathname}</code>
    //     </h3>
    //   </div>
    // );
    return (
        <Router>
            {/* <Route component={NoMatch} /> */}
            <Redirect to="/" />
            <ConfigProvider locale={es_ES}>
                <App
                    setLogin={setLogin.bind(this)}
                    login={login}
                    setZona={setZona.bind(this)}
                    zona={zona}
                    setUser={setUser.bind(this)}
                    user={user}
                    setIsReady={setIsReady.bind(this)}
                    isReady={isReady}
                    setNombre={setNombre.bind(this)}
                    nombre={nombre}
                    setCargo={setCargo.bind(this)}
                    cargo={cargo}
                    setPenUltFecha={setPenUltFecha.bind(this)}
                    tipoCargo={tipoCargo}
                    setTipoCargo={setTipoCargo.bind(this)}
                    setRegion={setRegion.bind(this)}
                >
                    <Route
                        key="/tarjetas"
                        path="/tarjetas"
                        render={(props) => (
                            <Crediuno
                                {...props}
                                zona={zona}
                                region={region}
                                cargo={cargo}
                                nombre={nombre}
                                tipoCargo={tipoCargo}
                            />
                        )}
                    />

                    <Route
                        key="/gestion"
                        path="/gestion"
                        render={(props) => <Gestion {...props} admin={false} />}
                    />
                    <Route
                        key="/campañas"
                        path="/campañas"
                        render={(props) => <Gestion {...props} admin={true} />}
                    />
                    <Route
                        key="/prestamos"
                        path="/prestamos"
                        render={(props) =>
                            login && (
                                <Libranza
                                    {...props}
                                    zona={zona}
                                    region={region}
                                    cargo={cargo}
                                    nombre={nombre}
                                    penUltFecha={penUltFecha}
                                />
                            )
                        }
                    />
                    <Route
                        key="/microcredito"
                        path="/microcredito"
                        render={(props) => (
                            <Microcredito
                                {...props}
                                zona={zona}
                                region={region}
                                cargo={cargo}
                                nombre={nombre}
                                tipoCargo={tipoCargo}
                            />
                        )}
                    />
                    <Route
                        key="/cdt"
                        path="/cdt"
                        render={(props) => (
                            <CDT
                                {...props}
                                zona={zona}
                                tipoCargo={tipoCargo}
                                cargo={cargo}
                                nombre={nombre}
                            />
                        )}
                    />
                    <Route
                        key="/polizas"
                        path="/polizas"
                        render={(props) => (
                            <Credipoliza
                                {...props}
                                zona={zona}
                                region={region}
                                cargo={cargo}
                                nombre={nombre}
                                tipoCargo={tipoCargo}
                                penUltFecha={penUltFecha}
                            />
                        )}
                    />
                    <Route
                        key="tigo"
                        path="/tigo"
                        render={(props) => (
                            <TIGO
                                {...props}
                                zona={zona}
                                region={region}
                                cargo={cargo}
                                tipoCargo={tipoCargo}
                                nombre={nombre}
                            />
                        )}
                    />
                </App>
            </ConfigProvider>
        </Router>
    );
};
export default AppRouter;
