import React from "react";
import { QueryRenderer } from "@cubejs-client/react";
import { Spin } from "antd";
import Chart from "react-apexcharts";
import cubejsApi from "../../../cubejsApiBQ";

const strokedGaugeTigo = ({ resultSet, props }) => {
    var series = [];
    resultSet.seriesNames().map((s, index) => {
        if (resultSet.totalRow()[s.key] === null) {
            return (series[index] = 0);
        } else {
            return (series[index] = resultSet.totalRow()[s.key].toFixed(2));
        }
    });
    const options = {
        chart: {
            type: "radialBar",
            offsetY: -30,
        },
        plotOptions: {
            radialBar: {
                startAngle: -90,
                endAngle: 90,
                track: {
                    background: "#e7e7e7",
                    strokeWidth: "97%",
                    margin: 5, // margin is in pixels
                    dropShadow: {
                        enabled: true,
                        top: 2,
                        left: 0,
                        color: "#999",
                        opacity: 1,
                        blur: 2,
                    },
                },
                dataLabels: {
                    name: {
                        fontSize: "14px",
                        offsetY: 0,
                        color: "#888",
                    },
                    value: {
                        offsetY: -40,
                        fontSize: "22px",
                        color: undefined,
                        formatter: function (val) {
                            return val + "%";
                        },
                    },
                },
            },
        },
        fill: {
            type: "gradient",
            gradient: {
                shade: "dark",
                type: "horizontal",
                shadeIntensity: 0.5,
                gradientToColors: ["#91CECF"],
                inverseColors: true,
                opacityFrom: 1,
                opacityTo: 1,
                stops: [0, 100],
            },
        },
        stroke: {
            dashArray: 4,
        },
        labels: ["Mes"],
    };

    return (
        <div
            style={{
                width: "100%",
            }}
        >
            <Chart
                style={{ margin: "-20px" }}
                options={options}
                series={series}
                type="radialBar"
                height={"320px"}
            />
        </div>
    );
};

const renderChart = (Component, props) => ({ resultSet, error }) =>
    (resultSet && <Component resultSet={resultSet} props={props} />) || (
        <Spin />
    );

const StrokedGaugeTigo = (props) => (
    <QueryRenderer
        query={{
            renewQuery: true,
            measures: ["RESUMENTigo.porcentajePresupuesto"],
            timeDimensions: [],
            filters: [
                {
                    dimension: "RESUMENTigo.mesCorte",
                    operator: "contains",
                    values: props.fecha !== "" ? [props.fecha] : [],
                },
                {
                    dimension: "RESUMENTigo.supervisor",
                    operator: "contains",
                    values: props.supervisor !== "" ? [props.supervisor] : [],
                },
                {
                    dimension: "RESUMENTigo.punto",
                    operator: "contains",
                    values: props.punto !== "" ? [props.punto] : [],
                },
            ],
        }}
        cubejsApi={cubejsApi}
        render={renderChart(strokedGaugeTigo, props)}
    />
);

export default StrokedGaugeTigo;
