import React from "react";
import { QueryRenderer } from "@cubejs-client/react";
import { Spin, Row, Col } from "antd";
import { AutoComplete, Input, Icon, Typography } from "antd";
import cubejsApi from "../../cubejsApiBQ";

const { Text } = Typography;

const AnioBar = ({ resultSet, props }) => {
    var anio = [];
    resultSet.loadResponse.data.map((result, index) => {
        return (anio[index] = String(result["CrediunoCascadaPdv.anio"]));
    });

    if (props.anio === "") {
        props.setAnio(anio[anio.length - 1]);
    }

    return (
        <AutoComplete
            style={{
                width: "70%",
            }}
            size="large"
            dataSource={anio}
            defaultValue={anio[anio.length - 1]}
            onSelect={(value) => {
                props.setAnio(value);
            }}
        />
    );
};

const MesBar = ({ resultSet, props }) => {
    var mes = [];

    resultSet.loadResponse.data.map((result, index) => {
        return (mes[index] = String(result["CrediunoCascadaPdv.mes"]));
    });

    if ((props.mes === "" && props.anio !== "") || !mes.includes(props.mes)) {
        props.setMes(mes[mes.length - 1]);
    }

    return (
        <AutoComplete
            style={{
                width: "50%",
            }}
            size="large"
            dataSource={mes}
            defaultValue={mes[mes.length - 1]}
            onSelect={(value) => props.setMes(value)}
        />
    );
};

const renderChart = (Component, props) => ({ resultSet, error }) =>
    (resultSet && <Component resultSet={resultSet} props={props} />) || (
        <Spin />
    );

const Bar = ({ consulta, componente, props, filtro }) => {
    return (
        <QueryRenderer
            query={{
                dimensions: [consulta],
                timeDimensions: [],
                segments: [],
                measures: [],
                filters: [
                    {
                        dimension: "CrediunoCascadaPdv.anio",
                        operator: "contains",
                        values: filtro !== "" ? [filtro] : [],
                    },
                ],
                order: {
                    // "BaseResumenColocacion.fechaCorte": "asc"
                },
                renewQuery: true,
            }}
            cubejsApi={cubejsApi}
            render={renderChart(componente, props)}
        />
    );
};

const CascaderBar = (props) => {
    return (
        <Row gutter={[10, 0]} type="flex" style={{ marginBottom: "25px" }}>
            <Col
                xs={12}
                sm={12}
                lg={12}
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Text
                    style={{
                        fontSize: "14px",
                        fontWeight: "bold",
                        marginRight: "8px",
                    }}
                >
                    Año:
                </Text>
                <Bar
                    props={props}
                    consulta="CrediunoCascadaPdv.anio"
                    componente={AnioBar}
                    filtro=""
                />
            </Col>
            <Col
                xs={12}
                sm={12}
                lg={12}
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Text
                    style={{
                        fontSize: "14px",
                        fontWeight: "bold",
                        marginRight: "8px",
                    }}
                >
                    Mes:
                </Text>
                <Bar
                    props={props}
                    consulta="CrediunoCascadaPdv.mes"
                    componente={MesBar}
                    filtro={props.anio}
                />
            </Col>
        </Row>
    );
};

export default CascaderBar;
