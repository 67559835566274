import React from "react";
import { QueryRenderer } from "@cubejs-client/react";
import { Spin } from "antd";
import { AutoComplete } from "antd";
import cubejsApi from "../../cubejsApiBQ";

const regionBar = ({ resultSet, props }) => {
    var dataSource = [];
    var region = "";
    resultSet.loadResponse.data.map((result, index) => {
        return (dataSource[index] = result["RESUMENTigo.region"]);
    });
    //   if (props.cargo) {
    //     punto = dataSource[dataSource.indexOf(props.nombre)];
    //   }
    return (
        <AutoComplete
            style={{
                width: "100%",
                marginBottom: "25px",
            }}
            disabled={props.cargo}
            allowClear
            size="large"
            dataSource={dataSource}
            placeholder="Region"
            defaultValue={region}
            filterOption={(inputValue, option) => {
                return (
                    option.props.children
                        .toUpperCase()
                        .indexOf(inputValue.toUpperCase()) !== -1
                );
            }}
            onSelect={(value) => {
                props.setRegion(value);
                props.setSupervisor("");
                props.setPunto("");
            }}
            onSearch={() => {
                props.setRegion("");
                props.setSupervisor("");
                props.setPunto("");
            }}
        />
    );
};

const renderChart = (Component, props) => ({ resultSet, error }) =>
    (resultSet && <Component resultSet={resultSet} props={props} />) || (
        <Spin />
    );

const RegionBar = (props) => (
    <QueryRenderer
        query={{
            renewQuery: true,
            measures: [],
            timeDimensions: [],
            dimensions: ["RESUMENTigo.region"],
            segments: [],
            filters: [],
        }}
        cubejsApi={cubejsApi}
        render={renderChart(regionBar, props)}
    />
);

export default RegionBar;
