import React from "react";
import { QueryRenderer } from "@cubejs-client/react";
import { Spin, Row, Col, Card, Typography } from "antd";
import NumberFormat from "react-number-format";
import cubejsApi from "../../../cubejsApiBQ";

import "../../../styles/boxRender.css";
import Porcentajes from "./Porcentajes";
import Progresos from "./Progresos";

const { Title, Text } = Typography;

const COLORS_SERIES = [
    "#e6b8af",
    "#f4cccc",
    "#fce5cd",
    "#fff2cc",
    "#d9ead3",
    "#d0e0e3",
    "#c9daf8",
    "#cfe2f3",
    "#d9d2e9",
    "#ead1dc",
    "#d9ead3",
];

const medidas = ({
    resultSet,
    props,
    anioCorte,
    mesCorte,
    porcentajes,
    progresos,
}) => {
    var NAME_SERIES;
    var PREFIX;
    if (props.tipo === "servicio") {
        NAME_SERIES = [
            "Entregas",
            "Activación Titular",
            "Amparadas",
            "Activación Amparadas",
            "Venta Total",
            "Seguros Total",
        ];
        PREFIX = ["#", "#", "#", "#", "$", "#"];
    } else if (props.tipo === "comercial") {
        NAME_SERIES = [
            "Consultas",
            "Viables",
            "Fuelle",
            "Ingresos",
            "Devueltos",
            "Aprobadas",
            "Entregadas",
            "Activación Titular",
            "Venta Total",
        ];
        PREFIX = ["#", "#", "#", "#", "#", "#", "#", "#", "$"];
    } else {
        NAME_SERIES = [
            "Consultas",
            "Viables",
            "Fuelle",
            "Ingresos",
            "Devueltos",
            "Aprobadas",
            "Entregas Titular",
            "Activación Titular",
            "Venta Total",
            "Entregas Amparadas",
            "Activación Amparadas",
            "Seguros Total",
        ];
        PREFIX = ["#", "#", "#", "#", "#", "#", "#", "#", "$", "#", "#", "#"];
    }

    return (
        <Row gutter={[0, 0]} type="flex" justify="space-around">
            {resultSet.seriesNames().map((s, index) => {
                return (
                    <Col key={index} xs={24} lg={7}>
                        <Card
                            size="small"
                            className="topCard"
                            style={{
                                borderTop: "3px solid " + COLORS_SERIES[index],
                            }}
                            bodyStyle={{ padding: "5px 10px 0px 10px" }}
                        >
                            <Row style={{ marginBottom: "5px" }}>
                                <Col>
                                    <Text className="cardTitle">
                                        {NAME_SERIES[index]}
                                    </Text>
                                </Col>
                            </Row>
                            <Row gutter={0} type="flex" justify="space-between">
                                <Col xs={12}>
                                    <Title
                                        style={{
                                            fontSize: "15px",
                                        }}
                                    >
                                        <NumberFormat
                                            value={resultSet.totalRow()[s.key]}
                                            decimalScale={1}
                                            displayType={"text"}
                                            thousandSeparator={true}
                                            prefix={PREFIX[index]}
                                        />
                                    </Title>
                                </Col>
                                <Col xs={6}>
                                    <Porcentajes
                                        {...props}
                                        index={index}
                                        anioCorte={anioCorte}
                                        mesCorte={mesCorte}
                                        porcentajes={porcentajes}
                                    />
                                </Col>
                            </Row>
                            {(props.tipo === "zona" ||
                                props.tipo === "comercial") && (
                                <Row
                                    style={{
                                        marginBottom: "5px",
                                        marginRight: "10px",
                                        marginTop: "-5px",
                                    }}
                                >
                                    <Col>
                                        <Progresos
                                            {...props}
                                            index={index}
                                            anioCorte={anioCorte}
                                            mesCorte={mesCorte}
                                            progresos={progresos}
                                        />
                                    </Col>
                                </Row>
                            )}
                        </Card>
                    </Col>
                );
            })}
        </Row>
    );
};

const renderChart = (
    Component,
    props,
    anioCorte,
    mesCorte,
    porcentajes,
    progresos
) => ({ resultSet, error }) =>
    (resultSet && (
        <Component
            resultSet={resultSet}
            props={props}
            anioCorte={anioCorte}
            mesCorte={mesCorte}
            porcentajes={porcentajes}
            progresos={progresos}
        />
    )) || <Spin />;

const Medidas = (props) => {
    var measures;
    var progresos;
    var mesCorte;
    var anioCorte;
    var porcentajes;
    if (props.tipo === "servicio") {
        measures = [
            "CrediunoAsesorServicio.entregas",
            "CrediunoAsesorServicio.activacion",
            "CrediunoAsesorServicio.amparadas",
            "CrediunoAsesorServicio.activacionAmparadas",
            "CrediunoAsesorServicio.ventaTotal",
            "CrediunoAsesorServicio.segurosTotal",
        ];
        porcentajes = [
            "CrediunoAsesorServicio.porcentajeEntregasComparacion",
            "",
            "",
            "",
            "CrediunoAsesorServicio.porcentajeVentaTotalComparacion",
            "",
        ];
        anioCorte = "CrediunoAsesorServicio.anio";
        mesCorte = "CrediunoAsesorServicio.mes";
    } else if (props.tipo === "comercial") {
        measures = [
            "CrediunoAsesorComercial.consultas",
            "CrediunoAsesorComercial.viables",
            "CrediunoAsesorComercial.fuelle",
            "CrediunoAsesorComercial.ingresos",
            "CrediunoAsesorComercial.devueltos",
            "CrediunoAsesorComercial.aprobadas",
            "CrediunoAsesorComercial.entregas",
            "CrediunoAsesorComercial.activacion",
            "CrediunoAsesorComercial.ventaTotal",
        ];
        porcentajes = [
            "CrediunoAsesorComercial.porcentajeConsultasComparacion",
            "",
            "",
            "",
            "",
            "",
            "CrediunoAsesorComercial.porcentajeEntregasComparacion",
            "",
            "CrediunoAsesorComercial.porcentajeVentaTotalComparacion",
        ];
        progresos = [
            "CrediunoAsesorComercial.porcentajeCumplimientoConsultas",
            "CrediunoAsesorComercial.porcentajeCumplimientoViables",
            "",
            "CrediunoAsesorComercial.porcentajeCumplimientoIngresos",
            "",
            "CrediunoAsesorComercial.porcentajeCumplimientoAprobadas",
            "CrediunoAsesorComercial.porcentajeCumplimientoEntregas",
            "CrediunoAsesorComercial.porcentajeCumplimientoActivacion",
            "",
        ];
        anioCorte = "CrediunoAsesorComercial.anio";
        mesCorte = "CrediunoAsesorComercial.mes";
    } else {
        measures = [
            "CrediunoCascadaPdv.consultas",
            "CrediunoCascadaPdv.viables",
            "CrediunoCascadaPdv.fuelle",
            "CrediunoCascadaPdv.ingresos",
            "CrediunoCascadaPdv.devueltos",
            "CrediunoCascadaPdv.aprobadas",
            "CrediunoCascadaPdv.entregas",
            "CrediunoCascadaPdv.activacion",
            "CrediunoCascadaPdv.ventaTotal",
            "CrediunoCascadaPdv.amparadas",
            "CrediunoCascadaPdv.activacionAmparadas",
            "CrediunoCascadaPdv.segurosTotal",
        ];
        porcentajes = [
            "CrediunoCascadaPdv.porcentajeConsultasComparacion",
            "",
            "",
            "",
            "",
            "",
            "CrediunoCascadaPdv.porcentajeEntregasComparacion",
            "CrediunoCascadaPdv.porcentajeActivacionComparacion",
            "CrediunoCascadaPdv.porcentajeVentaTotalComparacion",
            "",
            "",
            "CrediunoCascadaPdv.porcentajeSegurosTotalComparacion",
        ];
        progresos = [
            "CrediunoCascadaPdv.porcentajeCumplimientoConsultas",
            "CrediunoCascadaPdv.porcentajeCumplimientoViables",
            "",
            "CrediunoCascadaPdv.porcentajeCumplimientoIngresos",
            "",
            "CrediunoCascadaPdv.porcentajeCumplimientoAprobadas",
            "CrediunoCascadaPdv.porcentajeCumplimientoEntregas",
            "CrediunoCascadaPdv.porcentajeCumplimientoActivacion",
            "",
            "",
            "",
            "",
        ];
        anioCorte = "CrediunoCascadaPdv.anio";
        mesCorte = "CrediunoCascadaPdv.mes";
    }
    var filtros = [
        {
            dimension: anioCorte,
            operator: "contains",
            values: props.anio !== "" ? [props.anio] : [],
        },
        {
            dimension: mesCorte,
            operator: "contains",
            values: props.mes !== "" ? [props.mes] : [],
        },
    ];
    if (props.tipo === "comercial" || props.tipo === "servicio") {
        filtros = filtros.concat(
            {
                dimension: "Usuarios.idCedula",
                operator: "contains",
                values: props.cedula !== "" ? [props.cedula] : [],
            },
            {
                dimension: "Usuarios.zona",
                operator: "contains",
                values: props.zona !== "" ? [props.zona] : [],
            }
        );
    }
    if (props.tipo !== "comercial" && props.tipo !== "servicio") {
        filtros = filtros.concat(
            {
                dimension: "CrediunoCascadaPdv.zona",
                operator: "contains",
                values: props.zona !== "" ? [props.zona] : [],
            },
            {
                dimension: "CrediunoCascadaPdv.puntoVenta",
                operator: "contains",
                values: props.puntoVenta !== "" ? [props.puntoVenta] : [],
            }
        );
    }
    return (
        <QueryRenderer
            query={{
                renewQuery: true,
                measures: measures,
                timeDimensions: [],
                filters: filtros,
                dimensions: [],
            }}
            cubejsApi={cubejsApi}
            render={renderChart(
                medidas,
                props,
                anioCorte,
                mesCorte,
                porcentajes,
                progresos
            )}
        />
    );
};

export default Medidas;
