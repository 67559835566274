import React from "react";
import { QueryRenderer } from "@cubejs-client/react";
import { Spin, Row, Typography } from "antd";
import NumberFormat from "react-number-format";
import cubejsApi from "../../../cubejsApiBQ";

const { Title } = Typography;
var color;
var valor;

const porcentajes = ({ resultSet, props }) => {
    const name = props.porcentajes;
    valor = resultSet.loadResponse.data[0][name[props.index]];
    if (valor === null) {
        valor = "-";
        color = "#769995";
    } else if (valor < 0) color = "#f0585c";
    else if (valor > 0) color = "#30B359";
    else color = "#769995";
    return (
        <Row gutter={[0, 0]} type="flex" justify="center">
            <Title
                style={{
                    fontSize: "15px",
                    color: color,
                }}
            >
                <NumberFormat
                    value={valor}
                    decimalScale={1}
                    displayType={"text"}
                    thousandSeparator={true}
                    suffix={"%"}
                />
            </Title>
        </Row>
    );
};

const renderChart = (Component, props) => ({ resultSet, error }) =>
    (resultSet && <Component resultSet={resultSet} props={props} />) || (
        <Spin />
    );

const Porcentajes = (props) => {
    var measures;
    if (props.tipo === "servicio") {
        measures = [
            "CrediunoAsesorServicio.porcentajeEntregasComparacion",
            "CrediunoAsesorServicio.porcentajeVentaTotalComparacion",
        ];
    } else if (props.tipo === "comercial") {
        measures = [
            "CrediunoAsesorComercial.porcentajeConsultasComparacion",
            "CrediunoAsesorComercial.porcentajeEntregasComparacion",
            "CrediunoAsesorComercial.porcentajeVentaTotalComparacion",
        ];
    } else {
        measures = [
            "CrediunoCascadaPdv.porcentajeConsultasComparacion",
            "CrediunoCascadaPdv.porcentajeEntregasComparacion",
            "CrediunoCascadaPdv.porcentajeActivacionComparacion",
            "CrediunoCascadaPdv.porcentajeVentaTotalComparacion",
            "CrediunoCascadaPdv.porcentajeSegurosTotalComparacion",
        ];
    }
    var filtros = [
        {
            dimension: props.anioCorte,
            operator: "contains",
            values: props.anio !== "" ? [props.anio] : [],
        },
        {
            dimension: props.mesCorte,
            operator: "contains",
            values: props.mes !== "" ? [props.mes] : [],
        },
    ];
    if (props.tipo === "comercial" || props.tipo === "servicio") {
        filtros = filtros.concat(
            {
                dimension: "Usuarios.idCedula",
                operator: "contains",
                values: props.cedula !== "" ? [props.cedula] : [],
            },
            {
                dimension: "Usuarios.zona",
                operator: "contains",
                values: props.zona !== "" ? [props.zona] : [],
            }
        );
    }
    if (props.tipo !== "comercial" && props.tipo !== "servicio") {
        filtros = filtros.concat(
            {
                dimension: "CrediunoCascadaPdv.zona",
                operator: "contains",
                values: props.zona !== "" ? [props.zona] : [],
            },
            {
                dimension: "CrediunoCascadaPdv.puntoVenta",
                operator: "contains",
                values: props.puntoVenta !== "" ? [props.puntoVenta] : [],
            }
        );
    }
    return (
        <QueryRenderer
            query={{
                renewQuery: true,
                measures: measures,
                timeDimensions: [],
                filters: filtros,

                dimensions: [],
            }}
            cubejsApi={cubejsApi}
            render={renderChart(porcentajes, props)}
        />
    );
};

export default Porcentajes;
