import React from "react";
import { QueryRenderer } from "@cubejs-client/react";
import { Spin, Row, Col, Card, Typography } from "antd";
import NumberFormat from "react-number-format";
import cubejsApi from "../../../../cubejsApiBQ";
import Cantidades from "./Cantidades";
import PorcentajesMes from "./PorcentajesMes";
import PorcentajesAnio from "./PorcentajesAnio";

import "../../../../styles/boxRender.css";

const { Title, Text } = Typography;

const COLORS_SERIES = [
    "#b1abe9",
    "#b1abe9",
    "#8dc1ca",
    "#b1abe9",
    "#b1abe9",
    "#8dc1ca",
    "#5cc8d6",
    "#80c6b8",
    "#98c3a6",
    "#96c593",
];

const medidas = ({
    resultSet,
    props,
    fechaCorte,
    porcentajesMes,
    porcentajesAnio,
    cantidades,
}) => {
    const NAME_SERIES = [
        "Cartera Vigente",
        "Cartera al día",
        "Cartera Mora 30",
        "Cartera Mora Total",
        "Cartera 61_90",
        "Cartera Mayor 90",
    ];

    return (
        <Row gutter={[0, 20]} type="flex" justify="space-around">
            {resultSet.seriesNames().map((s, index) => {
                return (
                    <Col key={index} xs={24} lg={11}>
                        <Card
                            size="small"
                            className="topCard"
                            style={{
                                borderTop: "3px solid " + COLORS_SERIES[index],
                            }}
                            bodyStyle={{ padding: "5px 10px 0px 10px" }}
                        >
                            <Row style={{ marginBottom: "5px" }}>
                                <Col>
                                    <Text className="cardTitle">
                                        {NAME_SERIES[index]}
                                    </Text>
                                </Col>
                            </Row>
                            <Row
                                gutter={0}
                                type="flex"
                                justify="space-between"
                                align="middle"
                            >
                                <Col xs={8}></Col>
                                <Col xs={8}></Col>
                                <Col
                                    xs={4}
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                    }}
                                >
                                    <Text className="cardTitle">Mes</Text>
                                </Col>
                                <Col
                                    xs={4}
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                    }}
                                >
                                    <Text className="cardTitle">Año</Text>
                                </Col>
                            </Row>
                            <Row
                                gutter={0}
                                type="flex"
                                justify="space-between"
                                align="middle"
                            >
                                <Col xs={8}>
                                    <Title
                                        style={{
                                            fontSize: "16px",
                                        }}
                                    >
                                        <NumberFormat
                                            value={resultSet.totalRow()[s.key]}
                                            decimalScale={1}
                                            displayType={"text"}
                                            thousandSeparator={true}
                                            prefix={"$"}
                                        />
                                    </Title>
                                </Col>
                                <Col xs={8}>
                                    <Cantidades
                                        {...props}
                                        index={index}
                                        cantidades={cantidades}
                                        fechaCorte={fechaCorte}
                                        cedula={props.cedula}
                                        zona={props.zona}
                                    />
                                </Col>
                                <Col xs={4}>
                                    <PorcentajesMes
                                        {...props}
                                        index={index}
                                        porcentajesMes={porcentajesMes}
                                        fechaCorte={fechaCorte}
                                        cedula={props.cedula}
                                        zona={props.zona}
                                    />
                                </Col>
                                <Col xs={4}>
                                    <PorcentajesAnio
                                        {...props}
                                        index={index}
                                        porcentajesAnio={porcentajesAnio}
                                        fechaCorte={fechaCorte}
                                        cedula={props.cedula}
                                        zona={props.zona}
                                    />
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                );
            })}
        </Row>
    );
};

const renderChart = (
    Component,
    props,
    fechaCorte,
    porcentajesMes,
    porcentajesAnio,
    cantidades
) => ({ resultSet, error }) =>
    (resultSet && (
        <Component
            resultSet={resultSet}
            props={props}
            fechaCorte={fechaCorte}
            porcentajesMes={porcentajesMes}
            porcentajesAnio={porcentajesAnio}
            cantidades={cantidades}
        />
    )) || <Spin />;

const Medidas = (props) => {
    const measures = [
        "ResumenMicrocredito.carteraVigente",
        "ResumenMicrocredito.carteraDia",
        "ResumenMicrocredito.carteraMora30",
        "ResumenMicrocredito.carteraMoraTotal",
        "ResumenMicrocredito.carteraMora6190",
        "ResumenMicrocredito.carteraMoraMayor90",
    ];
    const fechaCorte = "ResumenMicrocredito.fechaCorte";
    const cantidades = [
        "ResumenMicrocredito.cantidadCarteraVigente",
        "",
        "ResumenMicrocredito.cantidadCarteraMora30",
        "ResumenMicrocredito.cantidadCarteraMoraTotal",
        "ResumenMicrocredito.cantidadCarteraMora6190",
        "ResumenMicrocredito.cantidadCarteraMoraMayor90",
    ];
    const porcentajesMes = [
        "ResumenMicrocredito.porcentajeComparacionCarteraVigenteMes",
        "ResumenMicrocredito.porcentajeComparacionCarteraDiaMes",
        "ResumenMicrocredito.porcentajeComparacionCarteraMora30Mes",
        "ResumenMicrocredito.porcentajeComparacionCarteraMoraTotalMes",
        "ResumenMicrocredito.porcentajeComparacionCarteraMora6190Mes",
        "ResumenMicrocredito.porcentajeComparacionCarteraMoraMayor90Mes",
    ];
    const porcentajesAnio = [
        "ResumenMicrocredito.porcentajeComparacionCarteraVigenteAnio",
        "ResumenMicrocredito.porcentajeComparacionCarteraDiaAnio",
        "ResumenMicrocredito.porcentajeComparacionCarteraMora30Anio",
        "ResumenMicrocredito.porcentajeComparacionCarteraMoraTotalAnio",
        "ResumenMicrocredito.porcentajeComparacionCarteraMora6190Anio",
        "ResumenMicrocredito.porcentajeComparacionCarteraMoraMayor90Anio",
    ];

    return (
        <QueryRenderer
            query={{
                renewQuery: true,
                measures: measures,
                timeDimensions: [],
                filters: [
                    {
                        dimension: fechaCorte,
                        operator: "contains",
                        values: props.fecha !== "" ? [props.fecha] : [],
                    },
                    {
                        dimension: "Usuarios.idCedula",
                        operator: "contains",
                        values: props.cedula !== "" ? [props.cedula] : [],
                    },
                    {
                        dimension: "Usuarios.zona",
                        operator: "contains",
                        values: props.zona !== "" ? [props.zona] : [],
                    },
                    // {
                    //   dimension: "Resumen.plaza",
                    //   operator: "contains",
                    //   values: [props.plazas]
                    // }
                ],
                dimensions: [],
            }}
            cubejsApi={cubejsApi}
            render={renderChart(
                medidas,
                props,
                fechaCorte,
                porcentajesMes,
                porcentajesAnio,
                cantidades
            )}
        />
    );
};

export default Medidas;
