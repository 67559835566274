import React from "react";
import { Card, Row, Col } from "antd";
import MedidasActividad from "./MedidasActividad";
import GaugeCdt from "./GaugeCdt";
const ActividadCdt = props => {
  return (
    <Row gutter={[12, 10]} type="flex" justify="space-around">
      <Col xs={24} lg={16}>
        <Card size="small" className="card" style={{ background: "#F2F2F2" }}>
          <MedidasActividad
            mesCorte={props.mesCorte}
            oficina={props.oficina}
            cedula={props.cedula}
          />
        </Card>
      </Col>
      <Col xs={24} lg={8} type="flex" justify="center">
        <Card size="small" className="card" style={{ background: "#F2F2F2" }}>
          <GaugeCdt
            mesCorte={props.mesCorte}
            oficina={props.oficina}
            cedula={props.cedula}
          />
        </Card>
      </Col>
    </Row>
  );
};

export default ActividadCdt;
