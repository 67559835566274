import React, { Component } from "react";
import "../../../styles/staticPage.css";
import "../../../styles/boxRender.css";
import { Table, Spin } from "antd";
import axios from "axios";

const columns = [
    { title: "Fecha Inicio", dataIndex: "FECHA_INICIO" },
    { title: "Tipo", dataIndex: "NOMBRE_SUBPROD" },
    { title: "Número Producto", dataIndex: "NUMERO" },
    { title: "Tasa Actual", dataIndex: "TASA" },
    { title: "Plazo Actual", dataIndex: "PLAZO_ACTUAL" },
    { title: "Saldo Actual", dataIndex: "SALDO_ACTUAL" },
    { title: "Monto Inicial", dataIndex: "MONTO_INICIAL" },
    { title: "Mora Actual", dataIndex: "MORA_ACTUAL" },
];

class Productos extends Component {
    constructor(props) {
        super(props);

        this.state = {
            cliente: [],
        };
    }

    apiSearch = async () => {
        await axios
            .get(
                `https://us-central1-dicocf.cloudfunctions.net/functions/producto/subproducto/cedula?id=${this.props.id}`
            )
            .then((res) => {
                this.setState({
                    cliente: res.data,
                });
                console.log(this.state.cliente);
            });
    };

    componentDidMount = async () => {
        await this.apiSearch();
    };

    componentDidUpdate = async (prevProps) => {
        if (prevProps.id !== this.props.id) {
            await this.apiSearch();
        }
    };

    render() {
        let uniqueId = 0;
        return (
            <div>
                {this.state.cliente ? (
                    <Table
                        columns={columns}
                        dataSource={this.state.cliente}
                        rowKey={(record) => {
                            if (!record.__uniqueId)
                                record.__uniqueId = ++uniqueId;
                            return record.__uniqueId;
                        }}
                        pagination={{
                            defaultPageSize: 5,
                            showSizeChanger: true,
                            pageSizeOptions: ["5", "10", "20"],
                        }}
                        size="small"
                    />
                ) : (
                    <Spin />
                )}
            </div>
        );
    }
}

export default Productos;
