import React from "react";
import { Card, Row, Col } from "antd";
import Medidas from "./Medidas";
import GaugeRender from "./GaugeRender";
import GaugeRenderMultiple from "./GaugeRenderMultiple";

const Embudo = (props) => {
    return (
        <Row gutter={[12, 10]} type="flex" justify="space-around">
            <Col xs={24} lg={24} xl={15}>
                <Card
                    size="small"
                    className="card"
                    style={{ background: "#F2F2F2" }}
                >
                    {/* Cards servicio */}

                    {(props.tipoCargo.includes("ASESOR SERVICIO") ||
                        props.filtroAsesor.includes("ASESOR SERVICIO")) && (
                        <Medidas
                            mes={props.mes}
                            anio={props.anio}
                            zona={props.zona}
                            puntoVenta={props.puntoVenta}
                            cedula={props.cedula}
                            tipo="servicio"
                        />
                    )}

                    {/* Cards Comercial */}

                    {(props.tipoCargo.includes("ASESOR COMERCIAL") ||
                        props.filtroAsesor.includes("ASESOR COMERCIAL")) && (
                        <Medidas
                            mes={props.mes}
                            anio={props.anio}
                            zona={props.zona}
                            puntoVenta={props.puntoVenta}
                            cedula={props.cedula}
                            tipo="comercial"
                        />
                    )}
                    {/* Cards Zonal y Coordinador */}
                    {!props.cargo &&
                        !props.filtroAsesor.includes("ASESOR COMERCIAL") &&
                        !props.filtroAsesor.includes("ASESOR SERVICIO") && (
                            <Medidas
                                mes={props.mes}
                                anio={props.anio}
                                zona={props.zona}
                                puntoVenta={props.puntoVenta}
                                cedula={props.cedula}
                                tipo="zona"
                            />
                        )}
                </Card>
            </Col>
            {/* Gauge comercial */}
            <Col xs={24} lg={24} xl={9}>
                {(props.tipoCargo.includes("ASESOR COMERCIAL") ||
                    props.filtroAsesor.includes("ASESOR COMERCIAL")) && (
                    <GaugeRenderMultiple
                        mes={props.mes}
                        anio={props.anio}
                        zona={props.zona}
                        cedula={props.cedula}
                        puntoVenta={props.puntoVenta}
                        tipo="comercial"
                    />
                )}
                {/* Gauge servicio */}
                {(props.tipoCargo.includes("ASESOR SERVICIO") ||
                    props.filtroAsesor.includes("ASESOR SERVICIO")) && (
                    <GaugeRender
                        mes={props.mes}
                        anio={props.anio}
                        cedula={props.cedula}
                        zona={props.zona}
                    />
                )}
                {/* Gauge Zonal y coordinador */}
                {!props.cargo &&
                    !props.filtroAsesor.includes("ASESOR COMERCIAL") &&
                    !props.filtroAsesor.includes("ASESOR SERVICIO") && (
                        <GaugeRenderMultiple
                            mes={props.mes}
                            anio={props.anio}
                            zona={props.zona}
                            cedula={props.cedula}
                            puntoVenta={props.puntoVenta}
                            tipo="zona"
                        />
                    )}
            </Col>
        </Row>
    );
};

export default Embudo;
