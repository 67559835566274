import React, { useState, useEffect } from "react";
import { QueryRenderer } from "@cubejs-client/react";
import { Spin } from "antd";
import Chart from "react-apexcharts";
import cubejsApi from "../../../cubejsApi";
import RankingTigoZonal from "./RankingTigoZonal";
import RankingTigoRegional from "./RankingTigoRegional";
import RankingTigoNacional from "./RankingTigoNacional";

const numeroMayor = (consulta, resultSet) => {
  var aux = 0;
  // var max = Math.max(
  //   parseInt(zonal.data[0][0]),
  //   parseInt(regional.data[0][0]),
  //   parseInt(nacional.data[0][0])
  // );

  resultSet.loadResponse.data.map(result => {
    if (aux < result[consulta]) {
      aux = result[consulta];
    }
    return null;
  });
  // if (aux < max) {
  //   aux = max;
  // }
  return aux + aux * 0.1;
};

const rankingTigo = ({ resultSet, zonal, regional, nacional }) => {
  var maximo = 0;

  maximo = numeroMayor("RESUMENTigo.vrFinanciado", resultSet);

  const options = {
    plotOptions: {
      bubble: {
        minBubbleRadius: 10
        // maxBubbleRadius: 30
      }
    },
    grid: {
      show: true,
      xaxis: {
        lines: {
          show: false
        }
      },
      yaxis: {
        lines: {
          show: false
        }
      }
    },
    chart: {
      toolbar: {
        show: false
      },
      zoom: {
        enabled: false,
        autoScaleYaxis: true,
        type: "xy"
      },
      background: "#fafafa"
    },
    colors: [
      "#2E93fA",
      "#66DA26",
      "#546E7A",
      "#E91E63",
      "#FF9800",
      "#486ac3",
      "#908245",
      "#3f7f7c",
      "#1acfed",
      "#170b2e",
      "#80a9b7",
      "#ec7ec5",
      "#ab8a9f",
      "#1c8f57",
      "#000be8",
      "#6fefa9",
      "#86b275",
      "#8e8b6f"
    ],
    dataLabels: {
      enabled: false
    },
    fill: {
      opacity: 0.8
    },
    title: {
      // text: "Simple Bubble Chart"
    },
    xaxis: {
      title: {
        text: "Valor Financiado (Millones)",
        offsetX: 0,
        offsetY: 0,
        style: {
          color: undefined,
          fontSize: "15px",
          fontFamily: "Helvetica, Arial, sans-serif",
          cssClass: "apexcharts-xaxis-title"
        }
      },
      tickAmount: 10,
      min: 0,
      max: maximo
    },
    yaxis: {
      title: {
        text: "Valor Financiado Isla (Millones)",
        type: "category",
        offsetX: 0,
        offsetY: 0,
        style: {
          color: undefined,
          fontSize: "15px",
          fontFamily: "Helvetica, Arial, sans-serif",
          cssClass: "apexcharts-xaxis-title"
        }
      },
      min: 0,
      // max: 15,
      forceNiceScale: true
    },
    responsive: [
      {
        breakpoint: 1000,
        options: {
          xaxis: {
            labels: {
              show: true,
              trim: true
            },
            tickAmount: 10,
            min: 0,
            max: 100
          },
          yaxis: {
            min: 0,
            // max: 15,
            forceNiceScale: true
          },
          legend: {
            show: true
          },
          chart: {
            width: "100%",
            zoom: {
              enabled: false,
              autoScaleYaxis: true,
              type: "xy"
            }
          }
        }
      }
    ],
    tooltip: {
      x: {
        show: false,
        formatter: function(val) {
          return "Vr Financiado: " + val;
        }
      },
      y: {
        formatter: function(val) {
          return val.toFixed(1);
        },
        title: {
          formatter: function(seriesName) {
            return seriesName + "<br>" + "Vr Financiado Isla";
          }
        }
      },
      z: {
        formatter: function(val) {
          return val.toFixed(2) + "%";
        },
        title: "Cumplimiento: "
      }
    }
  };

  var series = [];
  if (resultSet.series()[0] === undefined) {
    series = [{ name: "No hay datos", data: [["0", 0, 0]] }];
  } else {
    series = resultSet.series()[0].series.map((r, index) => {
      var original = r.category;
      var result = original.substr(original.indexOf(" ") + 1);
      var resultor = result.substr(result.indexOf(" ") + 1);
      return {
        name: resultor,
        data: [
          [
            r.value === null ? 0 : r.value.toFixed(1),
            resultSet.series()[1].series[index].value === null
              ? 0
              : resultSet.series()[1].series[index].value.toFixed(1),
            resultSet.series()[2].series[index].value === null
              ? 0
              : resultSet.series()[2].series[index].value
          ]
        ]
      };
    });
  }

  const seriesTotal = series;
  // .concat(zonal)
  // .concat(regional)
  // .concat(nacional);

  return (
    <div style={{ width: "100%" }}>
      <Chart
        options={options}
        series={seriesTotal}
        type="bubble"
        height="400px"
      />
    </div>
  );
};

const renderChart = (Component, zonal, regional, nacional) => ({
  resultSet,
  error
}) =>
  (resultSet && (
    <Component
      resultSet={resultSet}
      zonal={zonal}
      regional={regional}
      nacional={nacional}
    />
  )) || <Spin />;

const RankingTigo = props => {
  var [zonal, setZonal] = useState({
    name: "",
    data: [["0", 0, 0]]
  });
  var [zonalX, setZonalX] = useState("0");
  var [zonalY, setZonalY] = useState(0);
  var [zonalZ, setZonalZ] = useState(0);

  var [regional, setRegional] = useState({
    name: "",
    data: [["0", 0, 0]]
  });
  var [regionalX, setRegionalX] = useState("0");
  var [regionalY, setRegionalY] = useState(0);
  var [regionalZ, setRegionalZ] = useState(0);

  var [nacional, setNacional] = useState({
    name: "",
    data: [["0", 0, 0]]
  });
  var [nacionalX, setNacionalX] = useState("0");
  var [nacionalY, setNacionalY] = useState(0);
  var [nacionalZ, setNacionalZ] = useState(0);

  useEffect(() => {
    setZonal({ name: "ZONAL", data: [[zonalX, zonalY, zonalZ]] });
    setRegional({
      name: "REGIONAL",
      data: [[regionalX, regionalY, regionalZ]]
    });
    setNacional({
      name: "NACIONAL",
      data: [[nacionalX, nacionalY, nacionalZ]]
    });
  }, [
    zonalX,
    zonalY,
    zonalZ,
    regionalX,
    regionalY,
    regionalZ,
    nacionalX,
    nacionalY,
    nacionalZ
  ]);
  var dimensiones = "";
  var filtros = [
    {
      dimension: "RESUMENTigo.mesCorte",
      operator: "contains",
      values: [props.fecha]
    },
    {
      dimension: "RESUMENTigo.supervisor",
      operator: "contains",
      values: [props.supervisor]
    }
  ];

  if (props.tipo === "supervisor") {
    dimensiones = "RESUMENTigo.supervisor";
  } else {
    dimensiones = "RESUMENTigo.punto";
    filtros = filtros.concat({
      dimension: "RESUMENTigo.punto",
      operator: "contains",
      values: [props.punto]
    });
  }
  return (
    <div style={{ width: "100%" }}>
      {/* <RankingTigoZonal
        zona={props.zona}
        fecha={props.fecha}
        setZonalX={setZonalX}
        setZonalY={setZonalY}
        setZonalZ={setZonalZ}
      />
      <RankingTigoRegional
        region={props.region}
        fecha={props.fecha}
        setRegionalX={setRegionalX}
        setRegionalY={setRegionalY}
        setRegionalZ={setRegionalZ}
      />
      <RankingTigoNacional
        fecha={props.fecha}
        setNacionalX={setNacionalX}
        setNacionalY={setNacionalY}
        setNacionalZ={setNacionalZ}
      /> */}
      <QueryRenderer
        query={{
          renewQuery: true,
          dimensions: [dimensiones],
          timeDimensions: [],
          measures: [
            "RESUMENTigo.vrFinanciado",
            "RESUMENTigo.vrFinanciadoIsla",
            "RESUMENTigo.porcentajePresupuesto"
          ],
          filters: filtros
        }}
        cubejsApi={cubejsApi}
        render={renderChart(rankingTigo, zonal, regional, nacional)}
      />
    </div>
  );
};

export default RankingTigo;
