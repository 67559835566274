import React from "react";
import { Card, Row, Col } from "antd";
import Medidas from "./Medidas";

const Cards = props => {
  return (
    <Row gutter={[12, 10]} type="flex" justify="space-between">
      <Col xs={24} lg={24}>
        <Card size="small" className="card" style={{ background: "#F2F2F2" }}>
          <Medidas
            oficina={props.oficina}
            cedula={props.cedula}
            mesCorte={props.mesCorte}
          />
        </Card>
      </Col>
    </Row>
  );
};

export default Cards;
