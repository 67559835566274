import React from "react";
import { QueryRenderer } from "@cubejs-client/react";
import { Spin } from "antd";
import Chart from "react-apexcharts";
import cubejsApi from "../../../cubejsApiBQ";

const cumplimientoCorteGauge = ({ resultSet, props }) => {
    var series = [];
    resultSet.seriesNames().map((s, index) => {
        if (resultSet.totalRow()[s.key] === null) {
            return (series[index] = 0);
        } else {
            return (series[index] = resultSet.totalRow()[s.key].toFixed(2));
        }
    });
    const options = {
        chart: {
            type: "radialBar",
            offsetY: -20,
        },
        plotOptions: {
            radialBar: {
                startAngle: -90,
                endAngle: 90,
                track: {
                    background: "#e7e7e7",
                    strokeWidth: "97%",
                    margin: 5, // margin is in pixels
                    dropShadow: {
                        enabled: true,
                        top: 2,
                        left: 0,
                        color: "#999",
                        opacity: 1,
                        blur: 2,
                    },
                },
                dataLabels: {
                    name: {
                        fontSize: "14px",
                        offsetY: 0,
                        color: "#888",
                    },
                    value: {
                        offsetY: -40,
                        fontSize: "22px",
                        color: undefined,
                        formatter: function (val) {
                            return val + "%";
                        },
                    },
                },
            },
        },
        fill: {
            type: "gradient",
            gradient: {
                shade: "dark",
                type: "horizontal",
                shadeIntensity: 0.5,
                gradientToColors: ["#91CECF"],
                inverseColors: true,
                opacityFrom: 1,
                opacityTo: 1,
                stops: [0, 100],
            },
        },
        stroke: {
            dashArray: 4,
        },
        labels: ["Dia Corte"],
    };

    return (
        <div
            style={{
                width: "100%",
            }}
        >
            <Chart
                style={{ margin: "-26px" }}
                options={options}
                series={series}
                type="radialBar"
                height={"320px"}
            />
        </div>
    );
};

const renderChart = (Component, props) => ({ resultSet, error }) =>
    (resultSet && <Component resultSet={resultSet} props={props} />) || (
        <Spin />
    );

const CumplimientoCorteGauge = (props) => {
    var cargo = "JEFE";
    var filtros = [];
    if (props.supervisor !== "") {
        cargo = "SUPERVISOR";
        filtros = [
            {
                dimension: "CumplimientoAlDiaTigo.supervisor",
                operator: "contains",
                values: [props.supervisor],
            },
        ];
    }
    if (props.punto !== "") {
        cargo = "PUNTO";
        filtros = [
            {
                dimension: "CumplimientoAlDiaTigo.punto",
                operator: "contains",
                values: [props.punto],
            },
        ];
    }
    filtros = filtros.concat(
        {
            dimension: "CumplimientoAlDiaTigo.mesCorte",
            operator: "contains",
            values: props.fecha !== "" ? [props.fecha] : [],
        },
        {
            dimension: "CumplimientoAlDiaTigo.canal",
            operator: "contains",
            values: ["Total"],
        },
        {
            dimension: "CumplimientoAlDiaTigo.cargo",
            operator: "contains",
            values: [cargo],
        }
    );

    return (
        <QueryRenderer
            query={{
                renewQuery: true,
                measures: ["CumplimientoAlDiaTigo.cumplimientoAlCorte"],
                timeDimensions: [],
                filters: filtros,
            }}
            cubejsApi={cubejsApi}
            render={renderChart(cumplimientoCorteGauge, props)}
        />
    );
};

export default CumplimientoCorteGauge;
