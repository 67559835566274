import React from "react";
import { Table, Button, Row, Col, Popover, Input } from "antd";
import NativeSelect from "@material-ui/core/NativeSelect";
import { SearchOutlined } from "@ant-design/icons";

const types = [
    { value: "", label: "Prospectos" },
    { value: "Tabla1", label: "Tabla1" },
];

class ListaTrabajo extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            filteredInfo: null,
            sortedInfo: null,
            searchText: "",
            searchedColumn: "",
        };
    }

    getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={(node) => {
                        this.searchInput = node;
                    }}
                    placeholder={`Buscar Nombre`}
                    value={selectedKeys[0]}
                    onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() =>
                        this.handleSearch(selectedKeys, confirm, dataIndex)
                    }
                    style={{ width: 188, marginBottom: 8, display: "block" }}
                />
                <Button
                    type="primary"
                    onClick={() =>
                        this.handleSearch(selectedKeys, confirm, dataIndex)
                    }
                    size="small"
                    style={{ width: 90, marginRight: "10px" }}
                >
                    Buscar
                </Button>
                <Button
                    onClick={() => this.handleReset(clearFilters)}
                    size="small"
                    style={{ width: 90 }}
                >
                    Reset
                </Button>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{ color: filtered ? "#1890ff" : undefined }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => this.searchInput.select());
            }
        },
        render: (text) =>
            this.state.searchedColumn === dataIndex
                ? // <Highlighter
                  //   highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
                  //   searchWords={[this.state.searchText]}
                  //   autoEscape
                  //   textToHighlight={text.toString()}
                  // />
                  text
                : text,
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = (clearFilters) => {
        clearFilters();
        this.setState({ searchText: "" });
    };

    handleChange = (pagination, filters, sorter) => {
        this.setState({
            filteredInfo: filters,
            sortedInfo: sorter,
        });
    };

    clearAll = () => {
        this.setState({
            filteredInfo: null,
            sortedInfo: null,
            searchText: "",
            searchedColumn: "",
        });
    };

    compareByAlph(a, b) {
        if (a > b) {
            return -1;
        }
        if (a < b) {
            return 1;
        }
        return 0;
    }

    render() {
        let { sortedInfo } = this.state;
        const { clientes } = this.props;
        sortedInfo = sortedInfo || {};

        const data = clientes.map((e) => {
            const obj = {
                key: `${e.ID_CEDULA}`,
                name: `${e.NOMBRE_CLIENTE} ${e.APELLIDO_CLIENTE}`,
                vinculacion: "",
                ultimoContacto: "",
                tags: ["Llamar", "Mensaje"],
            };
            return obj;
        });

        const columns = [
            {
                title: "Nombre",
                dataIndex: "name",
                key: "name",
                ...this.getColumnSearchProps("name"),
                sorter: (a, b) => this.compareByAlph(a.name, b.name),
                sortOrder: sortedInfo.columnKey === "name" && sortedInfo.order,
            },
            {
                title: "Vinculacion",
                dataIndex: "vinculacion",
                key: "vinculacion",
                width: "23%",
                sorter: (a, b) =>
                    this.compareByAlph(a.vinculacion, b.vinculacion),
                sortOrder:
                    sortedInfo.columnKey === "vinculacion" && sortedInfo.order,
            },
            {
                title: "Ultimo Contacto",
                dataIndex: "ultimoContacto",
                key: "ultimoContacto",
                width: "23%",
                sorter: (a, b) =>
                    this.compareByAlph(a.ultimoContacto, b.ultimoContacto),
                sortOrder:
                    sortedInfo.columnKey === "ultimoContacto" &&
                    sortedInfo.order,
            },
            {
                title: "Acción Rápida",
                key: "tags",
                dataIndex: "tags",
                render: (tags) => (
                    <Popover
                        content={tags.map((tag, index) => (
                            <div key={index}>
                                <Button
                                    style={{
                                        marginBottom: "5px",
                                    }}
                                    size="small"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                    }}
                                >
                                    {tag}
                                </Button>
                            </div>
                        ))}
                    >
                        <Button
                            type="primary"
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                        >
                            Acciones
                        </Button>
                    </Popover>
                ),
            },
        ];
        return (
            <>
                <Row
                    gutter={[24, 24]}
                    type="flex"
                    justify="start"
                    style={{ marginBottom: "10px" }}
                >
                    <Col>
                        <NativeSelect
                            fullWidth
                            variant="outlined"
                            name="type"
                            inputProps={{
                                name: "accion",
                                id: "outlined-accion-native-simple",
                            }}
                        >
                            {types.map((element, index) => (
                                <option key={index} value={element.value}>
                                    {element.label}
                                </option>
                            ))}
                        </NativeSelect>
                    </Col>
                    <Col>
                        <Button onClick={this.clearAll}>Limpiar filtros</Button>
                    </Col>
                </Row>
                <Table
                    size="small"
                    onRow={(row) => {
                        return {
                            onClick: () => {
                                this.props.handleSelect(row.key);
                            },
                        };
                    }}
                    columns={columns}
                    dataSource={data}
                    onChange={this.handleChange}
                />
            </>
        );
    }
}

export default ListaTrabajo;
