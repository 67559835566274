import React from "react";
import { QueryRenderer } from "@cubejs-client/react";
import { Spin } from "antd";
import { Table } from "antd";
import cubejsApi from "../../../cubejsApiBQ";
import TotalTableRender from "./TotalTableRender";

const tableRender = ({ resultSet, props }) => {
    var c = resultSet;
    const cedula = props.cedula;
    const fecha = props.fecha;
    const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 1,
    });
    const formatterPercent = new Intl.NumberFormat("en-US", {
        style: "percent",
        minimumFractionDigits: 1,
    });
    resultSet.loadResponse.data.map((result, index) => {
        if (
            (typeof result["BaseResumenColocacion.porcentajeDesembolsado"] &&
                typeof result["BaseResumenColocacion.promedioDesembolso"] &&
                typeof result[
                    "BaseResumenColocacion.variacionRespectoAlPromedio"
                ] &&
                typeof result[
                    "BaseResumenColocacion.montoEstadoDesembolsado"
                ]) === "number"
        ) {
            c.loadResponse.data[index][
                "BaseResumenColocacion.porcentajeDesembolsado"
            ] = formatterPercent.format(
                result["BaseResumenColocacion.porcentajeDesembolsado"] / 100
            );
            c.loadResponse.data[index][
                "BaseResumenColocacion.promedioDesembolso"
            ] = formatter.format(
                result["BaseResumenColocacion.promedioDesembolso"]
            );
            c.loadResponse.data[index][
                "BaseResumenColocacion.variacionRespectoAlPromedio"
            ] = formatterPercent.format(
                result["BaseResumenColocacion.variacionRespectoAlPromedio"] /
                    100
            );
            c.loadResponse.data[index][
                "BaseResumenColocacion.montoEstadoDesembolsado"
            ] = formatter.format(
                result["BaseResumenColocacion.montoEstadoDesembolsado"]
            );
        }

        return null;
    });
    let uniqueId = 0;
    return (
        <Table
            bordered
            size="small"
            pagination={{ pageSize: 6 }}
            rowKey={(record) => {
                if (!record.__uniqueId) record.__uniqueId = ++uniqueId;
                return record.__uniqueId;
            }}
            columns={resultSet.tableColumns().map((c) => ({
                ...c,
                dataIndex: c.key,
                width: 100,
            }))}
            dataSource={c.tablePivot()}
            scroll={{ x: "max-content" }}
            footer={() => {
                return (
                    <TotalTableRender
                        cedula={cedula}
                        fecha={fecha}
                        zona={props.zona}
                    />
                );
            }}
        />
    );
};

const renderChart = (Component, props) => ({ resultSet, error }) =>
    (resultSet && <Component resultSet={resultSet} props={props} />) || (
        <Spin />
    );

const TableRender = (props) => (
    <QueryRenderer
        query={{
            renewQuery: true,
            dimensions: ["BaseResumenColocacion.canal"],
            segments: [],
            measures: [
                "BaseResumenColocacion.montoEstadoDesembolsado",
                "BaseResumenColocacion.porcentajeDesembolsado",
                "BaseResumenColocacion.promedioDesembolso",
                "BaseResumenColocacion.variacionRespectoAlPromedio",
            ],
            filters: [
                {
                    dimension: "BaseResumenColocacion.fechaCorte",
                    operator: "contains",
                    values: props.fecha !== "" ? [props.fecha] : [],
                },
                {
                    dimension: "Usuarios.idCedula",
                    operator: "contains",
                    values: props.cedula !== "" ? [props.cedula] : [],
                },
                {
                    dimension: "BaseResumenColocacion.zona",
                    operator: "contains",
                    values: props.zona !== "" ? [props.zona] : [],
                },
            ],
        }}
        cubejsApi={cubejsApi}
        render={renderChart(tableRender, props)}
    />
);

export default TableRender;
