import React from "react";
import { QueryRenderer } from "@cubejs-client/react";
import { Spin } from "antd";
import { Line } from "react-chartjs-2";
import cubejsApi from "../../../cubejsApiBQ";

const COLORS_SERIES = ["#FF6492", "#141446", "#7A77FF"];

const lineRender = ({ resultSet }) => {
    resultSet.loadResponse.data.sort((a, b) =>
        a["EstimacionColocacion.fecha"] > b["EstimacionColocacion.fecha"]
            ? 1
            : -1
    ); //Ordena el arreglo de objetos por fecha ascendentemente

    const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 1,
    });
    // const categories = resultSet.categories().reverse();
    const data = {
        labels: resultSet.categories().map((c) => c.category.slice(0, -13)),
        datasets: resultSet.series().map((s, index) => {
            // const series = s.series.reverse();
            return {
                label: s.title,
                data: s.series.map((r) => r.value),
                borderColor: COLORS_SERIES[index],
                fill: false,
            };
        }),
    };
    const options = {
        legend: {
            display: true,
        },
        maintainAspectRatio: false,
        responsive: true,
        scales: {
            yAxes: [
                {
                    ticks: {
                        callback: function (value, index, values) {
                            return formatter.format(value);
                        },
                    },
                },
            ],
        },

        tooltips: {
            callbacks: {
                label: function (tooltipItem, data) {
                    var dataset = data.datasets[tooltipItem.datasetIndex];
                    var currentValue = dataset.data[tooltipItem.index];

                    return "Valor: " + formatter.format(currentValue);
                },
                title: function (tooltipItem, data) {
                    return "Fecha Corte: " + data.labels[tooltipItem[0].index];
                },
            },
        },
    };
    return <Line data={data} options={options} />;
};

const renderChart = (Component) => ({ resultSet, error }) =>
    (resultSet && <Component resultSet={resultSet} />) || <Spin />;

const LineRender = (props) => {
    return (
        <QueryRenderer
            query={{
                renewQuery: true,
                // timezone: "America/Bogota",
                dimensions: ["EstimacionColocacion.fecha"],
                timeDimensions: [],
                measures: [
                    "EstimacionColocacion.valorColocacion",
                    "EstimacionColocacion.presupuestoDiario",
                ],
                filters: [
                    {
                        dimension: "EstimacionColocacion.mesCorte",
                        operator: "contains",
                        values:
                            props.fecha !== ""
                                ? [props.fecha.slice(0, -3)]
                                : [],
                    },
                    {
                        dimension: "Usuarios.idCedula",
                        operator: "contains",
                        values: props.cedula !== "" ? [props.cedula] : [],
                    },
                    {
                        dimension: "EstimacionColocacion.zona",
                        operator: "contains",
                        values: props.zona !== "" ? [props.zona] : [],
                    },
                ],
            }}
            cubejsApi={cubejsApi}
            render={renderChart(lineRender)}
        />
    );
};

export default LineRender;
