import React, { Component } from "react";
import "../../../styles/staticPage.css";
import "../../../styles/boxRender.css";
import { Spin, Table } from "antd";
import axios from "axios";

const columns = [
    { title: "Ingreso Basico", dataIndex: "INGRESO_BASICO" },
    { title: "Otros Ingresos", dataIndex: "OTROS_INGRESOS" },
    { title: "Total Ingresos", dataIndex: "TOTAL_INGRESOS" },
    { title: "Egresos Financieros", dataIndex: "EGRESOS_FINANCIEROS" },
    { title: "Otros Egresos", dataIndex: "OTROS_EGRESOS" },
    { title: "Total Egresos", dataIndex: "TOTAL_EGRESOS" },
    { title: "Total Activos", dataIndex: "TOTAL_ACTIVOS" },
    { title: "Total Patrimonio", dataIndex: "TOTAL_PATRIMONIO" },
];

class Financiero extends Component {
    constructor(props) {
        super(props);

        this.state = {
            cliente: {},
        };
    }

    apiSearch = async () => {
        await axios
            .get(
                `https://us-central1-dicocf.cloudfunctions.net/functions/financiero/?id=${this.props.id}`
            )
            .then((res) => {
                this.setState({
                    cliente: res.data,
                });
            });
    };

    componentDidMount = async () => {
        await this.apiSearch();
    };

    componentDidUpdate = async (prevProps) => {
        if (prevProps.id !== this.props.id) {
            await this.apiSearch();
        }
    };
    // $
    // {this.state.cliente[0].INGRESOS.toFixed(
    //     2
    // ).replace(/\d(?=(\d{3})+\.)/g, "$&,")}
    //{this.state.cliente[0].TIPO_ACT}
    render() {
        return (
            <div>
                {this.state.cliente ? (
                    <Table
                        columns={columns}
                        // dataSource={this.state.cliente}
                        pagination={{
                            defaultPageSize: 5,
                            showSizeChanger: true,
                            pageSizeOptions: ["5", "10", "20"],
                        }}
                        size="small"
                    />
                ) : (
                    <Spin />
                )}
            </div>
        );
    }
}

export default Financiero;
