import React from "react";
import { QueryRenderer } from "@cubejs-client/react";
import { Spin, Empty } from "antd";
import { Doughnut } from "react-chartjs-2";
import cubejsApi from "../../../cubejsApiBQ";

const COLORS_SERIES = [
    "#7b9fd8",
    "#b69a66",
    "#51a9c9",
    "#cf8c8f",
    "#4cac92",
    "#b791bb",
    "#86a57b",
    "#7197a2",
    "#5da8a6",
    "#7faeb8",
];

const doughnutRender = ({ resultSet }) => {
    const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 1,
    });
    const data = {
        labels: resultSet.categories().map((c) => c.category),
        datasets: resultSet.series().map((s) => ({
            label: s.title,
            data: s.series.map((r) => r.value),
            backgroundColor: COLORS_SERIES,
            hoverBackgroundColor: COLORS_SERIES,
        })),
    };
    const options = {
        plugins: {
            labels: {
                fontColor: "#fff",
            },
        },
        cutoutPercentage: "60",
        tooltips: {
            callbacks: {
                label: function (tooltipItem, data) {
                    var dataset = data.datasets[tooltipItem.datasetIndex];
                    var meta = dataset._meta[Object.keys(dataset._meta)[0]];
                    var total = meta.total;
                    var currentValue = dataset.data[tooltipItem.index];
                    var percentage = parseFloat(
                        ((currentValue / total) * 100).toFixed(1)
                    );
                    return (
                        formatter.format(currentValue) +
                        " (" +
                        percentage +
                        "%)"
                    );
                },
                title: function (tooltipItem, data) {
                    return data.labels[tooltipItem[0].index];
                },
            },
        },
    };
    if (resultSet.categories().length === 0) {
        return (
            <Empty
                description={
                    <span>Este mes no tienes solicitudes en procesos.</span>
                }
            />
        );
    } else {
        return (
            <Doughnut data={data} options={options} width={100} height={110} />
        );
    }
};

const renderChart = (Component) => ({ resultSet, error }) =>
    (resultSet && <Component resultSet={resultSet} />) || <Spin />;

const DoughnutRender = (props) => (
    <QueryRenderer
        query={{
            renewQuery: true,
            measures: ["BaseResumenColocacion.sumaAproYdesem"],
            timeDimensions: [],
            segments: [],
            filters: [
                {
                    dimension: "BaseResumenColocacion.fechaCorte",
                    operator: "contains",
                    values: props.fecha !== "" ? [props.fecha] : [],
                },
                {
                    dimension: "Usuarios.idCedula",
                    operator: "contains",
                    values: props.cedula !== "" ? [props.cedula] : [],
                },
                {
                    dimension: "BaseResumenColocacion.zona",
                    operator: "contains",
                    values: props.zona !== "" ? [props.zona] : [],
                },
                {
                    dimension: "BaseResumenColocacion.tipoConvenio",
                    operator: "notEquals",
                    values: [" "],
                },
            ],
            dimensions: ["BaseResumenColocacion.tipoConvenio"],
        }}
        cubejsApi={cubejsApi}
        render={renderChart(doughnutRender)}
    />
);

export default DoughnutRender;
