import React from "react";
import { Row, Col, Checkbox } from "antd";

const FilterSearch = props => {
    return (
        <Row gutter={[0, 0]}>
            <Col
                xs={12}
                sm={14}
                lg={14}
                style={{ display: "flex", justifyContent: "flex-start" }}
            >
                <Checkbox
                    disabled={props.cargo}
                    onChange={e => props.setEsComercial(e.target.checked)}
                    defaultChecked={props.esComercial}
                >
                    Asesor Comercial
                </Checkbox>
            </Col>
            <Col
                xs={12}
                sm={10}
                lg={10}
                style={{ display: "flex", justifyContent: "center" }}
            >
                <Checkbox
                    disabled={props.cargo}
                    onChange={e => props.setEsPartTime(e.target.checked)}
                    defaultChecked={props.esPartTime}
                >
                    Part Time
                </Checkbox>
            </Col>

            <Col
                xs={12}
                sm={14}
                lg={14}
                style={{
                    display: "flex",
                    justifyContent: "flex-start"
                }}
            >
                <Checkbox
                    disabled={props.cargo}
                    onChange={e => props.setEsServicio(e.target.checked)}
                    defaultChecked={props.esServicio}
                >
                    Asesor Servicio
                </Checkbox>
            </Col>
            <Col
                xs={12}
                sm={10}
                lg={10}
                style={{ display: "flex", justifyContent: "center" }}
            >
                <Checkbox
                    disabled={props.cargo}
                    onChange={e => props.setEsFullTime(e.target.checked)}
                    defaultChecked={props.esFullTime}
                >
                    Full Time
                </Checkbox>
            </Col>
        </Row>
    );
};

export default FilterSearch;
