import React from "react";
import Oferta from "./ofertas/InfoOfertas";
import Financieros from "./tabs/DatosFinancieros";
import Gestion from "./gestion/InfoGestion";
import Productos from "./tabs/Productos";
import CanalesCom from "./tabs/CanalesCom";
import { Tabs, Row, Button } from "antd";

const { TabPane } = Tabs;

class Info extends React.Component {
    render() {
        const { cliente } = this.props;
        return (
            <>
                <Tabs defaultActiveKey="1" type="card">
                    <TabPane tab="Ofertas" key="1">
                        <Oferta id={cliente.ID_CEDULA} />
                    </TabPane>
                    <TabPane tab="Productos" key="2">
                        <Productos id={cliente.ID_CEDULA} />
                    </TabPane>
                    <TabPane tab="Datos Financieros" key="3">
                        <Financieros id={cliente.ID_CEDULA} />
                    </TabPane>
                    <TabPane tab="Historico Gestión" key="4">
                        <Gestion id={cliente.ID_CEDULA} />
                    </TabPane>
                    <TabPane tab="Canales de Comunicación" key="5">
                        <CanalesCom id={cliente.ID_CEDULA} />
                    </TabPane>
                </Tabs>
                <Row
                    type="flex"
                    justify="start"
                    style={{ margin: "15px 0px 0px 10px" }}
                >
                    <Button
                        type="primary"
                        onClick={(e) => {
                            e.preventDefault();
                            this.props.handleSelect(null);
                        }}
                    >
                        Volver
                    </Button>
                </Row>
            </>
        );
    }
}

export default Info;
