import React from "react";
import { QueryRenderer } from "@cubejs-client/react";
import { Spin, Progress } from "antd";
import cubejsApi from "../../../cubejsApiBQ";

const progresos = ({ resultSet, props }) => {
    const name = props.progresos;
    var valor = resultSet.loadResponse.data[0]
        ? resultSet.loadResponse.data[0][name[props.index]]
        : null;
    var estado = "active";
    if (valor === undefined) {
        return <div></div>;
    }
    if (valor === null) {
        valor = 0.0;
    }
    if (valor >= 100) {
        estado = "success";
    }
    return (
        <Progress
            strokeColor={{
                "0%": "#30bcab",
                "100%": "#51a6ea",
            }}
            status={estado}
            percent={parseInt(valor.toFixed(1))}
        />
    );
};

const renderChart = (Component, props) => ({ resultSet, error }) =>
    (resultSet && <Component resultSet={resultSet} props={props} />) || (
        <Spin />
    );

const Progresos = (props) => {
    var measures;
    if (props.tipo === "servicio") {
        measures = [
            "CrediunoAsesorServicio.porcentajeEntregasComparacion",
            "CrediunoAsesorServicio.porcentajeVentaTotalComparacion",
        ];
    } else if (props.tipo === "comercial") {
        measures = [
            "CrediunoAsesorComercial.porcentajeCumplimientoConsultas",
            "CrediunoAsesorComercial.porcentajeCumplimientoViables",
            "CrediunoAsesorComercial.porcentajeCumplimientoIngresos",
            "CrediunoAsesorComercial.porcentajeCumplimientoAprobadas",
            "CrediunoAsesorComercial.porcentajeCumplimientoEntregas",
            "CrediunoAsesorComercial.porcentajeCumplimientoActivacion",
        ];
    } else {
        measures = [
            "CrediunoCascadaPdv.porcentajeCumplimientoConsultas",
            "CrediunoCascadaPdv.porcentajeCumplimientoViables",
            "CrediunoCascadaPdv.porcentajeCumplimientoIngresos",
            "CrediunoCascadaPdv.porcentajeCumplimientoAprobadas",
            "CrediunoCascadaPdv.porcentajeCumplimientoEntregas",
            "CrediunoCascadaPdv.porcentajeCumplimientoActivacion",
        ];
    }
    var filtros = [
        {
            dimension: props.anioCorte,
            operator: "contains",
            values: props.anio !== "" ? [props.anio] : [],
        },
        {
            dimension: props.mesCorte,
            operator: "contains",
            values: props.mes !== "" ? [props.mes] : [],
        },
    ];
    if (props.tipo === "comercial" || props.tipo === "servicio") {
        filtros = filtros.concat(
            {
                dimension: "Usuarios.idCedula",
                operator: "contains",
                values: props.cedula !== "" ? [props.cedula] : [],
            },
            {
                dimension: "Usuarios.zona",
                operator: "contains",
                values: props.zona !== "" ? [props.zona] : [],
            }
        );
    }
    if (props.tipo !== "comercial" && props.tipo !== "servicio") {
        filtros = filtros.concat(
            {
                dimension: "CrediunoCascadaPdv.zona",
                operator: "contains",
                values: props.zona !== "" ? [props.zona] : [],
            },
            {
                dimension: "CrediunoCascadaPdv.puntoVenta",
                operator: "contains",
                values: props.puntoVenta !== "" ? [props.puntoVenta] : [],
            }
        );
    }

    return (
        <QueryRenderer
            query={{
                renewQuery: true,
                measures: measures,
                timeDimensions: [],
                filters: filtros,

                dimensions: [],
            }}
            cubejsApi={cubejsApi}
            render={renderChart(progresos, props)}
        />
    );
};

export default Progresos;
