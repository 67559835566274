import React from "react";
import { QueryRenderer } from "@cubejs-client/react";
import NumberFormat from "react-number-format";
import { Row, Typography } from "antd";
import { Spin } from "antd";
import cubejsApi from "../../../cubejsApiBQ";

const { Text } = Typography;

const cantCarteraCredipoliza = ({ resultSet, props }) => {
    const name = ["ResumenCarteraHistorico.cantCartera"];
    return (
        <Row gutter={[0, 0]} type="flex" justify="space-between">
            <Text
                style={{
                    fontSize: "16px",
                    fontWeight: "bold",
                }}
            >
                <NumberFormat
                    value={resultSet.loadResponse.data[0][name[props.index]]}
                    decimalScale={0}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"#"}
                />
            </Text>
        </Row>
    );
};

const renderChart = (Component, props) => ({ resultSet, error }) =>
    (resultSet && <Component resultSet={resultSet} props={props} />) || (
        <Spin />
    );

const CantCarteraCredipoliza = (props) => {
    return (
        <QueryRenderer
            query={{
                measures: ["ResumenCarteraHistorico.cantCartera"],
                timeDimensions: [],
                dimensions: [],
                segments: [],
                filters: [
                    {
                        dimension: "ResumenCarteraHistorico.fechaCorte",
                        operator: "contains",
                        values: props.fecha !== "" ? [props.fecha] : [],
                    },
                    {
                        dimension: "ResumenCarteraHistorico.zona",
                        operator: "contains",
                        values: props.zona !== "" ? [props.zona] : [],
                    },
                    {
                        dimension: "ResumenCarteraHistorico.tipoProducto",
                        operator: "contains",
                        values: ["credipoliza"],
                    },
                ],
            }}
            cubejsApi={cubejsApi}
            render={renderChart(cantCarteraCredipoliza, props)}
        />
    );
};

export default CantCarteraCredipoliza;
