import React from "react";
import { QueryRenderer } from "@cubejs-client/react";
import { Spin } from "antd";
import cubejsApi from "../../../cubejsApiBQ";

const rankingRegional = ({ resultSet, props }) => {
    if (resultSet.series()[0] === undefined) {
        props.setRegionalX("0");
        props.setRegionalY(0);
        props.setRegionalZ(0);
    } else {
        resultSet.series()[0].series.map((r, index) => {
            props.setRegionalX(r.value === null ? 0 : r.value.toFixed(1));
            props.setRegionalY(
                resultSet.series()[1].series[index].value === null
                    ? 0
                    : resultSet.series()[1].series[index].value.toFixed(1)
            );
            return props.setRegionalZ(
                resultSet.series()[2].series[index].value === null
                    ? 0
                    : resultSet.series()[2].series[index].value
            );
        });
    }

    return null;
};

const renderChart = (Component, props) => ({ resultSet, error }) =>
    (resultSet && <Component resultSet={resultSet} props={props} />) || (
        <Spin />
    );

const RankingRegional = (props) => {
    return (
        <QueryRenderer
            query={{
                renewQuery: true,
                dimensions: [],
                timeDimensions: [],
                measures: [
                    "ResumenCp.promedioEfectividad",
                    "ResumenCp.promedioIntermediarios",
                    "ResumenCp.promedioMonto",
                ],
                filters: [
                    {
                        dimension: "ResumenCp.fechaCorte",
                        operator: "contains",
                        values: props.fecha !== "" ? [props.fecha] : [],
                    },
                    {
                        dimension: "Usuarios.region",
                        operator: "contains",
                        values: props.region !== "" ? [props.region] : [],
                    },
                ],
            }}
            cubejsApi={cubejsApi}
            render={renderChart(rankingRegional, props)}
        />
    );
};

export default RankingRegional;
