import React from "react";
import { QueryRenderer } from "@cubejs-client/react";
import NumberFormat from "react-number-format";
import { Row, Col, Typography } from "antd";
import { Spin } from "antd";
import cubejsApi from "../../../cubejsApiBQ";

const { Title } = Typography;

const avalRegional = ({ resultSet, props }) => {
    return (
        <div>
            {resultSet.seriesNames().map((s, index) => {
                return (
                    <Row
                        gutter={0}
                        type="flex"
                        justify="space-between"
                        align="middle"
                        key={index}
                    >
                        <Col xs={20}>
                            <Title
                                style={{
                                    fontSize: "12px",
                                    color: "#929988",
                                }}
                            >
                                - Participación Aval Regional:{" "}
                            </Title>
                        </Col>
                        <Col xs={4}>
                            <Title
                                key={index}
                                style={{
                                    fontSize: "12px",
                                    color: "#929988",
                                }}
                            >
                                <NumberFormat
                                    value={resultSet.totalRow()[s.key]}
                                    decimalScale={2}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    suffix={"%"}
                                />
                            </Title>
                        </Col>
                    </Row>
                );
            })}
        </div>
    );
};

const renderChart = (Component, props) => ({ resultSet, error }) =>
    (resultSet && <Component resultSet={resultSet} props={props} />) || (
        <Spin />
    );

const AvalRegional = (props) => {
    return (
        <QueryRenderer
            query={{
                renewQuery: true,
                measures: ["BaseResumenColocacion.porcentajeAval"],
                timeDimensions: [],
                dimensions: [],
                segments: [],
                filters: [
                    {
                        dimension: "BaseResumenColocacion.fechaCorte",
                        operator: "contains",
                        values: props.fecha !== "" ? [props.fecha] : [],
                    },
                    {
                        dimension: "BaseResumenColocacion.region",
                        operator: "contains",
                        values: ["Centro"],
                    },
                ],
            }}
            cubejsApi={cubejsApi}
            render={renderChart(avalRegional, props)}
        />
    );
};

export default AvalRegional;
