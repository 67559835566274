import React from "react";
import { QueryRenderer } from "@cubejs-client/react";
import { Spin, Row, Col, Card, Typography } from "antd";
import NumberFormat from "react-number-format";
import cubejsApi from "../../../cubejsApiBQ";

import "../../../styles/boxRender.css";
import SegundaColumnaTigo from "./SegundaColumnaTigo";
import TerceraColumnaTigo from "./TerceraColumnaTigo";

const { Title, Text } = Typography;

const COLORS_SERIES = [
    "#b1abe9",
    "#72bae8",
    "#8dc1ca",
    "#afb9cb",
    "#a4beb8",
    "#a4badd",
    "#5cc8d6",
    "#80c6b8",
    "#98c3a6",
    "#96c593",
];

const medidasTigo = ({ resultSet, props, fechaCorte, tercera, segunda }) => {
    const NAME_SERIES = [
        "Aprobadas Compra",
        "Aprobadas Sin Compra",
        "Rechazadas",
        "Financiado Punto",
        "Financiado Isla",
        "Consultas",
        "Financiado",
        "Avance",
    ];

    return (
        <Row gutter={[0, 20]} type="flex" justify="space-around">
            {resultSet.seriesNames().map((s, index) => {
                return (
                    <Col key={index} xs={24} lg={7}>
                        <Card
                            size="small"
                            className="topCard"
                            style={{
                                borderTop: "3px solid " + COLORS_SERIES[index],
                            }}
                            bodyStyle={{ padding: "5px 10px 0px 10px" }}
                        >
                            <Row style={{ marginBottom: "10px" }}>
                                <Col>
                                    <Text className="cardTitle">
                                        {NAME_SERIES[index]}
                                    </Text>
                                </Col>
                            </Row>
                            <Row
                                gutter={0}
                                type="flex"
                                justify="space-between"
                                align="middle"
                            >
                                <Col xs={7}>
                                    <Title
                                        style={{
                                            fontSize: "15px",
                                        }}
                                    >
                                        <NumberFormat
                                            value={resultSet.totalRow()[s.key]}
                                            decimalScale={1}
                                            displayType={"text"}
                                            thousandSeparator={true}
                                            prefix={"$"}
                                        />
                                    </Title>
                                </Col>
                                <Col xs={7}>
                                    <SegundaColumnaTigo
                                        {...props}
                                        index={index}
                                        segunda={segunda}
                                        fechaCorte={fechaCorte}
                                        supervisor={props.supervisor}
                                        punto={props.punto}
                                        zona={props.zona}
                                    />
                                </Col>
                                <Col xs={5}>
                                    <TerceraColumnaTigo
                                        {...props}
                                        index={index}
                                        tercera={tercera}
                                        fechaCorte={fechaCorte}
                                        supervisor={props.supervisor}
                                        punto={props.punto}
                                        zona={props.zona}
                                    />
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                );
            })}
        </Row>
    );
};

const renderChart = (Component, props, fechaCorte, tercera, segunda) => ({
    resultSet,
    error,
}) =>
    (resultSet && (
        <Component
            resultSet={resultSet}
            props={props}
            fechaCorte={fechaCorte}
            tercera={tercera}
            segunda={segunda}
        />
    )) || <Spin />;

const MedidasTigo = (props) => {
    const measures = [
        "RESUMENTigo.aprobadasCompra",
        "RESUMENTigo.aprobadasSinCompra",
        "RESUMENTigo.rechazadas",
        "RESUMENTigo.vrFinanciadoPunto",
        "RESUMENTigo.vrFinanciadoIsla",
        "RESUMENTigo.vrConsultas",
        "RESUMENTigo.vrFinanciado",
    ];
    const fechaCorte = "RESUMENTigo.mesCorte";
    const segunda = [
        "RESUMENTigo.cantAprobadasCompra",
        "RESUMENTigo.cantAprobadasSinCompra",
        "RESUMENTigo.cantRechazadas",
        "RESUMENTigo.cantFinanciadoPunto",
        "RESUMENTigo.cantFinanciadoIsla",
        "RESUMENTigo.cantConsultas",
        "RESUMENTigo.cantFinanciado",
    ];
    const tercera = [
        "RESUMENTigo.porcentajeComparacionCompra",
        "RESUMENTigo.porcentajeComparacionSinCompra",
        "RESUMENTigo.porcentajeComparacionRechazadas",
        "RESUMENTigo.porcentajeComparacionFinanciadoPunto",
        "RESUMENTigo.porcentajeComparacionFinanciadoIsla",
        "",
        "RESUMENTigo.porcentajeComparacionFinanciado",
    ];

    return (
        <QueryRenderer
            query={{
                renewQuery: true,
                measures: measures,
                timeDimensions: [],
                filters: [
                    {
                        dimension: fechaCorte,
                        operator: "contains",
                        values: props.fecha !== "" ? [props.fecha] : [],
                    },
                    {
                        dimension: "RESUMENTigo.supervisor",
                        operator: "contains",
                        values:
                            props.supervisor !== "" ? [props.supervisor] : [],
                    },
                    {
                        dimension: "RESUMENTigo.punto",
                        operator: "contains",
                        values: props.punto !== "" ? [props.punto] : [],
                    },
                ],
                dimensions: [],
            }}
            cubejsApi={cubejsApi}
            render={renderChart(
                medidasTigo,
                props,
                fechaCorte,
                tercera,
                segunda
            )}
        />
    );
};

export default MedidasTigo;
