import React from "react";
import { QueryRenderer } from "@cubejs-client/react";
import { Spin } from "antd";
import cubejsApi from "../../cubejsApiBQ";

const infoRender = ({ resultSet, props }) => {
    const zona = resultSet.loadResponse.data[0]["Usuarios.zona"];
    const region = resultSet.loadResponse.data[0]["Usuarios.region"];
    // const ant = resultSet.loadResponse.data[0]["Usuarios.antiguedad"];
    const nombre = resultSet.loadResponse.data[0]["Usuarios.nombre"];
    const cargo = resultSet.loadResponse.data[0]["Usuarios.cargo"];

    if (
        cargo.includes("ZONA") ||
        cargo.includes("COORDINADOR") ||
        cargo.includes("JEFE DE NEGOCIO MICROCREDITO") ||
        cargo.includes("JEFE OFICINA CDT")
    ) {
        props.setCargo(false);
    } else {
        props.setCargo(true);
    }
    props.setZona(zona);
    props.setRegion(region);
    props.setNombre(nombre);
    props.setTipoCargo(cargo);
    return zona;
};

const renderChart = (Component, props) => ({ resultSet, error }) => {
    if (resultSet) props.setIsReady(true);
    return (
        (resultSet && <Component resultSet={resultSet} props={props} />) || (
            <Spin />
        )
    );
};

const InfoRender = (props) => {
    return (
        <QueryRenderer
            query={{
                renewQuery: true,
                measures: [],
                timeDimensions: [],
                dimensions: [
                    "Usuarios.idCedula",
                    "Usuarios.nombre",
                    "Usuarios.correo",
                    "Usuarios.cargo",
                    "Usuarios.zona",
                    "Usuarios.region",
                    "Usuarios.antiguedad",
                ],
                filters: [
                    {
                        dimension: "Usuarios.correo",
                        operator: "equals",
                        values: [props.user],
                        // values: ["123456@d4d.com.co"],
                        // values: ["oficina102@credfinanciera.com.co"], //CDT JEFE
                        // values: ["asesor1_oficina102@credfinanciera.com.co"], //CDT
                        // values: ["tmontoya@asficredito.com"],
                        // values: ["jgaitan@credivalores.com"], //TIGO JEFE
                        // values: ["anieto@credivalores.com"] //TIGO
                        // values: ["crendon@asficredito.com"], //Crediuno coordinador
                        // values: ["jhon.hernandez@credivalores.com"], //Crediuno comercial
                        // values: ["rmolanos@credifinanciera.com.co"], //Microcredito
                        // values: ["tobar.ruth@credifinanciera.com.co"] //Microcredito
                    },
                ],
            }}
            cubejsApi={cubejsApi}
            render={renderChart(infoRender, props)}
        />
    );
};

export default InfoRender;
