import React, { Component } from "react";
import { Row, Col, Menu, Layout, Descriptions } from "antd";
import {
    PhoneOutlined,
    FormOutlined,
    UserOutlined,
    UserDeleteOutlined,
} from "@ant-design/icons";
import moment from "moment";
import axios from "axios";
// import InfoBasica from "../components/infoGestion/InfoBasica";
import InfoCompleta from "../components/infoGestion/InfoCompleta";
// import HomeGestion from "../components/infoGestion/HomeGestion";
import HomeAdmin from "../components/gestionAdmin/HomeAdmin";
// import HomeCall from "../components/gestionCall/HomeCall";
import ListaTrabajo from "../components/infoGestion/ListaTrabajo";

const { Content, Sider } = Layout;
const admin = true;

class Test extends Component {
    constructor(props) {
        super(props);
        this.state = {
            clientes: [],
            cliente: null,
        };
    }

    convert(fecha) {
        let newDate;
        moment().add();
        newDate = moment(fecha).add(1, "days").format("L");

        return newDate;
    }

    handleSelect = (key) => {
        if (key === null) {
            this.setState({ cliente: key });
        } else {
            const selected = this.state.clientes.find(
                (e) => e.ID_CEDULA === key
            );
            this.setState({ cliente: selected });
        }
    };

    apiGet = async () => {
        await axios
            .get(
                `https://us-central1-dicocf.cloudfunctions.net/functions/clientes`
            )
            .then((res) => {
                this.setState({
                    clientes: res.data,
                });
            });
    };

    componentDidMount = async () => {
        await this.apiGet();
    };

    render() {
        const { cliente } = this.state;

        return (
            <Layout
                style={{
                    background: "#F3F9FF",
                    height: window.innerHeight - 90,
                }}
            >
                {!this.props.admin && (
                    <Sider width={200} style={{ border: "none" }} theme="light">
                        <Menu
                            defaultSelectedKeys={["1"]}
                            mode="inline"
                            theme="light"
                            style={{ borderRight: 0 }}
                        >
                            <Menu.Item key="1">
                                <FormOutlined />
                                Prospectos
                            </Menu.Item>
                            <Menu.Item key="2">
                                <PhoneOutlined />
                                Contacto
                            </Menu.Item>
                            <Menu.Item key="3">
                                <UserOutlined />
                                Clientes
                            </Menu.Item>
                            <Menu.Item key="4">
                                <UserDeleteOutlined />
                                ExCliente
                            </Menu.Item>
                        </Menu>
                    </Sider>
                )}

                <Content
                    style={{
                        marginTop: !cliente ? 40 : 20,
                        minHeight: 280,
                    }}
                >
                    {this.props.admin ? (
                        <HomeAdmin />
                    ) : (
                        <Row gutter={0} type="flex" justify="center">
                            {cliente ? (
                                <>
                                    <Col
                                        xs={22}
                                        style={{
                                            padding: "20px",
                                            marginBottom: "20px",
                                            background: "#ffffff",
                                            borderRadius: "3px",
                                        }}
                                    >
                                        <Descriptions
                                            title="Información"
                                            bordered
                                            size="small"
                                        >
                                            <Descriptions.Item label="Nombre">
                                                {cliente.NOMBRE_CLIENTE +
                                                    " " +
                                                    cliente.APELLIDO_CLIENTE}
                                            </Descriptions.Item>
                                            <Descriptions.Item label="Cédula">
                                                {cliente.ID_CEDULA}
                                            </Descriptions.Item>
                                            <Descriptions.Item label="Edad">
                                                {moment().diff(
                                                    cliente.NACIMIENTO,
                                                    "years",
                                                    false
                                                )}
                                            </Descriptions.Item>
                                            <Descriptions.Item label="Género">
                                                Género
                                            </Descriptions.Item>
                                            <Descriptions.Item label="Segmento">
                                                Segmento
                                            </Descriptions.Item>
                                            <Descriptions.Item label="Responsable a"></Descriptions.Item>
                                            <Descriptions.Item label="Tiempo comno cliente"></Descriptions.Item>
                                            <Descriptions.Item label="Cliente desde"></Descriptions.Item>
                                        </Descriptions>
                                    </Col>
                                    <Col
                                        xs={22}
                                        style={{
                                            padding: "0px 20px 20px 20px",
                                            background: "#ffffff",
                                            borderRadius: "3px",
                                        }}
                                    >
                                        <InfoCompleta
                                            handleSelect={this.handleSelect.bind(
                                                this
                                            )}
                                            cliente={this.state.cliente}
                                        />
                                    </Col>
                                </>
                            ) : (
                                <Col
                                    xs={18}
                                    style={{
                                        padding: "20px",
                                        background: "#ffffff",
                                        borderRadius: "3px",
                                    }}
                                >
                                    <ListaTrabajo
                                        clientes={this.state.clientes}
                                        handleSelect={this.handleSelect.bind(
                                            this
                                        )}
                                    />
                                </Col>
                            )}
                        </Row>
                    )}
                </Content>
            </Layout>
        );
    }
}

export default Test;
