import React from "react";
import { Row, Col, Typography, Card, Icon } from "antd";
import EfectividadRender from "./EfectividadRender";
import AvalRender from "./AvalRender";
import TasasRender from "./TasasRender";
import Tooltip from "react-png-tooltip";

import "../../../styles/boxRender.css";

const { Text } = Typography;

const COLORS_SERIES = ["#3BABCC"];
const NAME_SERIES = ["Colocación Efectividad", "Aval", "Tasas"];

const BoxRender3 = props => {
  return (
    <Row gutter={[12, 12]} type="flex" justify="space-between">
      <Col xs={24} lg={12}>
        <Card
          title={<Text className="cardTitle">{NAME_SERIES[0]}</Text>}
          extra={
            <div
              align="right"
              fontSize="12px"
              style={{
                marginRight: "10px"
              }}
            >
              <Tooltip
                shouldDisableClick
                className="GreyTooltip"
                tooltip={<Icon type="info-circle" />}
              >
                {" "}
                <p
                  align="left"
                  style={{
                    marginBottom: "0em"
                  }}
                >
                  Colocación Efectividad: <br />- Colocación: Créditos en
                  estados Desembolsados y aprobados en el mes (Monto y Número) y
                  porcentaje de cumplimiento respecto a la meta del mes.
                  <br />- Indicador de efectividad (% de Aprobación):
                  Participación de colocaciones en el mes sobre radicaciones del
                  mes.
                  <br />- Comparación con el indicador de efectividad por zona,
                  regional y nacional.
                </p>
              </Tooltip>
            </div>
          }
          size="small"
          className="indicatorCard"
          style={{
            borderTop: "3px solid" + COLORS_SERIES[0]
          }}
          // bodyStyle={{ padding: "10px" }}
        >
          {/* Contenido Card */}
          <EfectividadRender {...props}></EfectividadRender>
        </Card>
      </Col>
      <Col xs={24} lg={12}>
        <Card
          title={<Text className="cardTitle">{NAME_SERIES[1]}</Text>}
          extra={
            <div
              align="right"
              fontSize="12px"
              style={{
                marginRight: "10px"
              }}
            >
              <Tooltip
                shouldDisableClick
                className="GreyTooltip"
                tooltip={<Icon type="info-circle" />}
              >
                <p
                  align="left"
                  style={{
                    marginBottom: "0em"
                  }}
                >
                  Aval:
                  <br />- Monto (millones) y participación sobre las
                  colocaciones del mes. <br />- Comparación con la participación
                  zonal, regional y nacional.
                </p>
              </Tooltip>
            </div>
          }
          size="small"
          className="indicatorCard"
          style={{
            borderTop: "3px solid" + COLORS_SERIES[0]
          }}
        >
          {/* Contenido Card */}
          <AvalRender {...props}></AvalRender>
        </Card>
      </Col>
      <Col xs={24} lg={12}>
        <Card
          title={<Text className="cardTitle">{NAME_SERIES[2]}</Text>}
          extra={
            <div
              align="right"
              fontSize="12px"
              style={{
                marginRight: "10px"
              }}
            >
              <Tooltip
                shouldDisableClick
                className="GreyTooltip"
                tooltip={<Icon type="info-circle" />}
              >
                <p
                  align="left"
                  style={{
                    marginBottom: "0em"
                  }}
                >
                  Tasas:
                  <br />- Tasa promedio ponderada por monto de las colocaciones
                  del mes.
                  <br />- Comparación con la tasa promedio ponderada del mes de
                  zona, regional y nacional.
                </p>
              </Tooltip>
            </div>
          }
          size="small"
          className="indicatorCard"
          style={{
            borderTop: "3px solid" + COLORS_SERIES[0]
          }}
        >
          {/* Contenido Card */}
          <TasasRender {...props}></TasasRender>
        </Card>
      </Col>
    </Row>
  );
};

export default BoxRender3;
