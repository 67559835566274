import React from "react";
import { QueryRenderer } from "@cubejs-client/react";
import { Spin } from "antd";
import { Table } from "antd";
import cubejsApi from "../../../cubejsApiBQ";
import TotalTable from "../carteraZonal/TotalTableCartera";

const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 1,
});
const formatterPercent = new Intl.NumberFormat("en-US", {
    style: "percent",
    minimumFractionDigits: 1,
});

const tableCartera = ({ resultSet, props }) => {
    var c = resultSet;
    // const update = val => {
    //   return parseInt(val.replace(/,|\.|\$|%/g, ""));
    // };

    resultSet.loadResponse.data.map((result, index) => {
        if (
            (typeof result["ResumenCarteraHistorico.variacionMes"] &&
                typeof result["ResumenCarteraHistorico.variacionAnho"] &&
                typeof result["ResumenCarteraHistorico.montoCartera"]) ===
            "number"
        ) {
            c.loadResponse.data[index][
                "ResumenCarteraHistorico.variacionMes"
            ] = formatterPercent.format(
                result["ResumenCarteraHistorico.variacionMes"] / 100
            );
            c.loadResponse.data[index][
                "ResumenCarteraHistorico.variacionAnho"
            ] = formatterPercent.format(
                result["ResumenCarteraHistorico.variacionAnho"] / 100
            );
            c.loadResponse.data[index][
                "ResumenCarteraHistorico.montoCartera"
            ] = formatter.format(
                result["ResumenCarteraHistorico.montoCartera"]
            );
        }
        return null;
    });
    let uniqueId = 0;
    return (
        <Table
            bordered
            size="small"
            pagination={{ pageSize: 10 }}
            rowKey={(record) => {
                if (!record.__uniqueId) record.__uniqueId = ++uniqueId;
                return record.__uniqueId;
            }}
            columns={resultSet.tableColumns().map((c) => ({
                ...c,
                dataIndex: c.key,
                width: 100,
            }))}
            dataSource={c.tablePivot()}
            scroll={{ x: "max-content" }}
            footer={() => {
                return (
                    <TotalTable
                        cedula={props.cedula}
                        fecha={props.fecha}
                        zona={props.zona}
                    />
                );
            }}
        />
    );
};

const renderChart = (Component, props) => ({ resultSet, error }) =>
    (resultSet && <Component resultSet={resultSet} props={props} />) || (
        <Spin />
    );

const TableCartera = (props) => (
    <QueryRenderer
        query={{
            dimensions: ["ResumenCarteraHistorico.canal"],
            timeDimensions: [
                {
                    dimension: "ResumenCarteraHistorico.fechaCorte",
                },
            ],
            segments: [],
            measures: [
                "ResumenCarteraHistorico.montoCartera",
                "ResumenCarteraHistorico.cantCartera",
                "ResumenCarteraHistorico.variacionMes",
                "ResumenCarteraHistorico.variacionAnho",
            ],
            filters: [
                {
                    dimension: "ResumenCarteraHistorico.fechaCorte",
                    operator: "contains",
                    values: props.fecha !== "" ? [props.fecha] : [],
                },
                {
                    dimension: "ResumenCarteraHistorico.zona",
                    operator: "contains",
                    values: props.zona !== "" ? [props.zona] : [],
                },
                {
                    dimension: "ResumenCarteraHistorico.tipoProducto",
                    operator: "contains",
                    values: ["libranzas"],
                },
            ],
        }}
        cubejsApi={cubejsApi}
        render={renderChart(tableCartera, props)}
    />
);

export default TableCartera;
