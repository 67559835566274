import React, { useState } from "react";
import Tooltip from "react-png-tooltip";
import { Icon, Card, Row, Col } from "antd";

import ActividadCdt from "../components/cdt/actividad/ActividadCdt";
import CascaderCdt from "../components/cdt/CascaderCdt";
import SearchCdt from "../components/cdt/SearchCdt";
import Oficina from "../components/cdt/Oficina";
import SaldosCdt from "../components/cdt/saldos/Cards";
import RankingAsesorCdt from "../components/cdt/ranking/RankingAsesorCdt";
import RankingOficinaCdt from "../components/cdt/ranking/RankingOficinaCdt";
import TablaCDT from "../components/cdt/tabla/TablaCdt";

const CDT = (props) => {
    const [oficina, setOficina] = useState("");
    const [cedula, setCedula] = useState("");
    const [mesCorte, setMesCorte] = useState("");

    return (
        <div
            style={{
                width: "85%",
                margin: "auto",
                marginTop: "30px",
                // background: "#fafafa"
            }}
        >
            {/* ---------------------- Barras Busqueda ---------------------- */}
            <Row
                gutter={{ xs: 8, sm: 16, md: 24 }}
                type="flex"
                justify="space-around"
            >
                <Col xs={24} lg={8}>
                    <Oficina
                        oficina={oficina}
                        setOficina={setOficina}
                        cedula={cedula}
                        setCedula={setCedula}
                        nombre={props.nombre}
                        cargo={props.cargo}
                        zona={props.zona}
                    />
                </Col>

                <Col xs={24} lg={8}>
                    <SearchCdt
                        oficina={oficina}
                        cedula={cedula}
                        setCedula={setCedula}
                        setOficina={setOficina}
                        nombre={props.nombre}
                        cargo={props.cargo}
                        zona={props.zona}
                    />
                </Col>

                <Col xs={24} lg={5}>
                    <CascaderCdt
                        setMesCorte={setMesCorte}
                        mesCorte={mesCorte}
                    />
                </Col>
            </Row>
            {/* ---------------------- ACTIVIDAD ---------------------- */}
            <Card
                title={
                    <div style={{ fontSize: "18px" }}>
                        <Icon type="funnel-plot" /> Actividad
                    </div>
                }
                className="card"
                style={{
                    width: "95%",
                    height: "auto",
                    margin: "auto",
                    marginBottom: "25px",
                }}
                extra={
                    <div align="right" fontSize="12px">
                        <Tooltip
                            className="GreyTooltip"
                            tooltip={<Icon type="info-circle" />}
                        >
                            <p align="left" style={{ marginBottom: "0em" }}>
                                - Monto en millones ($) y número (#). <br />
                            </p>
                        </Tooltip>
                    </div>
                }
            >
                <ActividadCdt
                    oficina={oficina}
                    cedula={cedula}
                    mesCorte={mesCorte}
                />
            </Card>
            {/* ---------------------- TABLA ---------------------- */}
            <Card
                title={
                    <div style={{ fontSize: "18px" }}>
                        <Icon type="dot-chart" /> Actividad
                    </div>
                }
                className="card"
                style={{
                    width: "95%",
                    height: "auto",
                    margin: "auto",
                    marginBottom: "25px",
                }}
                extra={
                    <div align="right" fontSize="12px">
                        <Tooltip
                            className="GreyTooltip"
                            tooltip={<Icon type="info-circle" />}
                        >
                            <p align="left" style={{ marginBottom: "0em" }}>
                                - Monto en millones ($) y número (#). <br />
                            </p>
                        </Tooltip>
                    </div>
                }
            >
                <TablaCDT
                    oficina={oficina}
                    cedula={cedula}
                    mesCorte={mesCorte}
                />
            </Card>
            {/* ---------------------- SALDOS ---------------------- */}
            <Card
                title={
                    <div style={{ fontSize: "18px" }}>
                        <Icon type="dot-chart" /> Saldos
                    </div>
                }
                className="card"
                style={{
                    width: "95%",
                    height: "auto",
                    margin: "auto",
                    marginBottom: "25px",
                }}
                extra={
                    <div align="right" fontSize="12px">
                        <Tooltip
                            className="GreyTooltip"
                            tooltip={<Icon type="info-circle" />}
                        >
                            <p align="left" style={{ marginBottom: "0em" }}>
                                - Monto en millones ($) y número (#). <br />
                            </p>
                        </Tooltip>
                    </div>
                }
            >
                <SaldosCdt
                    oficina={oficina}
                    cedula={cedula}
                    mesCorte={mesCorte}
                />
            </Card>
            {/* ---------------------- Ranking ---------------------- */}
            <Card
                title={
                    <div style={{ fontSize: "18px" }}>
                        <Icon type="dot-chart" /> Ranking Oficina
                    </div>
                }
                className="card"
                style={{
                    width: "95%",
                    height: "auto",
                    margin: "auto",
                    marginBottom: "25px",
                }}
                extra={
                    <div align="right" fontSize="12px">
                        <Tooltip
                            className="GreyTooltip"
                            tooltip={<Icon type="info-circle" />}
                        >
                            <p align="left" style={{ marginBottom: "0em" }}>
                                - Monto en millones ($) y número (#). <br />
                            </p>
                        </Tooltip>
                    </div>
                }
            >
                <RankingOficinaCdt
                    oficina={oficina}
                    cedula={cedula}
                    mesCorte={mesCorte}
                />
            </Card>
            <Card
                title={
                    <div style={{ fontSize: "18px" }}>
                        <Icon type="dot-chart" /> Ranking Asesor
                    </div>
                }
                className="card"
                style={{
                    width: "95%",
                    height: "auto",
                    margin: "auto",
                    marginBottom: "25px",
                }}
                extra={
                    <div align="right" fontSize="12px">
                        <Tooltip
                            className="GreyTooltip"
                            tooltip={<Icon type="info-circle" />}
                        >
                            <p align="left" style={{ marginBottom: "0em" }}>
                                - Monto en millones ($) y número (#). <br />
                            </p>
                        </Tooltip>
                    </div>
                }
            >
                <RankingAsesorCdt
                    oficina={oficina}
                    cedula={cedula}
                    mesCorte={mesCorte}
                />
            </Card>
        </div>
    );
};

export default CDT;
