import React from "react";
import Drag from "./Drag";

const DragAndDropElement = ({ element }) => {
    const onDragStart = (e) => {
        e.dataTransfer.setData("text/plain", e.target.id);
        e.dropEffect = "move";
    };
    return (
        <div
            key={element}
            id={element}
            className={"row"}
            draggable={true}
            onDragStart={onDragStart}
        >
            <Drag /> {element}
        </div>
    );
};

export default DragAndDropElement;
