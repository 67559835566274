import React from "react";
import { QueryRenderer } from "@cubejs-client/react";
import { Spin, Modal } from "antd";
import { AutoComplete } from "antd";
import cubejsApi from "../../cubejsApiBQ";

const searchBarRender = ({ resultSet, props }) => {
    var dataSource = [];
    var cedulas = [];
    var nombre = "";
    resultSet.loadResponse.data.map((result, index) => {
        cedulas[index] = result["Usuarios.idCedula"];
        return (dataSource[index] = result["Usuarios.nombre"]);
    });
    if (props.cargo) {
        nombre = props.nombre;
        const index = cedulas[dataSource.indexOf(nombre)];
        props.setCedula(index);
    }
    const _onSelect = (value) => {
        const index = cedulas[dataSource.indexOf(value)];
        props.setCedula(index);
    };
    return (
        <AutoComplete
            style={{
                width: "100%",
                marginBottom: "25px",
            }}
            disabled={props.cargo}
            allowClear
            size="large"
            dataSource={dataSource}
            placeholder="Asesor"
            defaultValue={nombre}
            filterOption={(inputValue, option) => {
                return (
                    option.props.children
                        .toUpperCase()
                        .indexOf(inputValue.toUpperCase()) !== -1
                );
            }}
            onSelect={(value) => {
                _onSelect(value);
            }}
            onSearch={() => props.setCedula("")}
        />
    );
};

const renderChart = (Component, props) => ({ resultSet, error }) =>
    (resultSet && <Component resultSet={resultSet} props={props} />) || (
        <Modal
            width="200px"
            centered
            closable={false}
            visible={true}
            footer={null}
        >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <h3>Cargando datos...</h3>
                <Spin />
            </div>
        </Modal>
    );

const SearchBarRender = (props) => (
    <QueryRenderer
        query={{
            renewQuery: true,
            measures: [],
            timeDimensions: [],
            dimensions: ["Usuarios.nombre", "Usuarios.idCedula"],
            segments: [],
            filters: [
                {
                    dimension: "Usuarios.zona",
                    operator: "contains",
                    values: props.zona !== "" ? [props.zona] : [],
                },
                {
                    dimension: "Usuarios.producto",
                    operator: "contains",
                    values: ["tucredito"],
                },
                {
                    dimension: "Usuarios.cargo",
                    operator: "notContains",
                    values: ["ZONA"],
                },
                {
                    dimension: "Usuarios.cargo",
                    operator: "notEquals",
                    values: ["SIN ASESOR"],
                },
                {
                    dimension: "Usuarios.cargo",
                    operator: "notEquals",
                    values: ["ASESOR RETIRADO"],
                },
            ],
        }}
        cubejsApi={cubejsApi}
        render={renderChart(searchBarRender, props)}
    />
);

export default SearchBarRender;
