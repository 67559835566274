import React from "react";
import { QueryRenderer } from "@cubejs-client/react";
import NumberFormat from "react-number-format";
import { Row, Col, Typography, Card } from "antd";
import { Spin } from "antd";
import cubejsApi from "../../../cubejsApiBQ";

import "../../../styles/boxRender.css";

const { Text } = Typography;
const PREFIX = ["#", "#", "$", "$", ""];
const SUFFIX = ["", "", "", "", "%"];
const NAME_SERIES = [
    "Cero Cuotas",
    "Mayores 8",
    "Meta Cartera",
    "Diferencia Meta Cartera",
    "Diferencia Meta Cartera",
];

const medidasTiempo = ({ resultSet, props }) => {
    return (
        <Row gutter={[0, 0]} type="flex" justify="space-around">
            {resultSet.seriesNames().map((s, index) => {
                return (
                    <Col key={index} xs={10} lg={4}>
                        <Card
                            size="small"
                            className="topCard"
                            style={{
                                height: "100px",
                            }}
                            bodyStyle={{ padding: "10px 10px 10px 10px" }}
                        >
                            <Row gutter={[10, 10]} type="flex" justify="center">
                                <Col
                                    span={24}
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                    }}
                                >
                                    <Text
                                        style={{
                                            fontSize: "14px",
                                            textAlign: "center",
                                        }}
                                    >
                                        {NAME_SERIES[index]}
                                    </Text>
                                </Col>
                                <Col
                                    span={20}
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        position: "absolute",
                                    }}
                                >
                                    <Text
                                        style={{
                                            fontSize: "16px",
                                            fontWeight: "bold",
                                            position: "relative",
                                            lineHeight: "120px",
                                        }}
                                    >
                                        <NumberFormat
                                            value={resultSet.totalRow()[s.key]}
                                            decimalScale={1}
                                            displayType={"text"}
                                            thousandSeparator={true}
                                            prefix={PREFIX[index]}
                                            suffix={SUFFIX[index]}
                                        />
                                    </Text>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                );
            })}
        </Row>
    );
};

const renderChart = (Component, props) => ({ resultSet, error }) =>
    (resultSet && <Component resultSet={resultSet} props={props} />) || (
        <Spin />
    );

const MedidasTiempo = (props) => {
    return (
        <QueryRenderer
            query={{
                measures: [
                    "ResumenMicrocredito.cantidadCeroCuotas",
                    "ResumenMicrocredito.cantidadMayores8",
                    "ResumenMicrocredito.valorPresupuestoCrecimiento",
                    "ResumenMicrocredito.valorDiferenciaCartera",
                    "ResumenMicrocredito.porcentajeDiferenciaMeta",
                ],
                timeDimensions: [],
                dimensions: [],
                segments: [],
                filters: [
                    {
                        dimension: "Usuarios.idCedula",
                        operator: "contains",
                        values: props.cedula !== "" ? [props.cedula] : [],
                    },
                    {
                        dimension: "ResumenMicrocredito.fechaCorte",
                        operator: "contains",
                        values: props.fecha !== "" ? [props.fecha] : [],
                    },
                    // {
                    //   dimension: "ResumenMicrocredito.plaza",
                    //   operator: "contains",
                    //   values: [props.plazas]
                    // }
                    {
                        dimension: "Usuarios.zona",
                        operator: "contains",
                        values: props.zona !== "" ? [props.zona] : [],
                    },
                ],
            }}
            cubejsApi={cubejsApi}
            render={renderChart(medidasTiempo, props)}
        />
    );
};

export default MedidasTiempo;
