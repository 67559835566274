import React, { useState } from "react";
import Tooltip from "react-png-tooltip";
import { Icon, Card, Row, Col } from "antd";
import EmbudoCredipoliza from "../components/crediPoliza/embudo/EmbudoCredipoliza";
import CascaderCredipoliza from "../components/crediPoliza/CascaderCredipoliza";
import SearchCredipoliza from "../components/crediPoliza/SearchCredipoliza";
import Aseguradoras from "../components/crediPoliza/Aseguradoras";
import RankingCredipoliza from "../components/crediPoliza/ranking/RankingCredipoliza";
import CarteraZonal from "../components/crediPoliza/cartera/RenderBoxCarteraCredipoliza";
import BarCarteraCredipoliza from "../components/crediPoliza/cartera/BarCarteraCredipoliza";
import ICV from "../components/crediPoliza/cartera/ICV";

const Credipoliza = props => {
  const [cedula, setCedula] = useState("");
  const [fecha, setFecha] = useState("");
  return (
    <div
      style={{
        width: "85%",
        margin: "auto",
        marginTop: "30px"
        // background: "#fafafa"
      }}
    >
      {/* ---------------------- Barras Busqueda ---------------------- */}
      <Row
        gutter={{ xs: 8, sm: 16, md: 24 }}
        type="flex"
        justify="space-around"
      >
        <Col xs={24} lg={8}>
          <Aseguradoras
            nombre={props.nombre}
            zona={props.zona}
            cargo={props.cargo}
          />
        </Col>
        <Col xs={24} lg={8}>
          <SearchCredipoliza
            setCedula={setCedula.bind(this)}
            cedula={cedula}
            cargo={props.cargo}
            nombre={props.nombre}
            zona={props.zona}
          />
        </Col>
        <Col xs={24} lg={5}>
          <CascaderCredipoliza setFecha={setFecha.bind(this)} fecha={fecha} />
        </Col>
      </Row>

      {/* ---------------------- EMBUDO ---------------------- */}

      <EmbudoCredipoliza fecha={fecha} cedula={cedula} zona={props.zona} />

      {/* ---------------------- Ranking ---------------------- */}

      <Card
        title={
          <div style={{ fontSize: "18px" }}>
            <Icon type="dot-chart" /> Ranking
          </div>
        }
        className="card"
        style={{
          width: "95%",
          height: "auto",
          margin: "auto",
          marginBottom: "25px"
        }}
        extra={
          <div align="right" fontSize="12px">
            <Tooltip
              className="GreyTooltip"
              tooltip={<Icon type="info-circle" />}
            >
              <p align="left" style={{ marginBottom: "0em" }}>
                - Monto en millones ($) y número (#). <br />
              </p>
            </Tooltip>
          </div>
        }
      >
        <RankingCredipoliza
          fecha={fecha}
          cedula={cedula}
          zona={props.zona}
          region={props.region}
        />
      </Card>

      {/* ---------------------- Cartera ---------------------- */}

      {props.tipoCargo.includes("ZONA") && (
        <Card
          title={
            <div style={{ fontSize: "18px" }}>
              <Icon type="wallet" /> Cartera Zonal
            </div>
          }
          extra={
            <div align="center">
              <strong>Fecha corte cartera: </strong>
              {props.penUltFecha}
            </div>
          }
          className="card-bg"
          style={{
            width: "95%",
            margin: "auto",
            marginBottom: "25px"
          }}
          headStyle={{ background: "White" }}
        >
          <Row
            gutter={[24, 24]}
            type="flex"
            justify="space-around"
            // style={{ marginTop: "20px" }}
          >
            <Col xs={24} lg={9}>
              <Card
                size="small"
                title="Indicadores Cartera"
                extra={
                  <div align="right" fontSize="12px">
                    <Tooltip
                      className="GreyTooltip"
                      tooltip={<Icon type="info-circle" />}
                    >
                      <p
                        align="left"
                        style={{
                          marginBottom: "0em"
                        }}
                      >
                        Cartera Zonal
                      </p>
                    </Tooltip>
                  </div>
                }
                headStyle={{ fontSize: "12px" }}
                style={{ marginBottom: "25px" }}
                className="card"
              >
                <CarteraZonal
                  cedula={cedula}
                  fecha={props.penUltFecha}
                  zona={props.zona}
                />
              </Card>
              <Card
                size="small"
                title="Indicadores Cartera Vencida"
                extra={
                  <div align="right" fontSize="12px">
                    <Tooltip
                      className="GreyTooltip"
                      tooltip={<Icon type="info-circle" />}
                    >
                      <p
                        align="left"
                        style={{
                          marginBottom: "0em"
                        }}
                      >
                        Cartera Zonal
                      </p>
                    </Tooltip>
                  </div>
                }
                headStyle={{ fontSize: "12px" }}
                bodyStyle={{
                  background: "#f2f2f2"
                }}
                className="card"
              >
                <ICV
                  cedula={cedula}
                  fecha={props.penUltFecha}
                  zona={props.zona}
                />
              </Card>
            </Col>
            <Col xs={24} lg={14}>
              <Card
                size="small"
                title="Composición Cartera por Tipo Convenio"
                extra={
                  <div align="right" fontSize="12px">
                    <Tooltip
                      className="GreyTooltip"
                      tooltip={<Icon type="info-circle" />}
                    >
                      <p align="left" style={{ marginBottom: "0em" }}>
                        Composición Cartera por Tipo Convenio
                      </p>
                    </Tooltip>
                  </div>
                }
                headStyle={{ fontSize: "12px" }}
                className="card"
              >
                <BarCarteraCredipoliza
                  cedula={cedula}
                  zona={props.zona}
                  fecha={props.penUltFecha}
                />
              </Card>
            </Col>
          </Row>
        </Card>
      )}
    </div>
  );
};

export default Credipoliza;
