import React, { Component } from "react";
import { Checkbox, Row, Col, Spin } from "antd";
import axios from "axios";

//const uid = firebase.auth().currentUser.uid;

class Check extends Component {
    constructor(props) {
        super(props);

        this.state = {
            tablas: [],
            loading: false,
        };
    }

    componentDidMount() {
        axios
            .get(
                `https://us-central1-dicocf.cloudfunctions.net/functions/services/csv/?uid=4IQYPhAGN2Xl64M4y3YJB6UoomC2`
            )
            .then((res) => {
                this.props.setTablas(res.data);
                console.log(res.data);
                this.setState({ tablas: Object.keys(res.data), loading: true });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    onChange(checked) {
        console.log(`Seleccionados =`, checked);
    }

    render() {
        const { tablas } = this.state;
        return (
            <Row type="flex" justify="center" style={{ marginTop: "60px" }}>
                {this.state.loading && (
                    <Col xs={24} style={{ width: "400px" }}>
                        <Checkbox.Group
                            options={tablas.filter((e) => e !== "not_detected")}
                            onChange={this.onChange}
                        />
                    </Col>
                )}
                {!this.state.loading && <Spin tip="Cargando..." size="large" />}
            </Row>
        );
    }
}

export default Check;
