import React from "react";
import { Card, Row, Col, Icon } from "antd";
import Tooltip from "react-png-tooltip";
import MedidasCredipoliza from "./MedidasCredipoliza";
import GaugeCredipoliza from "./GaugeCredipoliza";

const EmbudoCredipoliza = props => {
  return (
    <Row
      gutter={[0, 24]}
      type="flex"
      justify="space-between"
      style={{
        width: "95%",
        height: "auto",
        margin: "auto",
        marginBottom: "25px"
      }}
    >
      <Col xs={24} lg={24}>
        <Card
          title={
            <div style={{ fontSize: "18px" }}>
              <Icon type="funnel-plot" /> Embudo
            </div>
          }
          className="card"
          extra={
            <div align="right" fontSize="12px">
              <Tooltip
                className="GreyTooltip"
                tooltip={<Icon type="info-circle" />}
              >
                <p align="left" style={{ marginBottom: "0em" }}>
                  - Monto en millones ($) y número (#). <br />
                </p>
              </Tooltip>
            </div>
          }
        >
          <MedidasCredipoliza
            fecha={props.fecha}
            cedula={props.cedula}
            zona={props.zona}
          />
        </Card>
      </Col>
      <Col xs={24} lg={24}>
        <Card
          title={
            <div style={{ fontSize: "18px" }}>
              <Icon type="rocket" /> Cumplimiento
            </div>
          }
          className="card"
          extra={
            <div align="right" fontSize="12px">
              <Tooltip
                className="GreyTooltip"
                tooltip={<Icon type="info-circle" />}
              >
                <p align="left" style={{ marginBottom: "0em" }}>
                  - Monto en millones ($) y número (#). <br />
                </p>
              </Tooltip>
            </div>
          }
        >
          <GaugeCredipoliza
            fecha={props.fecha}
            cedula={props.cedula}
            zona={props.zona}
          />
        </Card>
      </Col>
    </Row>
  );
};

export default EmbudoCredipoliza;
