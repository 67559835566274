import React from "react";
import { QueryRenderer } from "@cubejs-client/react";
import { Spin } from "antd";
import cubejsApi from "../../../cubejsApiBQ";

const bubbleZonal = ({ resultSet, props }) => {
    if (resultSet.series()[0] === undefined) {
        props.setZonalX("0");
        props.setZonalY(0);
        props.setZonalZ(0);
    } else {
        resultSet.series()[0].series.map((r, index) => {
            props.setZonalX(r.value === null ? 0 : r.value.toFixed(3));
            props.setZonalY(
                resultSet.series()[1].series[index].value === null
                    ? 0
                    : resultSet.series()[1].series[index].value.toFixed(1)
            );
            return props.setZonalZ(
                resultSet.series()[2].series[index].value === null
                    ? 0
                    : resultSet.series()[2].series[index].value
            );
        });
    }

    return null;
};

const renderChart = (Component, props) => ({ resultSet, error }) =>
    (resultSet && <Component resultSet={resultSet} props={props} />) || (
        <Spin />
    );

const BubbleZonal = (props) => {
    return (
        <QueryRenderer
            query={{
                renewQuery: true,
                dimensions: [],
                timeDimensions: [],
                measures: [
                    "BaseResumenColocacion.promedioIndicadorEfectividad",
                    "BaseResumenColocacion.promedioRentabilidad",
                    "BaseResumenColocacion.promedioCumplimientoDeLaMeta",
                ],
                filters: [
                    {
                        dimension: "BaseResumenColocacion.fechaCorte",
                        operator: "contains",
                        values: props.fecha !== "" ? [props.fecha] : [],
                    },
                    {
                        dimension: "BaseResumenColocacion.zona",
                        operator: "contains",
                        values: props.zona !== "" ? [props.zona] : [],
                    },
                ],
            }}
            cubejsApi={cubejsApi}
            render={renderChart(bubbleZonal, props)}
        />
    );
};

export default BubbleZonal;
