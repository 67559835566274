import React, { useMemo, useCallback } from "react";
import { useDropzone } from "react-dropzone";

const baseStyle = {
    flex: "1",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "110px",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out",
    height: "100%",
};

const activeStyle = {
    borderColor: "#2196f3",
};

const acceptStyle = {
    borderColor: "#00e676",
};

const rejectStyle = {
    borderColor: "#ff1744",
};

function StyledDropzone(props) {
    const onDrop = useCallback(
        (acceptedFiles) => {
            acceptedFiles.forEach((file) => {
                const reader = new FileReader();

                reader.onabort = () => console.log("file reading was aborted");
                reader.onerror = () => console.log("file reading has failed");
                reader.onload = () => {
                    // Get the file as a Byte Array
                    const file = new Uint8Array(reader.result);
                    props.setFile(file);
                    props.setIsFile(true);
                };
                reader.readAsArrayBuffer(file);
            });
        },
        [props]
    );

    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject,
    } = useDropzone({
        accept: "application/vnd.ms-excel, text/plain, .csv",
        onDrop,
    });

    const style = useMemo(
        () => ({
            ...baseStyle,
            ...(isDragActive ? activeStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {}),
        }),
        [isDragActive, isDragReject, isDragAccept]
    );

    return (
        <div {...getRootProps({ style })}>
            <input {...getInputProps()} />
            <p>Arrastrar y soltar el documento en esta zona</p>
            <p>o click para seleccionar.</p>
        </div>
    );
}

export default StyledDropzone;
