import React from "react";
import { QueryRenderer } from "@cubejs-client/react";
import { Spin } from "antd";
import { AutoComplete } from "antd";
import cubejsApi from "../../cubejsApi";

const cascaderMicrocredito = ({ resultSet, props }) => {
  var dataSource = [];
  resultSet.loadResponse.data.map((result, index) => {
    return (dataSource[index] = result["ResumenMicrocredito.fechaCorte"].slice(
      0,
      -13
    ));
  });

  if (props.fecha === "") props.setFecha(dataSource[dataSource.length - 1]);

  const _onSelect = (value) => {
    props.setFecha(value);
  };
  return (
    <AutoComplete
      style={{
        width: "100%",
        marginBottom: "25px",
      }}
      size="large"
      dataSource={dataSource}
      defaultValue={dataSource[dataSource.length - 1]}
      onSelect={(value) => _onSelect(value)}
    />
  );
};

const renderChart = (Component, props) => ({ resultSet, error }) =>
  (resultSet && <Component resultSet={resultSet} props={props} />) || <Spin />;

const CascaderMicrocredito = (props) => (
  <QueryRenderer
    query={{
      dimensions: ["ResumenMicrocredito.fechaCorte"],
      timeDimensions: [],
      segments: [],
      measures: [],
      filters: [
        {
          dimension: "ResumenMicrocredito.tipoCifra",
          operator: "contains",
          values: ["solicitudes"],
        },
      ],
      order: {
        // "BaseResumenColocacion.fechaCorte": "asc"
      },
      renewQuery: true,
    }}
    cubejsApi={cubejsApi}
    render={renderChart(cascaderMicrocredito, props)}
  />
);

export default CascaderMicrocredito;
