import React, { useState } from "react";
import TableRender from "../components/libranza/indicadoresConvenios/TableRender";
import DoughnutRender from "../components/libranza/indicadoresConvenios/DoughnutRender";
import PieRender from "../components/libranza/colocacionCumplimiento/PieRender";
import BoxRender3 from "../components/libranza/colocacionCumplimiento/BoxRender3";
import BubbleRender from "../components/libranza/ranking/BubbleRender";
import LineRender from "../components/libranza/colocacionCumplimiento/LineRender";
import Carousel from "../components/libranza/embudo/Carousel";
import SearchBarRender from "../components/libranza/SearchBarRender";
import CascaderBarRender from "../components/libranza/CascaderBarRender";
import CarteraZonal from "../components/libranza/carteraZonal/RenderBoxCartera";
import TablaZonal from "../components/libranza/carteraZonal/TableCartera";
import PieCartera from "../components/libranza/carteraZonal/PieCartera";
import Tooltip from "react-png-tooltip";
import { Card, Row, Col } from "antd";
import { Icon } from "antd";
import "../styles/staticPage.css";
import "../styles/boxRender.css";
import FechaActualizacion from "../components/FechaActualizacion";

const StaticPage = (props) => {
    const [fecha, setFecha] = useState("");
    const [cedula, setCedula] = useState("");
    return (
        <div
            style={{
                width: "85%",
                margin: "auto",
                marginTop: "30px",
                // background: "#fafafa"
            }}
        >
            {/* ---------------------- Barras Busqueda ---------------------- */}

            <Row
                gutter={{ xs: 8, sm: 16, md: 24 }}
                type="flex"
                justify="space-around"
            >
                <Col xs={15} lg={10}>
                    <SearchBarRender
                        setCedula={setCedula.bind(this)}
                        cedula={cedula}
                        cargo={props.cargo}
                        nombre={props.nombre}
                        zona={props.zona}
                    />
                </Col>
                <Col xs={9} lg={10}>
                    <CascaderBarRender
                        setFecha={setFecha.bind(this)}
                        fecha={fecha}
                    />
                </Col>
            </Row>

            {/* ---------------------- EMBUDO ---------------------- */}
            <Card
                title={
                    <div style={{ fontSize: "18px" }}>
                        <Icon type="funnel-plot" /> Embudo
                    </div>
                }
                className="card"
                style={{
                    width: "95%",
                    height: "auto",
                    margin: "auto",
                    marginBottom: "25px",
                    position: "relative",
                }}
                extra={
                    <div align="right" fontSize="12px">
                        <Tooltip
                            className="GreyTooltip"
                            tooltip={<Icon type="info-circle" />}
                        >
                            <p align="left" style={{ marginBottom: "0em" }}>
                                - Monto en millones ($) y número (#) de créditos
                                según estado. <br />
                                - Variación con respecto al día hábil del mes
                                anterior <br />
                                (Radicaciones y Desembolsos).
                            </p>
                        </Tooltip>
                    </div>
                }
            >
                <Carousel cedula={cedula} fecha={fecha} zona={props.zona} />
            </Card>
            {/* ---------------------- Grafico lineas y pie ---------------------- */}

            <Card
                title={
                    <div style={{ fontSize: "18px" }}>
                        <Icon type="fund" /> Colocación y Cumplimiento
                    </div>
                }
                className="card-bg"
                style={{
                    width: "95%",
                    margin: "auto",
                    marginBottom: "25px",
                }}
                headStyle={{ background: "white" }}
            >
                <Row gutter={[0, 48]} type="flex" justify="center">
                    <Col xs={24} lg={24} xl={24}>
                        <Card
                            size="small"
                            title="Proyección Mes Actual"
                            extra={
                                <div align="right" fontSize="12px">
                                    <Tooltip
                                        className="GreyTooltip"
                                        tooltip={<Icon type="info-circle" />}
                                    >
                                        <p
                                            align="left"
                                            style={{ marginBottom: "0em" }}
                                        >
                                            Proyección Mes Actual:
                                            <br />
                                            Presupuesto diario del mes y
                                            colocación en monto (millones) al
                                            corte del mes.
                                        </p>
                                    </Tooltip>
                                </div>
                            }
                            headStyle={{ fontSize: "12px" }}
                            className="card"
                        >
                            <div className="chart-container" id="turn">
                                <LineRender
                                    cedula={cedula}
                                    zona={props.zona}
                                    fecha={fecha}
                                />
                            </div>
                            <div className="chart-container" id="container-tom">
                                <center>
                                    <Icon type="sync" />
                                </center>
                                <LineRender
                                    cedula={cedula}
                                    zona={props.zona}
                                    fecha={fecha}
                                />
                            </div>
                        </Card>
                    </Col>
                    <Col xs={24} lg={24} xl={24}>
                        <Card
                            size="small"
                            title="Indicadores Colocacion Mes Actual"
                            className="card"
                            headStyle={{ fontSize: "12px" }}
                            bodyStyle={{}}
                        >
                            <Row
                                gutter={[0, 48]}
                                type="flex"
                                justify="space-between"
                                align="top"
                            >
                                <Col xs={24} lg={9} xl={9}>
                                    <Card
                                        size="small"
                                        title="Destino colocaciones mes"
                                        extra={
                                            <div align="right" fontSize="12px">
                                                <Tooltip
                                                    className="GreyTooltip"
                                                    tooltip={
                                                        <Icon type="info-circle" />
                                                    }
                                                >
                                                    <p
                                                        align="left"
                                                        style={{
                                                            marginBottom: "0em",
                                                        }}
                                                    >
                                                        Destino colocación mes:{" "}
                                                        <br />
                                                        - Participación de las
                                                        colocaciones del mes por
                                                        tipo de destino. <br />-
                                                        Tipo de destino: <br />
                                                        -Refinanciación. <br />
                                                        - Nuevo. <br />- Compra:
                                                        Compra de cartera a
                                                        terceros.
                                                    </p>
                                                </Tooltip>
                                            </div>
                                        }
                                        headStyle={{ fontSize: "12px" }}
                                        className="card"
                                    >
                                        <PieRender
                                            cedula={cedula}
                                            zona={props.zona}
                                            fecha={fecha}
                                        />
                                    </Card>
                                </Col>
                                <Col xs={24} lg={14} xl={14}>
                                    <Card
                                        size="small"
                                        title="Principales Indicadores"
                                        headStyle={{ fontSize: "12px" }}
                                        className="card"
                                    >
                                        <BoxRender3
                                            cedula={cedula}
                                            fecha={fecha}
                                            zona={props.zona}
                                        />
                                    </Card>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </Card>
            {/* ---------------------- Grafico Dona y Tablas ---------------------- */}
            <Card
                title={
                    <div style={{ fontSize: "18px" }}>
                        <Icon type="bank" /> Indicadores Convenios
                    </div>
                }
                className="card-bg"
                style={{
                    width: "95%",
                    margin: "auto",
                    marginBottom: "25px",
                }}
                headStyle={{ background: "White" }}
            >
                <Row gutter={[0, 48]} type="flex" justify="space-around">
                    <Col xs={24} lg={8} xl={9}>
                        <Card
                            size="small"
                            title="Tipo de convenio"
                            extra={
                                <div align="right" fontSize="12px">
                                    <Tooltip
                                        className="GreyTooltip"
                                        tooltip={<Icon type="info-circle" />}
                                    >
                                        <p
                                            align="left"
                                            style={{ marginBottom: "0em" }}
                                        >
                                            Tipo de Convenio: Participación de
                                            colocaciones mes por tipo de
                                            convenio.
                                        </p>
                                    </Tooltip>
                                </div>
                            }
                            headStyle={{ fontSize: "12px" }}
                            className="card"
                        >
                            <DoughnutRender
                                cedula={cedula}
                                fecha={fecha}
                                zona={props.zona}
                            />
                        </Card>
                    </Col>
                    <Col xs={24} lg={12}>
                        <Card
                            size="small"
                            extra={
                                <div align="right" fontSize="12px">
                                    <Tooltip
                                        className="GreyTooltip"
                                        tooltip={<Icon type="info-circle" />}
                                    >
                                        <p
                                            align="left"
                                            style={{ marginBottom: "0em" }}
                                        >
                                            Nivel de Actividad de los convenios:{" "}
                                            <br />
                                            - Colocación por convenio mes
                                            actual. <br />
                                            - Variación colocación mes actual
                                            respecto al día hábil del mes
                                            anterior.
                                            <br />
                                            - Colocación promedio por convenio
                                            en los últimos tres meses.
                                            <br />
                                            - Variación colocación mes actual
                                            con respecto al promedio de los
                                            últimos tres meses.
                                            <br />
                                        </p>
                                    </Tooltip>
                                </div>
                            }
                            title="Nivel de Actividad"
                            headStyle={{ fontSize: "12px" }}
                            className="card"
                        >
                            <TableRender
                                cedula={cedula}
                                fecha={fecha}
                                zona={props.zona}
                            />
                        </Card>
                    </Col>
                </Row>
            </Card>
            {/* ---------------------- Grafico burbujas ---------------------- */}
            <Card
                title={
                    <div style={{ fontSize: "18px" }}>
                        <Icon type="bar-chart" /> Ranking
                    </div>
                }
                className="card"
                style={{
                    width: "95%",
                    margin: "auto",
                    marginBottom: "25px",
                }}
                extra={
                    <div align="right" fontSize="12px">
                        <Tooltip
                            className="GreyTooltip"
                            tooltip={<Icon type="info-circle" />}
                        >
                            <p align="left" style={{ marginBottom: "0em" }}>
                                Ranking: <br />
                                - Eje Indicador Efectividad: Participación de
                                colocaciones en el mes sobre radicaciones del
                                mes. <br />
                                - Eje Rentabilidad: Ingresos generados por
                                conceptos de intereses y aval a la compañía en
                                el mes (millones).
                                <br />
                                - Tamaño de la burbuja: Cumplimiento con
                                respecto a la meta del mes.
                                <br />
                            </p>
                        </Tooltip>
                    </div>
                }
            >
                <Row
                    gutter={[0, 48]}
                    type="flex"
                    justify="space-around"
                    style={{ marginTop: "20px" }}
                >
                    <BubbleRender
                        cedula={cedula}
                        fecha={fecha}
                        zona={props.zona}
                        region={props.region}
                    />
                </Row>
            </Card>

            {/*------------------------Cartera Zonal------------------------------*/}
            {!props.cargo && (
                <Card
                    title={
                        <div style={{ fontSize: "18px" }}>
                            <Icon type="wallet" /> Cartera Zonal
                        </div>
                    }
                    extra={
                        <div align="center">
                            <strong>Fecha corte cartera: </strong>
                            {props.penUltFecha}
                        </div>
                    }
                    className="card-bg"
                    style={{
                        width: "95%",
                        margin: "auto",
                        marginBottom: "25px",
                    }}
                    headStyle={{ background: "White" }}
                >
                    <Row
                        gutter={[24, 24]}
                        type="flex"
                        justify="space-around"
                        // style={{ marginTop: "10px" }}
                    >
                        <Col xs={24} lg={10}>
                            <Card
                                size="small"
                                title="Composición Cartera por Tipo Convenio"
                                extra={
                                    <div align="right" fontSize="12px">
                                        <Tooltip
                                            className="GreyTooltip"
                                            tooltip={
                                                <Icon type="info-circle" />
                                            }
                                        >
                                            <p
                                                align="left"
                                                style={{ marginBottom: "0em" }}
                                            >
                                                Composición Cartera por Tipo
                                                Convenio
                                            </p>
                                        </Tooltip>
                                    </div>
                                }
                                headStyle={{ fontSize: "12px" }}
                                className="card"
                            >
                                <PieCartera
                                    cedula={cedula}
                                    zona={props.zona}
                                    fecha={props.penUltFecha}
                                />
                            </Card>
                            <Row
                                gutter={[24, 24]}
                                type="flex"
                                justify="space-around"
                                style={{ marginTop: "20px" }}
                            >
                                <Card
                                    size="small"
                                    title="Indicadores Cartera"
                                    extra={
                                        <div align="right" fontSize="12px">
                                            <Tooltip
                                                className="GreyTooltip"
                                                tooltip={
                                                    <Icon type="info-circle" />
                                                }
                                            >
                                                <p
                                                    align="left"
                                                    style={{
                                                        marginBottom: "0em",
                                                    }}
                                                >
                                                    Cartera Zonal
                                                </p>
                                            </Tooltip>
                                        </div>
                                    }
                                    headStyle={{ fontSize: "12px" }}
                                    className="card"
                                >
                                    <CarteraZonal
                                        cedula={cedula}
                                        fecha={props.penUltFecha}
                                        zona={props.zona}
                                    />
                                </Card>
                            </Row>
                        </Col>
                        <Col xs={24} lg={14}>
                            <Card
                                size="small"
                                title="Cartera por Convenio"
                                extra={
                                    <div align="right" fontSize="12px">
                                        <Tooltip
                                            className="GreyTooltip"
                                            tooltip={
                                                <Icon type="info-circle" />
                                            }
                                        >
                                            <p
                                                align="left"
                                                style={{ marginBottom: "0em" }}
                                            >
                                                Tabla Cartera Zonal
                                            </p>
                                        </Tooltip>
                                    </div>
                                }
                                headStyle={{ fontSize: "12px" }}
                                className="card"
                            >
                                <TablaZonal
                                    cedula={cedula}
                                    fecha={props.penUltFecha}
                                    zona={props.zona}
                                />
                            </Card>
                        </Col>
                    </Row>
                </Card>
            )}

            {/* Fecha de actualizacion */}

            {/* <Row
                gutter={[0, 0]}
                type="flex"
                justify="end"
                style={{
                    width: "95%",
                    margin: "auto",
                    marginTop: "-10px",
                }}
            >
                <FechaActualizacion tipoProceso="Sol. Libranzas" />
            </Row> */}
        </div>
    );
};

export default StaticPage;
