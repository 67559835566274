import React from "react";
import { QueryRenderer } from "@cubejs-client/react";
import { Spin } from "antd";
import cubejsApi from "../../../cubejsApiBQ";

const rankingOficinaNacional = ({ resultSet, props }) => {
    if (resultSet.series()[0] === undefined) {
        props.setNacionalX("0");
        props.setNacionalY(0);
        props.setNacionalZ(0);
    } else {
        resultSet.series()[0].series.map((r, index) => {
            props.setNacionalX(r.value === null ? 0 : r.value.toFixed(1));
            props.setNacionalY(
                resultSet.series()[1].series[index].value === null
                    ? 0
                    : resultSet.series()[1].series[index].value.toFixed(1)
            );
            return props.setNacionalZ(
                resultSet.series()[2].series[index].value === null
                    ? 0
                    : resultSet.series()[2].series[index].value
            );
        });
    }

    return null;
};

const renderChart = (Component, props) => ({ resultSet, error }) =>
    (resultSet && <Component resultSet={resultSet} props={props} />) || (
        <Spin />
    );

const RankingOficinaNacional = (props) => {
    return (
        <QueryRenderer
            query={{
                renewQuery: true,
                dimensions: [],
                timeDimensions: [],
                measures: [
                    "ResumenActividadCdt.porcentajeRenovacionPromedio",
                    "ResumenSaldosCdt.factorTasaPromedio",
                    "ResumenSaldosCdt.saldoTotalPromedio",
                ],
                filters: [
                    // {
                    //     dimension: "ResumenCp.fechaCorte",
                    //     operator: "contains",
                    //     values: [props.fecha]
                    // }
                ],
            }}
            cubejsApi={cubejsApi}
            render={renderChart(rankingOficinaNacional, props)}
        />
    );
};

export default RankingOficinaNacional;
