import React from "react";
import { QueryRenderer } from "@cubejs-client/react";
import { Spin, Row, Col, Card, Typography } from "antd";
import NumberFormat from "react-number-format";
import cubejsApi from "../../../cubejsApiBQ";

import "../../../styles/boxRender.css";
import Cantidades from "./Cantidades";
import Porcentajes from "./Porcentajes";

const { Title, Text } = Typography;

const COLORS_SERIES = [
    "#b1abe9",
    "#b1abe9",
    "#8dc1ca",
    "#b1abe9",
    "#b1abe9",
    "#8dc1ca",
    "#5cc8d6",
    "#80c6b8",
    "#98c3a6",
    "#96c593",
];
const PREFIX = ["$", "$", "$", "#"];
const text = ["", "INTERNO", "EXTERNO"];
var t;
const medidas = ({ resultSet, props, mesCorte, porcentajes, cantidades }) => {
    const NAME_SERIES = [
        "Saldo Total",
        "Saldo Interno",
        "Saldo Canal Externo",
        "Plazo",
        "Tasa",
    ];

    return (
        <Row gutter={[0, 20]} type="flex" justify="space-around">
            {resultSet.seriesNames().map((s, index) => {
                return (
                    <Col key={index} xs={24} lg={7}>
                        <Card
                            size="small"
                            className="topCard"
                            style={{
                                borderTop: "3px solid " + COLORS_SERIES[index],
                            }}
                            bodyStyle={{ padding: "5px 10px 0px 10px" }}
                        >
                            {index !== 3 && (
                                <Row>
                                    <Text className="cardTitle">
                                        {NAME_SERIES[index]}
                                    </Text>
                                </Row>
                            )}
                            {index === 3 && (
                                <Row style={{ marginBottom: "5px" }}>
                                    <Col xs={10} lg={10}>
                                        <Text className="cardTitle">
                                            {NAME_SERIES[index]}
                                        </Text>
                                    </Col>
                                    <Col xs={10} lg={9}></Col>
                                    <Col xs={4} lg={5}>
                                        <Text className="cardTitle">
                                            {NAME_SERIES[index + 1]}
                                        </Text>
                                    </Col>
                                </Row>
                            )}

                            <Row
                                gutter={0}
                                type="flex"
                                justify="space-between"
                                align="middle"
                            >
                                <Col xs={10}>
                                    <Title
                                        style={{
                                            fontSize: "16px",
                                        }}
                                    >
                                        <NumberFormat
                                            value={resultSet.totalRow()[s.key]}
                                            decimalScale={1}
                                            displayType={"text"}
                                            thousandSeparator={true}
                                            prefix={PREFIX[index]}
                                        />
                                    </Title>
                                </Col>
                                <Col xs={3}>
                                    <Cantidades
                                        {...props}
                                        index={index}
                                        cantidades={cantidades}
                                        mesCorte={mesCorte}
                                    />
                                </Col>
                                <Col xs={6}>
                                    <Porcentajes
                                        {...props}
                                        index={index}
                                        porcentajes={porcentajes}
                                        mesCorte={mesCorte}
                                    />
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                );
            })}
        </Row>
    );
};

const renderChart = (Component, props, mesCorte, porcentajes, cantidades) => ({
    resultSet,
    error,
}) =>
    (resultSet && (
        <Component
            resultSet={resultSet}
            props={props}
            mesCorte={mesCorte}
            porcentajes={porcentajes}
            cantidades={cantidades}
        />
    )) || <Spin />;

const Medidas = (props) => {
    const measures = [
        "ResumenSaldosCdt.saldoTotal",
        "ResumenSaldosCdt.saldoTotalInterno",
        "ResumenSaldosCdt.saldoTotalExterno",
        "ResumenSaldosCdt.cantidadPlazo",
    ];
    const mesCorte = "ResumenSaldosCdt.fechaCorte";
    const cantidades = [
        "ResumenSaldosCdt.saldoDiferencia",
        "ResumenSaldosCdt.saldoDiferenciaInterno",
        "ResumenSaldosCdt.saldoDiferenciaExterno",
        "",
    ];
    const porcentajes = [
        "ResumenSaldosCdt.porcentajeComparacionSaldoTotal",
        "",
        "",
        "ResumenSaldosCdt.porcentajeTasa",
    ];

    return (
        <QueryRenderer
            query={{
                renewQuery: true,
                measures: measures,
                timeDimensions: [],
                filters: [
                    {
                        dimension: "ResumenActividadCdt.mesCorte",
                        operator: "contains",
                        values: props.mesCorte !== "" ? [props.mesCorte] : [],
                    },
                    {
                        dimension: "Usuarios.idCedula",
                        operator: "contains",
                        values: props.cedula !== "" ? [props.cedula] : [],
                    },
                    {
                        dimension: "ResumenActividadCdt.oficina",
                        operator: "contains",
                        values: props.oficina !== "" ? [props.oficina] : [],
                    },
                ],
                dimensions: [],
            }}
            cubejsApi={cubejsApi}
            render={renderChart(
                medidas,
                props,
                mesCorte,
                porcentajes,
                cantidades
            )}
        />
    );
};

export default Medidas;
