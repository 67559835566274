import cubejs from "@cubejs-client/core";
const jwt = require("jsonwebtoken");

const API_URL = "https://us-central1-dicocf.cloudfunctions.net/cube-dev-cubejs";

// const API_URL = "http://localhost:4000"; //Local

const CUBE_API_SECRET =
    "960c1908536abd8a863e981db148d9f248add26d9b96b78e6952ef0cf2c9d395b0c7f33a79ac45c07d22e3fb312353de3b6fc107d628e35ee6137a18fcfaf48c";
const CUBEJS_TOKEN = jwt.sign({}, CUBE_API_SECRET, { expiresIn: "1d" });
const cubejsApi = cubejs(CUBEJS_TOKEN, {
    apiUrl: `${API_URL}/cubejs-api/v1`,
});

export default cubejsApi;
