import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/analytics";
import "firebase/storage";

// Initialize Firebase
var firebaseConfig = {
  apiKey: "AIzaSyD6LNd1nbMkng_mJqSTpxjTuQ7t9rmBl2I",
  authDomain: "dicocf.firebaseapp.com",
  databaseURL: "https://dicocf.firebaseio.com",
  projectId: "dicocf",
  storageBucket: "dicocf.appspot.com",
  messagingSenderId: "560082566423",
  appId: "1:560082566423:web:a0d977e5511373dcdc4b52",
  measurementId: "G-8HQHHG5HEM"
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();
firebase.storage();

export default firebase;
