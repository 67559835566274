import React from "react";
import { QueryRenderer } from "@cubejs-client/react";
import { Spin } from "antd";
import { AutoComplete } from "antd";
import cubejsApi from "../../cubejsApiBQ";

const supervisorBar = ({ resultSet, props }) => {
    var dataSource = [];
    var supervisor = "";
    resultSet.loadResponse.data.map((result, index) => {
        return (dataSource[index] = result["RESUMENTigo.supervisor"]);
    });
    if (props.tipoCargo.includes("SUPERVISOR TIGO")) {
        supervisor = dataSource[dataSource.indexOf(props.nombre)];
        props.setSupervisor(supervisor);
    }
    return (
        <AutoComplete
            style={{
                width: "100%",
                marginBottom: "25px",
            }}
            disabled={!props.tipoCargo.includes("JEFE TIGO")}
            allowClear
            size="large"
            dataSource={dataSource}
            placeholder="Supervisor"
            defaultValue={supervisor}
            filterOption={(inputValue, option) => {
                return (
                    option.props.children
                        .toUpperCase()
                        .indexOf(inputValue.toUpperCase()) !== -1
                );
            }}
            onSelect={(value) => props.setSupervisor(value)}
            onSearch={() => {
                props.setSupervisor("");
                props.setPunto("");
            }}
        />
    );
};

const renderChart = (Component, props) => ({ resultSet, error }) =>
    (resultSet && <Component resultSet={resultSet} props={props} />) || (
        <Spin />
    );

const SupervisorBar = (props) => (
    <QueryRenderer
        query={{
            renewQuery: true,
            measures: [],
            timeDimensions: [],
            dimensions: ["RESUMENTigo.supervisor"],
            segments: [],
            filters: [
                {
                    dimension: "RESUMENTigo.region",
                    operator: "contains",
                    values: props.region !== "" ? [props.region] : [],
                },
            ],
        }}
        cubejsApi={cubejsApi}
        render={renderChart(supervisorBar, props)}
    />
);

export default SupervisorBar;
