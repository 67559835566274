import React from "react";
import BoxRender from "./BoxRender";
import BoxRender2 from "./BoxRender2";
import { Carousel, Icon } from "antd";
import "../../../styles/carousel.css";

export default class CarouselComponent extends React.Component {
    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.carousel = React.createRef();
    }
    next() {
        this.carousel.next();
    }
    previous() {
        this.carousel.prev();
    }

    render() {
        const arrowSize = "30px";
        const props = {
            dots: false,
            infinite: true,
            // autoplaySpeed: 10000,
            speed: 1000,
            slidesToShow: 1,
            slidesToScroll: 1,
        };
        return (
            <div>
                <Icon
                    className="carr"
                    type="arrow-left"
                    onClick={this.previous}
                    style={{
                        fontSize: arrowSize,
                        position: "absolute",
                        top: "110px",
                        left: "2%",
                    }}
                />
                <Carousel
                    // autoplay
                    ref={(node) => (this.carousel = node)}
                    {...props}
                    arrows="true"
                    dots="false"
                    style={{ width: "90%", margin: "auto" }}
                >
                    <div>
                        <BoxRender
                            cedula={this.props.cedula}
                            fecha={this.props.fecha}
                            zona={this.props.zona}
                        />
                    </div>
                    <div>
                        <BoxRender2
                            cedula={this.props.cedula}
                            fecha={this.props.fecha}
                            zona={this.props.zona}
                        />
                    </div>
                </Carousel>
                <Icon
                    className="carr"
                    type="arrow-right"
                    onClick={this.next}
                    style={{
                        fontSize: arrowSize,
                        position: "absolute",
                        top: "110px",
                        right: "3%",
                    }}
                />
            </div>
        );
    }
}
