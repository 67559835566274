import React from "react";
import { QueryRenderer } from "@cubejs-client/react";
import NumberFormat from "react-number-format";
import { Row, Col, Typography, Card } from "antd";
import { Spin } from "antd";
import CantCartera from "./CantCartera";
import cubejsApi from "../../../cubejsApiBQ";

import "../../../styles/boxRender.css";

const { Text } = Typography;

const COLORS_SERIES = ["#3d8aa9", "#ab7a9e", "#4fafaa", "#64abd8"];
const NAME_SERIES = [
    "Cartera al Corte:",
    "Vr Mes Anterior:",
    "Vr Año Anterior:",
];

const renderBoxCartera = ({ resultSet, props }) => {
    return (
        <Row gutter={[0, 0]} type="flex" justify="space-between">
            {resultSet.seriesNames().map((s, index) => {
                return (
                    <Col key={index} xs={24} lg={24}>
                        <Card
                            size="small"
                            className="topCard"
                            style={{
                                borderTop: "3px solid " + COLORS_SERIES[index],
                            }}
                            bodyStyle={{ padding: "10px 10px 10px 10px" }}
                        >
                            <Row
                                gutter={0}
                                type="flex"
                                justify="space-between"
                                align="middle"
                            >
                                <Col>
                                    <Text style={{ fontSize: "16px" }}>
                                        {NAME_SERIES[index]}
                                    </Text>
                                </Col>

                                {index === 0 && (
                                    <Col xs={4}>
                                        <CantCartera {...props} index={index} />
                                    </Col>
                                )}

                                <Col xs={6}>
                                    <Text
                                        style={{
                                            fontSize: "16px",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        {index !== 0 && (
                                            <NumberFormat
                                                value={
                                                    resultSet.totalRow()[s.key]
                                                }
                                                decimalScale={1}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                suffix={"%"}
                                            />
                                        )}
                                        {index === 0 && (
                                            <Col xs={6}>
                                                <NumberFormat
                                                    value={
                                                        resultSet.totalRow()[
                                                            s.key
                                                        ]
                                                    }
                                                    decimalScale={1}
                                                    displayType={"text"}
                                                    thousandSeparator={true}
                                                    prefix={"$"}
                                                />
                                            </Col>
                                        )}
                                    </Text>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                );
            })}
        </Row>
    );
};

const renderChart = (Component, props) => ({ resultSet, error }) =>
    (resultSet && <Component resultSet={resultSet} props={props} />) || (
        <Spin />
    );

const RenderBoxCartera = (props) => {
    return (
        <QueryRenderer
            query={{
                measures: [
                    "ResumenCarteraHistorico.montoCartera",
                    "ResumenCarteraHistorico.variacionMes",
                    "ResumenCarteraHistorico.variacionAnho",
                ],
                timeDimensions: [],
                dimensions: [],
                segments: [],
                filters: [
                    {
                        dimension: "ResumenCarteraHistorico.fechaCorte",
                        operator: "contains",
                        values: props.fecha !== "" ? [props.fecha] : [],
                    },
                    {
                        dimension: "ResumenCarteraHistorico.zona",
                        operator: "contains",
                        values: props.zona !== "" ? [props.zona] : [],
                    },
                    {
                        dimension: "ResumenCarteraHistorico.tipoProducto",
                        operator: "contains",
                        values: ["libranzas"],
                    },
                ],
            }}
            cubejsApi={cubejsApi}
            render={renderChart(renderBoxCartera, props)}
        />
    );
};

export default RenderBoxCartera;
