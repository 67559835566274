import React, { useState, useEffect } from "react";
import { QueryRenderer } from "@cubejs-client/react";
import { Spin } from "antd";
import Chart from "react-apexcharts";
import cubejsApi from "../../../cubejsApiBQ";

const rankingAsesorCdt = ({ resultSet, zonal, regional, nacional }) => {
    const options = {
        plotOptions: {
            bubble: {
                minBubbleRadius: 10,
                maxBubbleRadius: 30,
            },
        },
        annotations: {
            position: "back",
            yaxis: [
                {
                    y: 5.0,
                    borderColor: "#c2c2c2",
                    label: {
                        borderColor: "transparent",
                        style: {
                            fontSize: "14px",
                            color: "#666",
                            background: "transparent",
                        },
                        text: "5.0",
                    },
                },
            ],
            xaxis: [
                {
                    x: 50,
                },
            ],
        },
        grid: {
            show: true,
            xaxis: {
                lines: {
                    show: false,
                },
            },
            yaxis: {
                lines: {
                    show: false,
                },
            },
        },
        chart: {
            toolbar: {
                show: false,
            },
            zoom: {
                enabled: false,
                autoScaleYaxis: true,
                type: "xy",
            },
            background: "#fafafa",
        },
        colors: [
            "#E91E63",
            "#ec7ec5",
            "#2E93fA",
            "#66DA26",
            "#2E93fA",
            "#66DA26",
            "#546E7A",
            "#546E7A",
            "#E91E63",
            "#FF9800",
            "#486ac3",
            "#908245",
            "#3f7f7c",
            "#1acfed",
            "#170b2e",
            "#80a9b7",
            "#ab8a9f",
            "#1c8f57",
            "#000be8",
            "#6fefa9",
            "#86b275",
            "#8e8b6f",
        ],
        dataLabels: {
            enabled: false,
        },
        fill: {
            opacity: 0.8,
        },
        title: {
            // text: "Simple Bubble Chart"
        },
        xaxis: {
            title: {
                text: "Renovación",
                offsetX: 0,
                offsetY: 0,
                style: {
                    color: undefined,
                    fontSize: "15px",
                    fontFamily: "Helvetica, Arial, sans-serif",
                    cssClass: "apexcharts-xaxis-title",
                },
            },
            tickAmount: 10,
            min: 0,
            max: 100,
        },
        yaxis: {
            title: {
                text: "Rendimiento",
                type: "category",
                offsetX: 0,
                offsetY: 0,
                style: {
                    color: undefined,
                    fontSize: "15px",
                    fontFamily: "Helvetica, Arial, sans-serif",
                    cssClass: "apexcharts-xaxis-title",
                },
            },
            min: 0,
            // max: 15,
            forceNiceScale: true,
        },
        responsive: [
            {
                breakpoint: 1000,
                options: {
                    xaxis: {
                        labels: {
                            show: true,
                            trim: true,
                        },
                        tickAmount: 10,
                        min: 0,
                        max: 100,
                    },
                    yaxis: {
                        min: 0,
                        // max: 15,
                        forceNiceScale: true,
                    },
                    legend: {
                        show: true,
                    },
                    chart: {
                        width: "100%",
                        zoom: {
                            enabled: false,
                            autoScaleYaxis: true,
                            type: "xy",
                        },
                    },
                },
            },
        ],
        tooltip: {
            x: {
                show: false,
                formatter: function (val) {
                    return "Renovacion: " + val + "%";
                },
            },
            y: {
                formatter: function (val) {
                    return val.toFixed(1) + "%";
                },
                title: {
                    formatter: function (seriesName) {
                        return seriesName + "<br>" + "Variacion";
                    },
                },
            },
            z: {
                formatter: function (val) {
                    return;
                },
                title: " ",
            },
        },
    };

    var series = [];
    if (resultSet.series()[0] === undefined) {
        series = [{ name: "No hay datos", data: [["0", 0, 0]] }];
    } else {
        series = resultSet.series()[0].series.map((r, index) => {
            var original = r.category;
            // var result = original.substr(original.indexOf(" ") + 1);
            // var resultor = result.substr(result.indexOf(" ") + 1);
            return {
                name: r.category,
                data: [
                    [
                        r.value === null ? 0 : r.value.toFixed(1),
                        resultSet.series()[1].series[index].value === null
                            ? 0
                            : resultSet
                                  .series()[1]
                                  .series[index].value.toFixed(1),
                        resultSet.series()[2].series[index].value === null
                            ? 0
                            : resultSet.series()[2].series[index].value,
                    ],
                ],
            };
        });
    }

    const seriesTotal = series;
    // .concat(zonal)
    // .concat(regional)
    // .concat(nacional);

    return (
        <div style={{ width: "100%" }}>
            <Chart
                options={options}
                series={seriesTotal}
                type="bubble"
                height="400px"
            />
        </div>
    );
};

const renderChart = (Component, zonal, regional, nacional) => ({
    resultSet,
    error,
}) =>
    (resultSet && (
        <Component
            resultSet={resultSet}
            zonal={zonal}
            regional={regional}
            nacional={nacional}
        />
    )) || <Spin />;

const RankingAsesorCdt = (props) => {
    var [zonal, setZonal] = useState({
        name: "",
        data: [["0", 0, 0]],
    });
    var [zonalX, setZonalX] = useState("0");
    var [zonalY, setZonalY] = useState(0);
    var [zonalZ, setZonalZ] = useState(0);

    var [regional, setRegional] = useState({
        name: "",
        data: [["0", 0, 0]],
    });
    var [regionalX, setRegionalX] = useState("0");
    var [regionalY, setRegionalY] = useState(0);
    var [regionalZ, setRegionalZ] = useState(0);

    var [nacional, setNacional] = useState({
        name: "",
        data: [["0", 0, 0]],
    });
    var [nacionalX, setNacionalX] = useState("0");
    var [nacionalY, setNacionalY] = useState(0);
    var [nacionalZ, setNacionalZ] = useState(0);

    useEffect(() => {
        setZonal({ name: "ZONAL", data: [[zonalX, zonalY, zonalZ]] });
        setRegional({
            name: "REGIONAL",
            data: [[regionalX, regionalY, regionalZ]],
        });
        setNacional({
            name: "NACIONAL",
            data: [[nacionalX, nacionalY, nacionalZ]],
        });
    }, [
        zonalX,
        zonalY,
        zonalZ,
        regionalX,
        regionalY,
        regionalZ,
        nacionalX,
        nacionalY,
        nacionalZ,
    ]);
    return (
        <div style={{ width: "100%" }}>
            {/* <RankingMicrocreditoZonal
        zona={props.zona}
        fecha={props.fecha}
        setZonalX={setZonalX}
        setZonalY={setZonalY}
        setZonalZ={setZonalZ}
      />
      <RankingMicrocreditoRegional
        region={props.region}
        fecha={props.fecha}
        setRegionalX={setRegionalX}
        setRegionalY={setRegionalY}
        setRegionalZ={setRegionalZ}
      />
      <RankingMicrocreditoNacional
        fecha={props.fecha}
        setNacionalX={setNacionalX}
        setNacionalY={setNacionalY}
        setNacionalZ={setNacionalZ}
      /> */}
            <QueryRenderer
                query={{
                    renewQuery: true,
                    dimensions: ["Usuarios.nombre"],
                    timeDimensions: [],
                    measures: [
                        "ResumenActividadCdt.porcentajeRenovacion",
                        "ResumenSaldosCdt.factorTasa",
                        "ResumenSaldosCdt.saldoTotal",
                    ],
                    filters: [
                        // {
                        //   dimension: "ResumenMicrocredito.fechaCorte",
                        //   operator: "contains",
                        //   values: [props.fecha]
                        // },
                        {
                            dimension: "Usuarios.idCedula",
                            operator: "contains",
                            values: props.cedula !== "" ? [props.cedula] : [],
                        },
                        {
                            dimension: "ResumenActividadCdt.oficina",
                            operator: "contains",
                            values: props.oficina !== "" ? [props.oficina] : [],
                        },
                        // {
                        //   dimension: "Usuarios.cargo",
                        //   operator: "notContains",
                        //   values: ["ZONA"]
                        // },
                        // {
                        //   dimension: "Usuarios.cargo",
                        //   operator: "notEquals",
                        //   values: ["SIN ASESOR"]
                        // },
                        // {
                        //   dimension: "Usuarios.cargo",
                        //   operator: "notEquals",
                        //   values: ["ASESOR RETIRADO"]
                        // }
                    ],
                }}
                cubejsApi={cubejsApi}
                render={renderChart(
                    rankingAsesorCdt,
                    zonal,
                    regional,
                    nacional
                )}
            />
        </div>
    );
};

export default RankingAsesorCdt;
