import React from "react";
import { QueryRenderer } from "@cubejs-client/react";
import { Typography, Spin } from "antd";
import cubejsApi from "../cubejsApi";

const { Title, Text } = Typography;

const fechaActualizacion = ({ resultSet }) => {
    const fecha = resultSet.loadResponse.data[0][
        "EjecucionProcesos.fechaProceso"
    ].slice(0, -11);
    return (
        <div>
            <Title code style={{ fontSize: "16px" }}>
                Fecha Actualización: <Text>{fecha}</Text>
            </Title>
        </div>
    );
};

const renderChart = Component => ({ resultSet, error }) =>
    (resultSet && <Component resultSet={resultSet} />) || <Spin />;

const FechaActualizacion = props => (
    <QueryRenderer
        query={{
            renewQuery: true,
            timezone: "America/Bogota",
            measures: [],
            timeDimensions: [],
            dimensions: ["EjecucionProcesos.fechaProceso"],
            segments: [],
            filters: [
                {
                    dimension: "EjecucionProcesos.tipoProceso",
                    operator: "contains",
                    values: [props.tipoProceso]
                }
            ]
        }}
        cubejsApi={cubejsApi}
        render={renderChart(fechaActualizacion)}
    />
);

export default FechaActualizacion;
