import React from "react";
import { QueryRenderer } from "@cubejs-client/react";
import { Spin } from "antd";
import { AutoComplete } from "antd";
import cubejsApi from "../../cubejsApiBQ";

const puntoBar = ({ resultSet, props }) => {
    var dataSource = [];
    var punto = "";
    resultSet.loadResponse.data.map((result, index) => {
        return (dataSource[index] = result["RESUMENTigo.punto"]);
    });
    //   if (props.cargo) {
    //     punto = dataSource[dataSource.indexOf(props.nombre)];
    //   }
    return (
        <AutoComplete
            style={{
                width: "100%",
                marginBottom: "25px",
            }}
            disabled={props.cargo}
            allowClear
            size="large"
            dataSource={dataSource}
            placeholder="Punto"
            defaultValue={punto}
            filterOption={(inputValue, option) => {
                return (
                    option.props.children
                        .toUpperCase()
                        .indexOf(inputValue.toUpperCase()) !== -1
                );
            }}
            onSelect={(value) => props.setPunto(value)}
            onSearch={() => props.setPunto("")}
        />
    );
};

const renderChart = (Component, props) => ({ resultSet, error }) =>
    (resultSet && <Component resultSet={resultSet} props={props} />) || (
        <Spin />
    );

const PuntoBar = (props) => (
    <QueryRenderer
        query={{
            renewQuery: true,
            measures: [],
            timeDimensions: [],
            dimensions: ["RESUMENTigo.punto"],
            segments: [],
            filters: [
                {
                    dimension: "RESUMENTigo.region",
                    operator: "contains",
                    values: props.region !== "" ? [props.region] : [],
                },
                {
                    dimension: "RESUMENTigo.supervisor",
                    operator: "contains",
                    values: props.supervisor !== "" ? [props.supervisor] : [],
                },
            ],
        }}
        cubejsApi={cubejsApi}
        render={renderChart(puntoBar, props)}
    />
);

export default PuntoBar;
