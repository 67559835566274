import React, { useState, useEffect } from "react";
import { QueryRenderer } from "@cubejs-client/react";
import { Spin } from "antd";
import Chart from "react-apexcharts";
import cubejsApi from "../../../cubejsApiBQ";

const SERIESLABELS = [
  "Ind Cartera 30",
  "Ind Cartera Total",
  "Presupuesto Crecimiento",
];
const RankingColumn2 = ({ resultSet, props }) => {
  var series = [];
  var data = [];
  var category = [];
  var promedios;
  const [series2, setSeries2] = useState(null);
  const [series3, setSeries3] = useState(null);

  promedios = [
    "ResumenMicrocredito.indicadorCarteraVencida30Promedio",
    "ResumenMicrocredito.indicadorCarteraMoraTotalPromedio",
    "ResumenMicrocredito.indicadorPresupuestoCrecimientoPromedio",
  ];
  var promedio = ["regional", "nacional"];
  var filtros = [];

  useEffect(() => {
    promedio.map((e, i) => {
      filtros = [
        {
          dimension: "ResumenMicrocredito.fechaCorte",
          operator: "contains",
          values: [props.fecha],
        },
      ];
      if (e === "regional") {
        filtros = filtros.concat({
          dimension: "Usuarios.region",
          operator: "contains",
          values: [props.region],
        });
      }
      cubejsApi
        .load({
          measures: promedios,
          timeDimensions: [],
          filters: filtros,
        })
        .then((resultset2) => {
          var series2 = [];
          if (resultset2 === undefined) {
            series2 = [{ name: "No hay datos", data: [0] }];
          } else {
            series2 = resultset2.series().map((r, index) => {
              data = [];
              r.series.map((w, index2) => {
                data[index2] =
                  w.value == null ? 0 : parseFloat(w.value.toFixed(1));
              });
              return {
                name: SERIESLABELS[index],
                data: data,
              };
            });
          }
          if (i === 0) setSeries2(series2);
          if (i === 1) setSeries3(series2);
        });
    });
  }, []);

  if (resultSet.series()[0] === undefined) {
    series = [{ name: "No hay datos", data: [0] }];
  } else {
    resultSet.series()[0].series.map((names, i) => {
      var original = names.category;
      var result = "";
      var resultor = "";
      result = original.substr(original.indexOf(" ") + 1);
      resultor = result.substr(result.indexOf(" ") + 1);
      category[i] = resultor;
    });
    category.push("Prom. Regional");
    category.push("Prom. Nacional");

    series = resultSet.series().map((r, index) => {
      data = [];
      r.series.map((w, index2) => {
        data[index2] = w.value == null ? 0 : parseFloat(w.value.toFixed(1)); //index;
      });
      return {
        name: SERIESLABELS[index],
        data: data,
      };
    });
  }

  const options = {
    chart: {
      height: 350,
      type: "line",
      stacked: false,
    },
    dataLabels: {
      formatter: function (val) {
        return val + "%";
      },
      style: {
        fontSize: "8px",
      },
    },
    stroke: {
      width: [1, 1, 4],
    },
    title: {
      text: "",
      align: "left",
      offsetX: 110,
    },
    xaxis: {
      categories: category,
    },
    yaxis: [
      {
        seriesName: "Ind Cartera Total",
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: "#008FFB",
        },
        labels: {
          formatter: function (val) {
            return val.toFixed(1) + "%";
          },
          style: {
            colors: "#008FFB",
          },
        },
        title: {
          text: "Indicador Cartera 30 y Total",
          style: {
            color: "#008FFB",
          },
        },
        tooltip: {
          enabled: true,
        },
        //min: -0.1,
      },
      {
        // seriesName: "Indicador Cartera Total",
        show: false,
        opposite: true,
        axisTicks: {
          show: false,
        },
        axisBorder: {
          show: false,
          color: "#00E396",
        },
        labels: {
          style: {
            colors: "#008FFB",
          },
        },
        title: {
          text: "Indicador Cartera Total",
          style: {
            color: "#00E396",
          },
        },
      },
      {
        seriesName: "Presupuesto Crecimiento",
        opposite: true,
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: "#FEB019",
        },
        labels: {
          formatter: function (val) {
            return val.toFixed(1) + "%";
          },
          style: {
            colors: "#FEB019",
          },
        },
        title: {
          text: "Presupuesto Crecimiento",
          style: {
            color: "#FEB019",
          },
        },
      },
    ],
    tooltip: {
      fixed: {
        enabled: true,
        position: "topLeft", // topRight, topLeft, bottomRight, bottomLeft
        offsetY: 30,
        offsetX: 60,
      },
    },
    legend: {
      horizontalAlign: "left",
      offsetX: 40,
    },
  };

  var seriesTotal = series;
  if (series2 !== null && series3 !== null && series[1] !== undefined) {
    series[0].data.push(series2[0].data[0]);
    series[1].data.push(series2[1].data[0]);
    series[2].data.push(series2[2].data[0]);

    series[0].data.push(series3[0].data[0]);
    series[1].data.push(series3[1].data[0]);
    series[2].data.push(series3[2].data[0]);
    seriesTotal = series;
  }

  return (
    <div style={{ width: "100%" }}>
      <Chart options={options} series={seriesTotal} type="bar" height="350" />
    </div>
  );
};

const renderChart = (Component, props) => ({ resultSet, error }) => {
  return (
    (resultSet && <Component resultSet={resultSet} props={props} />) || <Spin />
  );
};

const RankingColumn = (props) => {
  return (
    <QueryRenderer
      query={{
        measures: [
          "ResumenMicrocredito.indicadorCarteraVencida30",
          "ResumenMicrocredito.indicadorCarteraMoraTotal",
          "ResumenMicrocredito.indicadorPresupuestoCrecimiento",
        ],
        dimensions: ["Usuarios.nombre"],
        filters: [
          {
            dimension: "ResumenMicrocredito.fechaCorte",
            operator: "contains",
            values: props.fecha !== "" ? [props.fecha] : [],
          },
          {
            dimension: "Usuarios.idCedula",
            operator: "contains",
            values: props.cedula !== "" ? [props.cedula] : [],
          },
          {
            dimension: "Usuarios.region",
            operator: "contains",
            values: props.zona !== "" ? [props.zona] : [],
          },
        ],
      }}
      cubejsApi={cubejsApi}
      render={renderChart(RankingColumn2, props)}
    />
  );
};

export default RankingColumn;
