import cubejs from "@cubejs-client/core";
const jwt = require("jsonwebtoken");

// const API_URL = "http://localhost:4000";
const API_URL =
    "https://us-central1-dicocf.cloudfunctions.net/bqcube-dev-cubejs";

const CUBE_API_SECRET =
    "8b664bd9aaa092f7599070e5fd14c07adbf200c8092169982a7d228a56344cac81654e7f4883af96cf1186faffe76676dbb6ba31dfbf611e14ea1ed68ba8587f";
const CUBEJS_TOKEN = jwt.sign({}, CUBE_API_SECRET, { expiresIn: "1d" });
const cubejsApi = cubejs(CUBEJS_TOKEN, {
    apiUrl: `${API_URL}/cubejs-api/v1`,
});

export default cubejsApi;
