import React from "react";
import { QueryRenderer } from "@cubejs-client/react";
import { Spin } from "antd";
import { AutoComplete, Input, Icon } from "antd";
import cubejsApi from "../../cubejsApiBQ";

const cascaderCdt = ({ resultSet, props }) => {
    var dataSource = [];
    resultSet.loadResponse.data.map((result, index) => {
        var start = result["ResumenActividadCdt.mesCorte"].slice(0, 4);
        var end = result["ResumenActividadCdt.mesCorte"].slice(-2);
        var concat = start.concat("-", end);
        return (dataSource[index] = concat);
    });

    if (props.mesCorte === "") {
        let fecha = dataSource[dataSource.length - 1];
        let inicio = fecha.slice(0, 4);
        let final = fecha.slice(-2);
        let concat = inicio.concat(final);
        props.setMesCorte(concat);
    }

    const _onSelect = (value) => {
        let fecha = value;
        let inicio = fecha.slice(0, 4);
        let final = fecha.slice(-2);
        let concat = inicio.concat(final);
        props.setMesCorte(concat);
    };
    return (
        <AutoComplete
            style={{
                width: "100%",
                marginBottom: "25px",
            }}
            size="large"
            dataSource={dataSource}
            defaultValue={dataSource[dataSource.length - 1]}
            onSelect={(value) => _onSelect(value)}
        >
            <Input
                suffix={<Icon type="down" className="certain-category-icon" />}
            />
        </AutoComplete>
    );
};

const renderChart = (Component, props) => ({ resultSet, error }) =>
    (resultSet && <Component resultSet={resultSet} props={props} />) || (
        <Spin />
    );

const CascaderCdt = (props) => (
    <QueryRenderer
        query={{
            dimensions: ["ResumenActividadCdt.mesCorte"],
            timeDimensions: [],
            segments: [],
            measures: [],
            filters: [],
            order: {
                // "BaseResumenColocacion.fechaCorte": "asc"
            },
            renewQuery: true,
        }}
        cubejsApi={cubejsApi}
        render={renderChart(cascaderCdt, props)}
    />
);

export default CascaderCdt;
