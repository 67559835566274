import React from "react";
import { Card, Row, Col } from "antd";
import MedidasIndicador from "./MedidasIndicador";

const IndicadorPrincipal = props => {
  return (
    <Row gutter={[12, 10]} type="flex" justify="space-between">
      <Col xs={24} lg={24}>
        <Card size="small" className="card" style={{ background: "#F2F2F2" }}>
          <MedidasIndicador
            fecha={props.fecha}
            cedula={props.cedula}
            zona={props.zona}
            plazas={props.plazas}
          />
        </Card>
      </Col>
    </Row>
  );
};

export default IndicadorPrincipal;
