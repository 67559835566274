import React from "react";
import "antd/dist/antd.css";
import { Layout, Spin } from "antd";
import firebase from "./firebase";
import Header from "./components/header/Header";
import SignIn from "./pages/SignIn";
import ErrorBoundary from "./components/ErrorBoundary";

class AppLayout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: "",
            password: "",
        };
    }

    onPressLogin = () => {
        firebase
            .auth()
            .signInWithEmailAndPassword(this.state.user, this.state.password)
            .then(
                () => {
                    this.props.setLogin({ login: true });
                    this.props.setUser({ user: this.state.user });
                },
                (error) => {
                    if (this.state.user === "") alert("Ingrese usuario");
                    if (this.state.password === "") alert("Ingrese contraseña");
                    else {
                        alert("Usuario o contraseña incorrectos");
                        this.setState({ user: "", password: "" });
                    }
                }
            );
    };

    updateState = (data) => {
        this.setState(data);
    };

    render() {
        return (
            <Layout style={{ height: "100%" }}>
                {this.props.login ? (
                    <ErrorBoundary>
                        <div>
                            <Header
                                setLogin={this.props.setLogin.bind(this)}
                                setZona={this.props.setZona.bind(this)}
                                setRegion={this.props.setRegion.bind(this)}
                                setIsReady={this.props.setIsReady.bind(this)}
                                setNombre={this.props.setNombre.bind(this)}
                                setCargo={this.props.setCargo.bind(this)}
                                setPenUltFecha={this.props.setPenUltFecha.bind(
                                    this
                                )}
                                setTipoCargo={this.props.setTipoCargo.bind(
                                    this
                                )}
                                zona={this.props.zona}
                                user={this.state.user}
                                nombre={this.props.nombre}
                                cargo={this.props.cargo}
                                tipoCargo={this.props.tipoCargo}
                            />
                            {this.props.isReady ? (
                                <Layout.Content>
                                    {this.props.children}
                                </Layout.Content>
                            ) : (
                                <Spin />
                            )}
                        </div>
                    </ErrorBoundary>
                ) : (
                    <SignIn
                        updateParentState={this.updateState.bind(this)}
                        onPressLogin={this.onPressLogin.bind(this)}
                        user={this.state.user}
                        password={this.state.password}
                    />
                )}
            </Layout>
        );
    }
}

const App = (props) => (
    <AppLayout
        setLogin={props.setLogin.bind(this)}
        setZona={props.setZona.bind(this)}
        setRegion={props.setRegion.bind(this)}
        setUser={props.setUser.bind(this)}
        setIsReady={props.setIsReady.bind(this)}
        setNombre={props.setNombre.bind(this)}
        setCargo={props.setCargo.bind(this)}
        setPenUltFecha={props.setPenUltFecha.bind(this)}
        setTipoCargo={props.setTipoCargo.bind(this)}
        isReady={props.isReady}
        login={props.login}
        zona={props.zona}
        user={props.user}
        nombre={props.nombre}
        cargo={props.cargo}
        tipoCargo={props.tipoCargo}
    >
        {props.children}
    </AppLayout>
);

export default App;
