import React from "react";
import { QueryRenderer } from "@cubejs-client/react";
import { Spin } from "antd";
import { AutoComplete } from "antd";
import cubejsApi from "../../cubejsApiBQ";

const puntoVentaBar = ({ resultSet, props }) => {
    var dataSource = [];
    var puntoVenta = "";

    resultSet.loadResponse.data.map((result, index) => {
        return (dataSource[index] = result["Usuarios.puntoVenta"]);
    });

    if (!props.tipoCargo.includes("ZONA")) {
        puntoVenta = dataSource[dataSource.length - 1];
        props.setPuntoVenta(puntoVenta);
    }

    if (
        props.tipoCargo.includes("ZONA") &&
        (props.cedula !== "" || props.coordinador !== "")
    ) {
        puntoVenta = dataSource[dataSource.length - 1];
        props.setPuntoVenta(puntoVenta);
    }

    return (
        <AutoComplete
            style={{
                width: "100%",
                marginBottom: "25px",
            }}
            disabled={
                props.cargo ||
                props.tipoCargo.includes("COORDINADOR") ||
                props.cedula !== "" ||
                props.coordinador !== ""
            }
            allowClear
            size="large"
            dataSource={dataSource}
            placeholder="Punto de Venta"
            defaultValue={puntoVenta}
            filterOption={(inputValue, option) => {
                return (
                    option.props.children
                        .toUpperCase()
                        .indexOf(inputValue.toUpperCase()) !== -1
                );
            }}
            onSelect={(value) => {
                props.setPuntoVenta(value);
            }}
            onSearch={() => {
                props.setPuntoVenta("");
            }}
        />
    );
};

const renderChart = (Component, props) => ({ resultSet, error }) =>
    (resultSet && <Component resultSet={resultSet} props={props} />) || (
        <Spin />
    );

const PuntoVentaBar = (props) => {
    var filtros = [
        {
            dimension: "Usuarios.zona",
            operator: "contains",
            values: [props.zona],
        },
        {
            dimension: "Usuarios.producto",
            operator: "contains",
            values: ["crediuno"],
        },
    ];

    if (!props.tipoCargo.includes("ZONA")) {
        filtros = filtros.concat({
            dimension: "Usuarios.nombre",
            operator: "contains",
            values: [props.nombre],
        });
    }
    if (props.tipoCargo.includes("ZONA")) {
        filtros = filtros.concat({
            dimension: "Usuarios.idCedula",
            operator: "contains",
            values: [props.cedula],
        });
    }
    if (props.coordinador !== "") {
        filtros = filtros.concat({
            dimension: "Usuarios.nombre",
            operator: "contains",
            values: [props.coordinador],
        });
    }
    return (
        <QueryRenderer
            query={{
                renewQuery: true,
                measures: [],
                timeDimensions: [],
                dimensions: ["Usuarios.puntoVenta"],
                segments: [],
                filters: filtros,
            }}
            cubejsApi={cubejsApi}
            render={renderChart(puntoVentaBar, props)}
        />
    );
};

export default PuntoVentaBar;
