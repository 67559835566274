import React, { Component } from "react";
import moment from "moment";
import {
    Form,
    Table,
    Button,
    Spin,
    Modal,
    Tag,
    AutoComplete,
    Input,
} from "antd";
import axios from "axios";

var yourDate = new Date();
var fecha = yourDate.toISOString().split("T")[0];

class Gestion extends Component {
    constructor(props) {
        super(props);

        this.state = {
            cliente: [],
            visible: false,
            confirmLoading: false,
            Resultado: String,
            Canal: String,
            Accion: String,
            Id_Gestion: String,
            Oferta: [],
            sortedInfo: null,
            ofertas: [],
        };

        this.handleChange = this.handleChange.bind(this);
        this.onSelectOferta = this.onSelectOferta.bind(this);
    }

    handleChange(event) {
        const target = event.target;
        switch (target.id) {
            case "Id_Gestion":
                this.setState({ Id_Gestion: target.value });
                break;
            default:
                break;
        }
    }

    onSelectOferta = async (value) => {
        const id_oferta = await this.state.ofertas.find(
            (obj) => obj.SubProducto == value
        );
        this.setState({ Oferta: id_oferta });
        console.log(this.state.Oferta.ID_OFERTA);
    };

    _handleChange = (pagination, filters, sorter) => {
        console.log("Various parameters", pagination, filters, sorter);
        this.setState({
            sortedInfo: sorter,
        });
    };

    showModal = async () => {
        this.setState({
            visible: true,
        });
    };

    handleOk = async (e) => {
        this.setState({
            confirmLoading: true,
        });
        await this.apiPost();
    };

    handleCancel = () => {
        this.setState({
            visible: false,
        });
    };

    apiSearch = async () => {
        await axios
            .get(
                `https://us-central1-dicocf.cloudfunctions.net/functions/gestion/id?id=${this.props.id}`
            )
            .then((res) => {
                this.setState({
                    cliente: res.data,
                });
            });
    };

    apiOferta = async () => {
        await axios
            .get(
                `https://us-central1-dicocf.cloudfunctions.net/functions/oferta/id?id=${this.props.id}`
            )
            .then((res) => {
                this.setState({
                    ofertas: res.data,
                });
            });
    };

    apiPost = async () => {
        await axios
            .post(
                `https://us-central1-dicocf.cloudfunctions.net/functions/gestion`,
                {
                    id_usuario: "123456789", //Mientras se conecta la informacion de los asesores
                    id_cedula: this.props.id,
                    id_oferta: this.state.Oferta.ID_OFERTA,
                    id_gestion: this.state.Id_Gestion,
                    fecha: fecha,
                    resultado: this.state.Resultado,
                    canal: this.state.Canal,
                    accion: this.state.Accion,
                    tipo: "Asesor", //Mientras se conecta la informacion de los asesores
                }
            )
            .then((response) => {
                console.log(response.data);
            })
            .catch((error) => {
                console.log("Error", error);
            });
    };

    componentDidMount = async () => {
        console.log(fecha);
        await this.apiSearch();
        await this.apiOferta();
    };

    componentDidUpdate = async (prevProps) => {
        if (prevProps.id !== this.props.id) {
            await this.apiSearch();
            await this.apiOferta();
        }
    };

    tagColor(resultado) {
        let color = "volcano";
        if (resultado === "Interesado") {
            color = "green";
        } else if (resultado === "No Contesta") {
            color = "blue";
        }
        return color;
    }

    convert(fecha) {
        let newDate;
        moment().add();
        newDate = moment(fecha).add(1, "days").format("L");
        return newDate;
    }

    render() {
        let { sortedInfo } = this.state;
        sortedInfo = sortedInfo || {};
        const columns = [
            {
                title: "Nombre",
                dataIndex: "nombre_subprod",
                key: "nombre",
                sorter: (a, b) =>
                    a.nombre_subprod.length - b.nombre_subprod.length,
                sortOrder:
                    sortedInfo.columnKey === "nombre" && sortedInfo.order,
                ellipsis: true,
            },
            {
                title: "Fecha",
                dataIndex: "FECHA",
                key: "FECHA",
                render: (fecha) => (
                    <p style={{ marginBottom: "0px" }}>{this.convert(fecha)}</p>
                ),
            },
            { title: "Canal", dataIndex: "CANAL" },
            {
                title: "Resultado",
                dataIndex: "RESULTADO",
                render: (resultado) => (
                    <span>
                        <Tag color={this.tagColor(resultado)} key={resultado}>
                            {resultado.toUpperCase()}
                        </Tag>
                    </span>
                ),
            },
            { title: "Accion", dataIndex: "ACCION" },
        ];
        const { visible, confirmLoading } = this.state; //Modal
        let uniqueId = 0;
        return (
            <div>
                {this.state.cliente ? (
                    <Table
                        columns={columns}
                        dataSource={this.state.cliente}
                        onChange={this._handleChange}
                        rowKey={(record) => {
                            if (!record.__uniqueId)
                                record.__uniqueId = ++uniqueId;
                            return record.__uniqueId;
                        }}
                        pagination={{
                            defaultPageSize: 5,
                            showSizeChanger: true,
                            pageSizeOptions: ["5", "10", "20"],
                        }}
                        size="small"
                    />
                ) : (
                    <Spin />
                )}
                <Button
                    onClick={this.showModal}
                    style={{ position: "absolute", top: "88%", zIndex: 100 }}
                >
                    Agregar gestion
                </Button>
                {this.state.ofertas ? (
                    <Modal
                        title="Agregar Gestion"
                        visible={visible}
                        onOk={this.handleOk}
                        confirmLoading={confirmLoading}
                        onCancel={this.handleCancel}
                    >
                        <Form layout="inline">
                            <Form.Item>
                                <Input
                                    placeholder="Id Gestion" //toca ver como agregar este campo automaticamente, sin necesidad que el asesor la escriba
                                    type="text"
                                    id="Id_Gestion"
                                    onChange={this.handleChange}
                                />
                            </Form.Item>
                            <Form.Item>
                                <AutoComplete
                                    dataSource={this.state.ofertas.map(
                                        (oferta) => {
                                            return oferta.SubProducto;
                                        }
                                    )}
                                    placeholder="Escribir Oferta"
                                    id="Oferta"
                                    onSelect={this.onSelectOferta}
                                    filterOption={(inputValue, option) =>
                                        option.props.children
                                            .toUpperCase()
                                            .indexOf(
                                                inputValue.toUpperCase()
                                            ) !== -1
                                    }
                                />
                            </Form.Item>
                            <Form.Item>
                                <AutoComplete
                                    dataSource={[
                                        "Interesado",
                                        "No Contesta",
                                        "Rechazado",
                                    ]}
                                    placeholder={"Resultado"}
                                    id="Resultado"
                                    onSelect={async (value) => {
                                        await this.setState({
                                            Resultado: value,
                                        });
                                    }}
                                    filterOption={(inputValue, option) =>
                                        option.props.children
                                            .toUpperCase()
                                            .indexOf(
                                                inputValue.toUpperCase()
                                            ) !== -1
                                    }
                                />
                            </Form.Item>
                            <Form.Item>
                                <AutoComplete
                                    dataSource={["Telefono", "SMS", "Correo"]}
                                    placeholder="Canal"
                                    id="Canal"
                                    onSelect={async (value) => {
                                        await this.setState({ Canal: value });
                                    }}
                                    filterOption={(inputValue, option) =>
                                        option.props.children
                                            .toUpperCase()
                                            .indexOf(
                                                inputValue.toUpperCase()
                                            ) !== -1
                                    }
                                />
                            </Form.Item>
                            <Form.Item>
                                <AutoComplete
                                    dataSource={[
                                        "Llamar",
                                        "Enviar SMS",
                                        "Enviar Correo",
                                    ]}
                                    placeholder="Accion"
                                    id="Accion"
                                    onSelect={async (value) => {
                                        await this.setState({ Accion: value });
                                    }}
                                    filterOption={(inputValue, option) =>
                                        option.props.children
                                            .toUpperCase()
                                            .indexOf(
                                                inputValue.toUpperCase()
                                            ) !== -1
                                    }
                                />
                            </Form.Item>
                        </Form>
                    </Modal>
                ) : (
                    <Spin />
                )}
            </div>
        );
    }
}

export default Gestion;
