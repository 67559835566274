import React, { useState, useEffect } from "react";
import { QueryRenderer } from "@cubejs-client/react";
import { Spin } from "antd";
import Chart from "react-apexcharts";
import cubejsApi from "../../../cubejsApiBQ";

const SERIESLABELS = [
  "Total Entregas",
  "Total de activacion",
  "% de Activacion",
];

const SERIESTYPES = ["column", "column", "column"];
const RankingColumn2 = ({ resultSet, props }) => {
  var series = [];
  var data = [];
  var category = [];
  var promedios;
  var mesCortePromedio;
  var anioCortePromedio;
  const [series2, setSeries2] = useState(null);
  const [series3, setSeries3] = useState(null);
  const [series4, setSeries4] = useState(null);
  if (props.tipo === "servicio") {
    SERIESLABELS[2] = "% de Seguro";
  } else {
    SERIESLABELS[2] = "% de Activación";
  }
  if (props.tipo === "servicio") {
    promedios = [
      "PromedioServicio.entregas",
      "PromedioServicio.activacion",
      "PromedioServicio.segurosTotal",
    ];
    mesCortePromedio = "PromedioServicio.mes";
    anioCortePromedio = "PromedioServicio.anio";
  } else if (props.tipo === "comercial") {
    promedios = [
      "PromedioComercial.entregas",
      "PromedioComercial.activacion",
      "PromedioComercial.porcentajeActivacion",
    ];
    mesCortePromedio = "PromedioComercial.mes";
    anioCortePromedio = "PromedioComercial.anio";
  } else {
    promedios = [
      "PromedioPDV.entregas",
      "PromedioPDV.activacionTotal",
      "PromedioPDV.porcentajeActivacion",
    ];
    mesCortePromedio = "PromedioPDV.mes";
    anioCortePromedio = "PromedioPDV.anio";
  }
  var promedio = ["zonal", "regional", "nacional"];
  var filtros = [];

  useEffect(() => {
    promedio.map((e, i) => {
      filtros = [
        {
          dimension: mesCortePromedio,
          operator: "contains",
          values: props.mes !== "" ? [props.mes] : [],
        },
        {
          dimension: anioCortePromedio,
          operator: "contains",
          values: props.anio !== "" ? [props.anio] : [],
        },
      ];
      if (e === "regional") {
        if (props.tipo === "comercial" || props.tipo === "servicio") {
          filtros = filtros.concat({
            dimension: "Usuarios.region",
            operator: "contains",
            values: props.region !== "" ? [props.region] : [],
          });
        }
        if (props.tipo !== "comercial" && props.tipo !== "servicio") {
          filtros = filtros.concat({
            dimension: "PromedioPDV.region",
            operator: "contains",
            values: props.region !== "" ? [props.region] : [],
          });
        }
      }
      if (e === "zonal") {
        if (props.tipo === "comercial" || props.tipo === "servicio") {
          filtros = filtros.concat({
            dimension: "Usuarios.zona",
            operator: "contains",
            values: props.zona !== "" ? [props.zona] : [],
          });
        }
        if (props.tipo !== "comercial" && props.tipo !== "servicio") {
          filtros = filtros.concat({
            dimension: "PromedioPDV.zona",
            operator: "contains",
            values: props.zona !== "" ? [props.zona] : [],
          });
        }
      }
      if (e === "nacional") {
        if (props.tipo === "comercial") {
          filtros = filtros.concat({
            dimension: "PromedioComercial.region",
            operator: "notEquals",
            values: ["0", "(en blanco)", "NULL"],
          });
        }
      }
      cubejsApi
        .load({
          measures: promedios,
          timeDimensions: [],
          filters: filtros,
        })
        .then((resultset2) => {
          var series2 = [];
          if (resultset2 === undefined) {
            series2 = [{ name: "No hay datos", data: [0] }];
          } else {
            series2 = resultset2.series().map((r, index) => {
              data = [];
              r.series.map((w, index2) => {
                data[index2] =
                  w.value == null ? 0 : parseFloat(w.value.toFixed(1));
              });
              return {
                name: SERIESLABELS[index],
                type: SERIESTYPES[index],
                data: data,
              };
            });
          }
          if (i === 0) setSeries2(series2);
          if (i === 1) setSeries3(series2);
          if (i === 2) setSeries4(series2);
        });
    });
  }, []);

  if (resultSet.series()[0] === undefined) {
    series = [{ name: "No hay datos", data: [0] }];
  } else {
    resultSet.series()[0].series.map((names, i) => {
      var original = names.category;
      var result = "";
      if (props.tipo === "servicio" || props.tipo === "comercial") {
        result = original.substr(original.indexOf(" ") + 1);
        category[i] = result.substr(result.indexOf(" ") + 1);
      } else {
        category[i] = original;
      }
    });
    category.push("Prom. Zonal");
    category.push("Prom. Regional");
    category.push("Prom. Nacional");

    series = resultSet.series().map((r, index) => {
      data = [];
      r.series.map((w, index2) => {
        data[index2] = w.value == null ? 0 : parseFloat(w.value.toFixed(1));
      });
      return {
        name: SERIESLABELS[index],
        type: SERIESTYPES[index],
        data: data,
      };
    });
  }

  const options = {
    chart: {
      height: 350,
      type: "line",
      stacked: false,
    },
    dataLabels: {
      enabled: true,
      enabledOnSeries: [0, 1, 2],
      formatter: function (val, { seriesIndex, dataPointIndex, w }) {
        return seriesIndex === 2 ? val + "%" : val;
      },
      style: {
        colors: ["#3D3D3D"],
      },
    },
    stroke: {
      width: [1, 1, 4],
    },
    title: {
      text: "",
      align: "left",
      offsetX: 110,
    },
    xaxis: {
      categories: category,
    },
    yaxis: [
      {
        seriesName: "Total de activacion",
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: "#008FFB",
        },
        labels: {
          style: {
            colors: "#008FFB",
          },
        },
        title: {
          text: "Total Entregas y Activación",
          style: {
            color: "#008FFB",
          },
        },
        tooltip: {
          enabled: true,
        },
        //min: -0.1,
      },
      {
        show: false,
        opposite: false,
        axisTicks: {
          show: false,
        },
        axisBorder: {
          show: false,
          color: "#00E396",
        },
        labels: {
          style: {
            colors: "#00E396",
          },
        },
        title: {
          text: "Total de activacion",
          style: {
            color: "#00E396",
          },
        },
      },
      {
        seriesName: "Activacion",
        opposite: true,
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: "#FEB019",
        },
        labels: {
          formatter: function (val) {
            return val + "%";
          },
          style: {
            colors: "#000000",
          },
        },
        title: {
          text: "Activacion",
          style: {
            color: "#FEB019",
            // color: "#FEB019",
          },
        },
      },
    ],
    tooltip: {
      fixed: {
        enabled: true,
        position: "topLeft", // topRight, topLeft, bottomRight, bottomLeft
        offsetY: 30,
        offsetX: 60,
      },
    },
    legend: {
      horizontalAlign: "left",
      offsetX: 40,
    },
  };

  var seriesTotal = series;
  if (
    series2 !== null &&
    series3 !== null &&
    series4 !== null &&
    series[1] !== undefined
  ) {
    series[0].data.push(series2[0].data[0]);
    series[1].data.push(series2[1].data[0]);
    series[2].data.push(series2[2].data[0]);

    series[0].data.push(series3[0].data[0]);
    series[1].data.push(series3[1].data[0]);
    series[2].data.push(series3[2].data[0]);

    series[0].data.push(series4[0].data[0]);
    series[1].data.push(series4[1].data[0]);
    series[2].data.push(series4[2].data[0]);
    seriesTotal = series;
  }
  return (
    <div style={{ width: "100%" }}>
      <Chart options={options} series={seriesTotal} type="line" height="350" />
    </div>
  );
};

const renderChart = (Component, props) => ({ resultSet, error }) => {
  return (
    (resultSet && <Component resultSet={resultSet} props={props} />) || <Spin />
  );
};

const RankingColumn = (props) => {
  var measures;
  var promedios;
  var mesCorte;
  var anioCorte;
  var mesCortePromedio;
  var anioCortePromedio;
  var cargo;
  var dimensiones;

  if (props.tipo === "servicio") {
    measures = [
      "CrediunoAsesorServicio.entregas",
      "CrediunoAsesorServicio.activacion",
      "CrediunoAsesorServicio.segurosTotal",
    ];
    promedios = [
      "PromedioServicio.activacion",
      "PromedioServicio.entregas",
      "PromedioServicio.segurosTotal",
    ];
    mesCorte = "CrediunoAsesorServicio.mes";
    anioCorte = "CrediunoAsesorServicio.anio";
    mesCortePromedio = "PromedioServicio.mes";
    anioCortePromedio = "PromedioServicio.anio";
    cargo = "ASESOR SERVICIO";
    dimensiones = "Usuarios.nombre";
  } else if (props.tipo === "comercial") {
    measures = [
      "CrediunoAsesorComercial.entregas",
      "CrediunoAsesorComercial.activacion",
      "CrediunoAsesorComercial.porcentajeActivacion",
    ];
    promedios = [
      "PromedioComercial.entregas",
      "PromedioComercial.porcentajeActivacion",
      "PromedioComercial.activacion",
    ];
    mesCorte = "CrediunoAsesorComercial.mes";
    anioCorte = "CrediunoAsesorComercial.anio";
    mesCortePromedio = "PromedioComercial.mes";
    anioCortePromedio = "PromedioComercial.anio";
    cargo = "ASESOR COMERCIAL";
    dimensiones = "Usuarios.nombre";
  } else {
    measures = [
      "CrediunoCascadaPdv.entregas",
      "CrediunoCascadaPdv.activacionTotal",
      "CrediunoCascadaPdv.porcentajeActivacion",
    ];
    promedios = [
      "PromedioPDV.entregas",
      "PromedioPDV.porcentajeActivacion",
      "PromedioPDV.activacionTotal",
    ];
    mesCorte = "CrediunoCascadaPdv.mes";
    anioCorte = "CrediunoCascadaPdv.anio";
    mesCortePromedio = "PromedioPDV.mes";
    anioCortePromedio = "PromedioPDV.anio";
    dimensiones = "CrediunoCascadaPdv.puntoVenta";
    cargo = "";
  }

  var filtros = [
    {
      dimension: mesCorte,
      operator: "contains",
      values: props.mes !== "" ? [props.mes] : [],
    },
    {
      dimension: anioCorte,
      operator: "contains",
      values: props.anio !== "" ? [props.anio] : [],
    },
  ];
  if (props.tipo === "comercial" || props.tipo === "servicio") {
    filtros = filtros.concat(
      {
        dimension: "Usuarios.cargo",
        operator: "contains",
        values: cargo !== "" ? [cargo] : [],
      },
      {
        dimension: "Usuarios.idCedula",
        operator: "contains",
        values: props.cedula !== "" ? [props.cedula] : [],
      },
      {
        dimension: "Usuarios.zona",
        operator: "contains",
        values: props.zona !== "" ? [props.zona] : [],
      },
      {
        dimension: "Usuarios.puntoVenta",
        operator: "contains",
        values: props.puntoVenta !== "" ? [props.puntoVenta] : [],
      }
    );
  }
  if (props.tipo !== "comercial" && props.tipo !== "servicio") {
    filtros = filtros.concat(
      {
        dimension: "CrediunoCascadaPdv.zona",
        operator: "contains",
        values: props.zona !== "" ? [props.zona] : [],
      },
      {
        dimension: "CrediunoCascadaPdv.puntoVenta",
        operator: "contains",
        values: props.puntoVenta !== "" ? [props.puntoVenta] : [],
      }
    );
  }
  return (
    <QueryRenderer
      query={{
        renewQuery: true,
        dimensions: [dimensiones],
        timeDimensions: [],
        measures: measures,
        filters: filtros,
      }}
      cubejsApi={cubejsApi}
      render={renderChart(RankingColumn2, props)}
    />
  );
};

export default RankingColumn;
