import React from "react";
import { QueryRenderer } from "@cubejs-client/react";
import { Spin, Row, Typography } from "antd";
import NumberFormat from "react-number-format";
import cubejsApi from "../../../cubejsApiBQ";

const { Title } = Typography;
var color;
var valor;

const terceraColumnaTigo = ({ resultSet, props }) => {
    const name = props.tercera;
    valor = resultSet.loadResponse.data[0][name[props.index]];
    if (valor === null) {
        valor = "-";
        color = "#769995";
    } else if (valor < 0) color = "#FF615E";
    else if (valor > 0) color = "#30B359";
    else color = "#769995";
    return (
        <Row gutter={[0, 0]} type="flex" justify="center">
            <Title
                style={{
                    fontSize: "13px",
                    color: color,
                }}
            >
                <NumberFormat
                    value={valor}
                    decimalScale={1}
                    displayType={"text"}
                    thousandSeparator={true}
                    suffix={"%"}
                />
            </Title>
        </Row>
    );
};

const renderChart = (Component, props) => ({ resultSet, error }) =>
    (resultSet && <Component resultSet={resultSet} props={props} />) || (
        <Spin />
    );

const TerceraColumnaTigo = (props) => {
    const measures = [
        "RESUMENTigo.porcentajeComparacionCompra",
        "RESUMENTigo.porcentajeComparacionSinCompra",
        "RESUMENTigo.porcentajeComparacionRechazadas",
        "RESUMENTigo.porcentajeComparacionFinanciadoPunto",
        "RESUMENTigo.porcentajeComparacionFinanciadoIsla",
        "RESUMENTigo.porcentajeComparacionFinanciado",
    ];

    return (
        <QueryRenderer
            query={{
                renewQuery: true,
                measures: measures,
                timeDimensions: [],
                filters: [
                    {
                        dimension: props.fechaCorte,
                        operator: "contains",
                        values: props.fecha !== "" ? [props.fecha] : [],
                    },
                    {
                        dimension: "RESUMENTigo.supervisor",
                        operator: "contains",
                        values:
                            props.supervisor !== "" ? [props.supervisor] : [],
                    },
                    {
                        dimension: "RESUMENTigo.punto",
                        operator: "contains",
                        values: props.punto !== "" ? [props.punto] : [],
                    },
                ],
                dimensions: [],
            }}
            cubejsApi={cubejsApi}
            render={renderChart(terceraColumnaTigo, props)}
        />
    );
};

export default TerceraColumnaTigo;
