import React, { Component } from "react";
import "../../../styles/staticPage.css";
import "../../../styles/boxRender.css";
import { Collapse, Spin } from "antd";
import axios from "axios";

const { Panel } = Collapse;

class CanalCom extends Component {
    constructor(props) {
        super(props);

        this.state = {
            cliente: [],
        };
    }

    apiSearch = async () => {
        await axios
            .get(
                `https://us-central1-dicocf.cloudfunctions.net/functions/canales/id?id=${this.props.id}`
            )
            .then((res) => {
                this.setState({
                    cliente: res.data,
                });
            });
    };

    componentDidMount = async () => {
        await this.apiSearch();
    };

    componentDidUpdate = async (prevProps) => {
        if (prevProps.id !== this.props.id) {
            await this.apiSearch();
        }
    };

    render() {
        const listItem = this.state.cliente.map((cli, index) => (
            <Collapse key={cli.ID_CANAL} bordered={false}>
                <Panel header={"Canal " + cli.ESTADO_CANAL}>
                    <div>
                        <p>{"Contacto Preferido: " + cli.PREFERIDO}</p>
                        <p>{"Horario de Atencion: " + cli.HORARIO_COM}</p>
                    </div>
                </Panel>
            </Collapse>
        ));

        return <div>{this.state.cliente ? listItem : <Spin />}</div>;
    }
}

export default CanalCom;
