import React from "react";
import { QueryRenderer } from "@cubejs-client/react";
import { Spin, Col, Card, Empty } from "antd";
import Chart from "react-apexcharts";
import cubejsApi from "../../../cubejsApiBQ";

const gaugeRenderMultiple = ({ resultSet, props }) => {
    var series = [];
    var labels = [];

    if (props.tipo === "comercial") {
        labels = ["Viables", "Fuelles", "Devolución", "Entregas", "Activación"];
    } else {
        labels = [
            "Viables",
            "Fuelles",
            "Devolución",
            "Entregas",
            "Activación Titular",
            "Seguros",
        ];
    }
    resultSet.seriesNames().map((s, index) => {
        if (resultSet.totalRow()[s.key] === null) {
            return resultSet.totalRow()[s.key];
        } else {
            return (series[index] = resultSet.totalRow()[s.key].toFixed(2));
        }
    });
    const options = {
        grid: {
            padding: {
                bottom: 0,
            },
        },
        plotOptions: {
            radialBar: {
                offsetY: 0,
                startAngle: 0,
                endAngle: 270,
                hollow: {
                    //   margin: 5,
                    size: "30%",
                    background: "transparent",
                    image: undefined,
                },
                dataLabels: {
                    name: {
                        show: false,
                    },
                    value: {
                        show: false,
                    },
                },
                track: {
                    background: "#fff",
                    strokeWidth: "90%",
                    margin: 3, // margin is in pixels
                    dropShadow: {
                        enabled: true,
                        top: -2,
                        left: 0,
                        blur: 2,
                        opacity: 0.2,
                    },
                },
            },
        },
        colors: [
            "#51a6ea",
            "#30bcab",
            "#6c81f4",
            "#498cd5",
            "#a282e5",
            "#2e8bf3",
            "#8f98e5",
            "#718ae4",
        ],
        labels: labels,
        legend: {
            show: true,
            floating: true,
            fontSize: "12px",
            position: "left",
            labels: {
                useSeriesColors: true,
            },
            markers: {
                size: 0,
            },
            formatter: function (seriesName, opts) {
                return (
                    seriesName +
                    ":  " +
                    opts.w.globals.series[opts.seriesIndex] +
                    "%"
                );
            },
            itemMargin: {
                horizontal: 2,
                // vertical: 0
            },
        },
        responsive: [
            {
                breakpoint: 480,
                options: {
                    legend: {
                        position: "bottom",
                    },
                },
            },
        ],
    };
    if (series.length === 0) {
        return <Empty description={<span>No hay datos.</span>} />;
    } else {
        return (
            <div style={{ width: "100%" }}>
                <Col xs={24} lg={24}>
                    <Card
                        size="small"
                        className="card"
                        bodyStyle={{
                            padding: "0px",
                            background: "#F2F2F2",
                        }}
                    >
                        <Chart
                            options={options}
                            series={series}
                            type="radialBar"
                            style={{
                                maxWidth: "400px",
                                minWidth: "250px",
                                // heihgt: "auto",
                                margin: "auto",
                            }}
                        />
                    </Card>
                </Col>
            </div>
        );
    }
};

const renderChart = (Component, props) => ({ resultSet, error }) =>
    (resultSet && <Component resultSet={resultSet} props={props} />) || (
        <Spin />
    );

const GaugeRenderMultiple = (props) => {
    var measures;
    var mesCorte;
    var anioCorte;
    if (props.tipo === "comercial") {
        measures = [
            "CrediunoAsesorComercial.porcentajeViable",
            "CrediunoAsesorComercial.porcentajeFuelles",
            "CrediunoAsesorComercial.porcentajeDevolucion",
            "CrediunoAsesorComercial.porcentajeEntregas",
            "CrediunoAsesorComercial.porcentajeActivacion",
        ];
        anioCorte = "CrediunoAsesorComercial.anio";
        mesCorte = "CrediunoAsesorComercial.mes";
    } else {
        measures = [
            "CrediunoCascadaPdv.porcentajeViables",
            "CrediunoCascadaPdv.porcentajeFuelle",
            "CrediunoCascadaPdv.porcentajeDevueltos",
            "CrediunoCascadaPdv.porcentajeEntregas",
            "CrediunoCascadaPdv.porcentajeActivacion",
            "CrediunoCascadaPdv.porcentajeSeguros",
        ];
        anioCorte = "CrediunoCascadaPdv.anio";
        mesCorte = "CrediunoCascadaPdv.mes";
    }
    var filtros = [
        {
            dimension: anioCorte,
            operator: "contains",
            values: props.anio !== "" ? [props.anio] : [],
        },
        {
            dimension: mesCorte,
            operator: "contains",
            values: props.mes !== "" ? [props.mes] : [],
        },
    ];
    if (props.tipo === "comercial") {
        filtros = filtros.concat(
            {
                dimension: "Usuarios.idCedula",
                operator: "contains",
                values: props.cedula !== "" ? [props.cedula] : [],
            },
            {
                dimension: "Usuarios.zona",
                operator: "contains",
                values: props.zona !== "" ? [props.zona] : [],
            }
        );
    }
    if (props.tipo !== "comercial") {
        filtros = filtros.concat(
            {
                dimension: "CrediunoCascadaPdv.zona",
                operator: "contains",
                values: props.zona !== "" ? [props.zona] : [],
            },
            {
                dimension: "CrediunoCascadaPdv.puntoVenta",
                operator: "contains",
                values: props.puntoVenta !== "" ? [props.puntoVenta] : [],
            }
        );
    }
    return (
        <QueryRenderer
            query={{
                renewQuery: true,
                measures: measures,
                timeDimensions: [],
                filters: filtros,
            }}
            cubejsApi={cubejsApi}
            render={renderChart(gaugeRenderMultiple, props)}
        />
    );
};

export default GaugeRenderMultiple;
