import React, { useState } from "react";
import Tooltip from "react-png-tooltip";
import { Icon, Card, Row, Col } from "antd";
import SearchBar from "../components/crediUno/SearchBar";
import CascaderBar from "../components/crediUno/CascaderBar";
import PuntoVentaBar from "../components/crediUno/PuntoVentaBar";
import Embudo from "../components/crediUno/embudo/Embudo";
import Ranking from "../components/crediUno/ranking/Ranking";
import RankingColumn from "../components/crediUno/ranking/RankingColumn";
import FilterSearch from "../components/crediUno/FilterSearch";

const Crediuno = (props) => {
    const [cedula, setCedula] = useState("");
    const [puntoVenta, setPuntoVenta] = useState("");
    const [mes, setMes] = useState("04");
    const [anio, setAnio] = useState("2020");
    const [coordinador, setCoordinador] = useState("");
    const [filtroAsesor, setFiltroAsesor] = useState("");
    const [esComercial, setEsComercial] = useState(
        props.tipoCargo.includes("ASESOR COMERCIAL") ||
            props.tipoCargo.includes("ZONA") ||
            props.tipoCargo.includes("COORDINADOR")
    );
    const [esServicio, setEsServicio] = useState(
        props.tipoCargo.includes("ASESOR SERVICIO") ||
            props.tipoCargo.includes("ZONA") ||
            props.tipoCargo.includes("COORDINADOR")
    );
    const [esPartTime, setEsPartTime] = useState(
        props.tipoCargo.includes("PART TIME") ||
            props.tipoCargo.includes("ZONA") ||
            props.tipoCargo.includes("COORDINADOR")
    );
    const [esFullTime, setEsFullTime] = useState(
        props.tipoCargo.includes("FULL TIME") ||
            props.tipoCargo.includes("ZONA") ||
            props.tipoCargo.includes("COORDINADOR")
    );

    return (
        <div
            style={{
                width: "85%",
                margin: "auto",
                marginTop: "30px",
                // background: "#fafafa"
            }}
        >
            {/* ---------------------- Barras Busqueda ---------------------- */}
            <Row gutter={[15, 0]} type="flex" justify="center">
                <Col xs={24} lg={12} xl={5}>
                    <PuntoVentaBar
                        zona={props.zona}
                        cargo={props.cargo}
                        setPuntoVenta={setPuntoVenta}
                        coordinador={coordinador}
                        setCoordinador={setCoordinador}
                        tipoCargo={props.tipoCargo}
                        cedula={cedula}
                        nombre={props.nombre}
                    />
                </Col>
                <Col xs={24} lg={12} xl={6}>
                    <SearchBar
                        setCoordinador={setCoordinador}
                        coordinador={coordinador}
                        puntoVenta={puntoVenta}
                        setPuntoVenta={setPuntoVenta}
                        setCedula={setCedula}
                        setFiltroAsesor={setFiltroAsesor}
                        filtroAsesor={filtroAsesor}
                        cedula={cedula}
                        cargo={props.cargo}
                        tipoCargo={props.tipoCargo}
                        nombre={props.nombre}
                        zona={props.zona}
                        esComercial={esComercial}
                        esServicio={esServicio}
                        esPartTime={esPartTime}
                        esFullTime={esFullTime}
                    />
                </Col>
                <Col
                    xs={24}
                    sm={11}
                    lg={10}
                    xl={6}
                    style={{ marginBottom: "20px" }}
                >
                    <FilterSearch
                        cargo={props.cargo}
                        esComercial={esComercial}
                        setEsComercial={setEsComercial}
                        esServicio={esServicio}
                        setEsServicio={setEsServicio}
                        esPartTime={esPartTime}
                        setEsPartTime={setEsPartTime}
                        esFullTime={esFullTime}
                        setEsFullTime={setEsFullTime}
                    />
                </Col>
                <Col xs={24} sm={12} lg={10} xl={6}>
                    <CascaderBar
                        setMes={setMes}
                        mes={mes}
                        setAnio={setAnio}
                        anio={anio}
                    />
                </Col>
            </Row>

            {/* ---------------------- EMBUDO ---------------------- */}

            <Card
                title={
                    <div style={{ fontSize: "18px" }}>
                        <Icon type="funnel-plot" /> Embudo
                    </div>
                }
                className="card"
                style={{
                    width: "95%",
                    height: "auto",
                    margin: "auto",
                    marginBottom: "25px",
                }}
                extra={
                    <div align="right" fontSize="12px">
                        <Tooltip
                            className="GreyTooltip"
                            tooltip={<Icon type="info-circle" />}
                        >
                            <p align="left" style={{ marginBottom: "0em" }}>
                                Embudo: <br />- Monto en millones ($) y número
                                (#) de operaciones según estado.
                                <br /> - Variación con respecto al cierre del
                                mes anterior (Consultas, Entregadas y Venta
                                Total).
                                <br /> - Barra de cumplimiento: cumplimiento de
                                meta en el mes ppor estado
                                <br /> - Principales Indicadores del mes.
                            </p>
                        </Tooltip>
                    </div>
                }
            >
                <Embudo
                    mes={mes}
                    anio={anio}
                    cedula={cedula}
                    zona={props.zona}
                    puntoVenta={puntoVenta}
                    tipoCargo={props.tipoCargo}
                    filtroAsesor={filtroAsesor}
                />
            </Card>

            {/* ---------------------- Ranking Column Punto de Venta ---------------------- */}

            {!props.cargo &&
                !filtroAsesor.includes("ASESOR SERVICIO") &&
                !filtroAsesor.includes("ASESOR COMERCIAL") && (
                    <Card
                        title={
                            <div style={{ fontSize: "18px" }}>
                                <Icon type="dot-chart" /> Ranking Punto de Venta
                            </div>
                        }
                        className="card"
                        style={{
                            width: "95%",
                            height: "auto",
                            margin: "auto",
                            marginBottom: "25px",
                            position: "relative",
                        }}
                        extra={
                            <div align="right" fontSize="12px">
                                <Tooltip
                                    className="GreyTooltip"
                                    tooltip={<Icon type="info-circle" />}
                                >
                                    <p
                                        align="left"
                                        style={{ marginBottom: "0em" }}
                                    >
                                        Ranking Punto de Venta:
                                        <br /> - Eje Entregas : Número de
                                        tarjetas entregadas.
                                        <br /> - Eje Consultas: Número de
                                        consultas.
                                        <br /> - Tamaño de la burbuja: % de
                                        Activación.
                                        <br /> * Comparación con el promedio por
                                        Zona,Regional y Nacional.
                                        <br />
                                    </p>
                                </Tooltip>
                            </div>
                        }
                    >
                        <RankingColumn
                            mes={mes}
                            anio={anio}
                            cedula={cedula}
                            zona={props.zona}
                            region={props.region}
                            puntoVenta={puntoVenta}
                            cargo={props.cargo}
                            tipo="puntoVenta"
                        />
                    </Card>
                )}

            {/* ---------------------- Ranking Column Comercial ---------------------- */}

            {(props.tipoCargo.includes("ASESOR COMERCIAL") || !props.cargo) &&
                !filtroAsesor.includes("ASESOR SERVICIO") && (
                    <Card
                        title={
                            <div style={{ fontSize: "18px" }}>
                                <Icon type="dot-chart" /> Ranking Comercial
                            </div>
                        }
                        className="card"
                        style={{
                            width: "95%",
                            height: "auto",
                            margin: "auto",
                            marginBottom: "25px",
                            position: "relative",
                        }}
                        extra={
                            <div align="right" fontSize="12px">
                                <Tooltip
                                    className="GreyTooltip"
                                    tooltip={<Icon type="info-circle" />}
                                >
                                    Ranking Asesor Comercial:
                                    <br /> - Eje Entregas : Número de tarjetas
                                    entregadas.
                                    <br /> - Eje Consultas: Número de consultas.
                                    <br /> - Tamaño de la burbuja: % de
                                    Activación.
                                    <br /> * Comparación con el promedio por
                                    Zona,Regional y Nacional.
                                    <br />
                                </Tooltip>
                            </div>
                        }
                    >
                        <RankingColumn
                            mes={mes}
                            anio={anio}
                            cedula={cedula}
                            zona={props.zona}
                            region={props.region}
                            puntoVenta={puntoVenta}
                            cargo={props.cargo}
                            tipo="comercial"
                        />
                    </Card>
                )}

            {/* ---------------------- Ranking Servicio ---------------------- */}

            {(props.tipoCargo.includes("ASESOR SERVICIO") || !props.cargo) &&
                !filtroAsesor.includes("ASESOR COMERCIAL") && (
                    <Card
                        title={
                            <div style={{ fontSize: "18px" }}>
                                <Icon type="dot-chart" /> Ranking Servicio
                            </div>
                        }
                        className="card"
                        style={{
                            width: "95%",
                            height: "auto",
                            margin: "auto",
                            marginBottom: "25px",
                            position: "relative",
                        }}
                        extra={
                            <div align="right" fontSize="12px">
                                <Tooltip
                                    className="GreyTooltip"
                                    tooltip={<Icon type="info-circle" />}
                                >
                                    Ranking Asesor Servicio:
                                    <br /> - Eje Entregas : Número de tarjetas
                                    entregadas.
                                    <br /> - Eje Consultas: Número de consultas.
                                    <br /> - Tamaño de la burbuja: Número de
                                    seguros.
                                    <br /> * Comparación con el promedio por
                                    Zona,Regional y Nacional.
                                    <br />
                                </Tooltip>
                            </div>
                        }
                    >
                        <RankingColumn
                            mes={mes}
                            anio={anio}
                            cedula={cedula}
                            zona={props.zona}
                            region={props.region}
                            puntoVenta={puntoVenta}
                            tipo="servicio"
                        />
                    </Card>
                )}
        </div>
    );
};

export default Crediuno;
