import React from "react";
import { QueryRenderer } from "@cubejs-client/react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Card, Icon, Spin } from "antd";
import cubejsApi from "../../../cubejsApiBQ";
import NumberFormat from "react-number-format";

const useStyles = makeStyles({
    table: {
        // minWidth: 650
    },
    head: {
        background: "#E3E3E3",
    },
});

const colNames = [
    "Canal",
    "Valor Financiado",
    "Valor Avance",
    "Presupuesto Mes",
    "Presupuesto Al Corte",
    "Cumplimiento Mes",
    "Cumplimiento al Corte",
];

function compare(a, b) {
    // Use toUpperCase() to ignore character casing
    // Ordenar Total
    const canalA = a["CumplimientoAlDiaTigo.canal"].toUpperCase();
    const canalB = b["CumplimientoAlDiaTigo.canal"].toUpperCase();

    let comparison = 0;
    if (canalA > canalB) {
        comparison = 1;
    } else if (canalA < canalB) {
        comparison = -1;
    }
    return comparison;
}

const TablaRender = ({ resultSet }) => {
    const cols = resultSet.tableColumns().map((c) => c.key);
    // var total;
    // var sum = [0, 0, 0, 0, 0, 0];
    // resultSet.tablePivot().map(s => {
    //     for (var i = 0; i < 6; i++) {
    //         sum[i] += s[cols[i + 1]];
    //     }
    //     return (total = {
    //         "CumplimientoAlDiaTigo.canal": "Total",
    //         "CumplimientoAlDiaTigo.vrFinanciado": sum[0],
    //         "CumplimientoAlDiaTigo.vrAvance": sum[1],
    //         "CumplimientoAlDiaTigo.presupuestoMes": sum[2],
    //         "CumplimientoAlDiaTigo.presupuestoAlCorte": sum[3],
    //         "CumplimientoAlDiaTigo.cumplimientoMes": sum[4],
    //         "CumplimientoAlDiaTigo.cumplimientoAlCorte": sum[5]
    //     });
    // });
    const rows = resultSet.tablePivot().map((s) => s);
    // .concat(total);

    rows.sort(compare);

    const classes = useStyles();
    return (
        <Card
            title={
                <div style={{ fontSize: "18px" }}>
                    <Icon type="table" /> Ventas por Canal
                </div>
            }
        >
            <Table className={classes.table} aria-label="simple table">
                <TableHead className={classes.head}>
                    <TableRow>
                        {colNames.map((colNames, index) => (
                            <TableCell
                                key={index}
                                align="justify"
                                style={{
                                    color: "#3D3D3D",
                                }}
                            >
                                {colNames}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((rows, index) => {
                        var fontWeight = "normal";
                        if (index === 3) fontWeight = "bold";
                        return (
                            <TableRow key={index}>
                                {cols.map((cols, index) => {
                                    if (index !== 0) {
                                        return (
                                            <TableCell
                                                key={index}
                                                align="justify"
                                                style={{
                                                    fontWeight: fontWeight,
                                                }}
                                            >
                                                <NumberFormat
                                                    value={rows[cols]}
                                                    decimalScale={1}
                                                    displayType={"text"}
                                                    thousandSeparator={true}
                                                    prefix={
                                                        index !== 5 &&
                                                        index !== 6
                                                            ? "$"
                                                            : ""
                                                    }
                                                    suffix={
                                                        index === 5 ||
                                                        index === 6
                                                            ? "%"
                                                            : ""
                                                    }
                                                />
                                            </TableCell>
                                        );
                                    }
                                    return (
                                        <TableCell
                                            key={index}
                                            align="justify"
                                            style={{
                                                fontWeight: fontWeight,
                                            }}
                                        >
                                            {rows[cols]}
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </Card>
    );
};

const renderChart = (Component) => ({ resultSet, error }) =>
    (resultSet && <Component resultSet={resultSet} />) || <Spin />;

const TablaTigo = (props) => {
    return (
        <QueryRenderer
            query={{
                renewQuery: true,
                measures: [
                    "CumplimientoAlDiaTigo.vrFinanciado",
                    "CumplimientoAlDiaTigo.vrAvance",
                    "CumplimientoAlDiaTigo.presupuestoMes",
                    "CumplimientoAlDiaTigo.presupuestoAlCorte",
                    "CumplimientoAlDiaTigo.cumplimientoMes",
                    "CumplimientoAlDiaTigo.cumplimientoAlCorte",
                ],
                dimensions: ["CumplimientoAlDiaTigo.canal"],
                filters: [
                    {
                        dimension: "CumplimientoAlDiaTigo.mesCorte",
                        operator: "contains",
                        values: props.fecha !== "" ? [props.fecha] : [],
                    },
                    {
                        dimension: "CumplimientoAlDiaTigo.cargo",
                        operator: "contains",
                        values: ["JEFE"],
                    },
                ],
            }}
            cubejsApi={cubejsApi}
            render={renderChart(TablaRender)}
        />
    );
};

export default TablaTigo;
