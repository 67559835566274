import React, { Component } from "react";
import Steps from "./Steps";
import { Col, Card, Typography } from "antd";
// import axios from "axios";
// import moment from "moment";

const { Title } = Typography;

class HomeAdmin extends Component {
    constructor(props) {
        super(props);

        this.state = {
            Admin: "",
        };
    }

    // convert(fecha) {
    //     let newDate;
    //     moment().add();
    //     newDate = moment(fecha)
    //         .add(1, "days")
    //         .format("L");

    //     return newDate;
    // }

    render() {
        return (
            <div
                xs={16}
                style={{ width: "70%", margin: "auto", marginBottom: "50px" }}
            >
                <Title
                    level={3}
                    style={{ margin: "0px 0px 30px 0px", textAlign: "center" }}
                >
                    Bienvenido al Cargador de Información
                </Title>
                <Steps />
            </div>
        );
    }
}

export default HomeAdmin;
