import React from "react";
import { QueryRenderer } from "@cubejs-client/react";
import { Spin } from "antd";
import { Pie } from "react-chartjs-2";
import cubejsApi from "../../../cubejsApiBQ";
import "chartjs-plugin-labels";

const COLORS_SERIES = [
    "#357b72",
    "#7785b1",
    "#3d8aa9",
    "#ab7a9e",
    "#4fafaa",
    "#64abd8",
    "#a279c3",
    "#6286d3",
    "#c96a9c",
];

const pieCartera = ({ resultSet }) => {
    const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 1,
    });
    const data = {
        labels: resultSet.categories().map((c) => c.category),
        datasets: resultSet.series().map((s) => ({
            label: s.title,
            data: s.series.map((r) => r.value),
            backgroundColor: COLORS_SERIES,
            hoverBackgroundColor: COLORS_SERIES,
        })),
    };
    const options = {
        plugins: {
            labels: {
                fontColor: "#fff",
            },
        },
        tooltips: {
            callbacks: {
                label: function (tooltipItem, data) {
                    var dataset = data.datasets[tooltipItem.datasetIndex];
                    var meta = dataset._meta[Object.keys(dataset._meta)[0]];
                    var total = meta.total;
                    var currentValue = dataset.data[tooltipItem.index];
                    var percentage = parseFloat(
                        ((currentValue / total) * 100).toFixed(1)
                    );
                    return (
                        formatter.format(currentValue) +
                        " (" +
                        percentage +
                        "%)"
                    );
                },
                title: function (tooltipItem, data) {
                    return data.labels[tooltipItem[0].index];
                },
            },
        },
    };
    return <Pie data={data} options={options} width={80} height={80} />;
};

const renderChart = (Component) => ({ resultSet, error }) =>
    (resultSet && <Component resultSet={resultSet} />) || <Spin />;

const PieCartera = (props) => (
    <QueryRenderer
        query={{
            measures: ["ResumenCarteraHistorico.montoCartera"],
            filters: [
                {
                    dimension: "ResumenCarteraHistorico.fechaCorte",
                    operator: "contains",
                    values: props.fecha !== "" ? [props.fecha] : [],
                },
                {
                    dimension: "ResumenCarteraHistorico.zona",
                    operator: "contains",
                    values: props.zona !== "" ? [props.zona] : [],
                },
                {
                    dimension: "ResumenCarteraHistorico.tipoProducto",
                    operator: "contains",
                    values: ["libranzas"],
                },
            ],
            dimensions: ["ResumenCarteraHistorico.tipoConvenio"],
        }}
        cubejsApi={cubejsApi}
        render={renderChart(pieCartera)}
    />
);

export default PieCartera;
