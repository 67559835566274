import React from "react";
import { QueryRenderer } from "@cubejs-client/react";
import { Spin, Row, Typography } from "antd";
import NumberFormat from "react-number-format";
import cubejsApi from "../../../../cubejsApiBQ";

const { Title } = Typography;

var valor;

const cantidades = ({ resultSet, props }) => {
    const name = props.cantidades;
    valor = resultSet.loadResponse.data[0][name[props.index]];
    if (valor === null) {
        valor = "-";
    }
    return (
        <Row gutter={[0, 0]} type="flex" justify="center">
            <Title
                style={{
                    fontSize: "16px",
                }}
            >
                <NumberFormat
                    value={valor}
                    decimalScale={1}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"#"}
                />
            </Title>
        </Row>
    );
};

const renderChart = (Component, props) => ({ resultSet, error }) =>
    (resultSet && <Component resultSet={resultSet} props={props} />) || (
        <Spin />
    );

const Cantidades = (props) => {
    const measures = [
        "ResumenMicrocredito.cantidadCarteraVigente",
        "ResumenMicrocredito.cantidadCarteraMora30",
        "ResumenMicrocredito.cantidadCarteraMoraTotal",
        "ResumenMicrocredito.cantidadCarteraMora6190",
        "ResumenMicrocredito.cantidadCarteraMoraMayor90",
    ];

    return (
        <QueryRenderer
            query={{
                renewQuery: true,
                measures: measures,
                timeDimensions: [],
                filters: [
                    {
                        dimension: props.fechaCorte,
                        operator: "contains",
                        values: props.fecha !== "" ? [props.fecha] : [],
                    },
                    {
                        dimension: "Usuarios.idCedula",
                        operator: "contains",
                        values: props.cedula !== "" ? [props.cedula] : [],
                    },
                    {
                        dimension: "Usuarios.zona",
                        operator: "contains",
                        values: props.zona !== "" ? [props.zona] : [],
                    },
                ],
                dimensions: [],
            }}
            cubejsApi={cubejsApi}
            render={renderChart(cantidades, props)}
        />
    );
};

export default Cantidades;
