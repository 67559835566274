import React from "react";
import { QueryRenderer } from "@cubejs-client/react";
import { Spin, Col, Card } from "antd";
import Chart from "react-apexcharts";
import cubejsApi from "../../../cubejsApiBQ";

const gaugeRender = ({ resultSet, props }) => {
    const LABELS = ["Activación Tit.", "Seguros"];

    return (
        <div style={{ width: "100%" }}>
            {resultSet.seriesNames().map((s, index) => {
                const options = {
                    grid: {
                        padding: {
                            top: 0,
                            right: 0,
                            botom: 0,
                            left: 0,
                        },
                    },
                    chart: {
                        height: "200px",
                        offsetY: 15,
                        toolbar: {
                            show: false,
                        },
                    },
                    plotOptions: {
                        radialBar: {
                            startAngle: -135,
                            endAngle: 225,
                            hollow: {
                                margin: 0,
                                size: "70%",
                                background: "#fff",
                                image: undefined,
                                imageOffsetX: 0,
                                imageOffsetY: 0,
                                position: "front",
                                dropShadow: {
                                    enabled: true,
                                    top: 3,
                                    left: 0,
                                    blur: 4,
                                    opacity: 0.24,
                                },
                            },
                            track: {
                                background: "#fff",
                                strokeWidth: "67%",
                                margin: 0, // margin is in pixels
                                dropShadow: {
                                    enabled: true,
                                    top: -3,
                                    left: 0,
                                    blur: 4,
                                    opacity: 0.35,
                                },
                            },

                            dataLabels: {
                                showOn: "always",
                                name: {
                                    offsetY: -10,
                                    show: true,
                                    color: "#888",
                                    fontSize: "15px",
                                },
                                value: {
                                    formatter: function (val) {
                                        return parseInt(val) + "%";
                                    },
                                    color: "#111",
                                    fontSize: "20px",
                                    show: true,
                                },
                            },
                        },
                    },
                    fill: {
                        type: "gradient",
                        gradient: {
                            shade: "dark",
                            type: "horizontal",
                            shadeIntensity: 0.5,
                            gradientToColors: ["#ABE5A1"],
                            inverseColors: true,
                            opacityFrom: 1,
                            opacityTo: 1,
                            stops: [0, 100],
                        },
                    },
                    stroke: {
                        lineCap: "round",
                    },
                    labels: [LABELS[index]],
                };
                var series;
                if (resultSet.totalRow()[s.key] === null) {
                    series = [0];
                } else {
                    series = [resultSet.totalRow()[s.key]];
                }
                return (
                    <Col key={index} xs={24} sm={12} lg={12}>
                        <Card
                            size="small"
                            className="card"
                            bodyStyle={{
                                padding: "0px",
                            }}
                        >
                            <Chart
                                options={options}
                                series={series}
                                type="radialBar"
                                style={{ background: "#F2F2F2" }}
                                height={"200px"}
                            />
                        </Card>
                    </Col>
                );
            })}
        </div>
    );
};

const renderChart = (Component, props) => ({ resultSet, error }) =>
    (resultSet && <Component resultSet={resultSet} props={props} />) || (
        <Spin />
    );

const GaugeRender = (props) => (
    <QueryRenderer
        query={{
            renewQuery: true,
            measures: [
                "CrediunoAsesorServicio.porcentajeActivacion",
                "CrediunoAsesorServicio.porcentajeSeguros",
            ],
            filters: [
                {
                    dimension: "CrediunoAsesorServicio.anio",
                    operator: "contains",
                    values: props.anio !== "" ? [props.anio] : [],
                },
                {
                    dimension: "CrediunoAsesorServicio.mes",
                    operator: "contains",
                    values: props.mes !== "" ? [props.mes] : [],
                },
                {
                    dimension: "Usuarios.idCedula",
                    operator: "contains",
                    values: props.cedula !== "" ? [props.cedula] : [],
                },
                {
                    dimension: "Usuarios.zona",
                    operator: "contains",
                    values: props.zona !== "" ? [props.zona] : [],
                },
            ],
            dimensions: [],
        }}
        cubejsApi={cubejsApi}
        render={renderChart(gaugeRender, props)}
    />
);

export default GaugeRender;
