import React from "react";
import { QueryRenderer } from "@cubejs-client/react";
import NumberFormat from "react-number-format";
import { Row, Col, Typography, Card } from "antd";
import { Spin } from "antd";
import cubejsApi from "../../../cubejsApiBQ";
import CantRender2 from "./CantRender2";
import PercentRender2 from "./PercentRender2";

import "../../../styles/boxRender.css";

const { Title, Text } = Typography;

const COLORS_SERIES = ["#66BFFF", "#FFCF59", "#F4AF59", "#A3F0A1"];
const NAME_SERIES = ["Fuelles", "Radicados", "Devueltos", "Preaprobados"];

const boxRender2 = ({ resultSet, props }) => {
    return (
        <Row gutter={[0, 0]} type="flex" justify="space-between" align="middle">
            {resultSet.seriesNames().map((s, index) => (
                <Col key={index} xs={23} lg={5}>
                    <Card
                        size="small"
                        className="topCard"
                        style={{
                            borderTop: "3px solid " + COLORS_SERIES[index],
                        }}
                        bodyStyle={{ padding: "5px 10px 0px 10px" }}
                    >
                        <Row style={{ marginBottom: "5px" }}>
                            <Col>
                                <Text className="cardTitle">
                                    {NAME_SERIES[index]}
                                </Text>
                            </Col>
                        </Row>
                        <Row
                            gutter={0}
                            type="flex"
                            justify="space-between"
                            align="middle"
                        >
                            <Col xs={12}>
                                <Title
                                    style={{
                                        fontSize: "16px",
                                    }}
                                >
                                    <NumberFormat
                                        value={resultSet.totalRow()[s.key]}
                                        decimalScale={1}
                                        displayType={"text"}
                                        thousandSeparator={true}
                                        prefix={"$"}
                                    />
                                </Title>
                            </Col>
                            <Col xs={3}>
                                <CantRender2 {...props} index={index} />
                            </Col>
                            <Col xs={6}>
                                <PercentRender2 {...props} index={index} />
                            </Col>
                        </Row>
                    </Card>
                </Col>
            ))}
        </Row>
    );
};

const renderChart = (Component, props) => ({ resultSet, error }) =>
    (resultSet && <Component resultSet={resultSet} props={props} />) || (
        <Spin />
    );

const BoxRender2 = (props) => {
    return (
        <QueryRenderer
            query={{
                renewQuery: true,
                measures: [
                    "BaseResumenColocacion.montoEstadoFuelles",
                    "BaseResumenColocacion.montoEstadoRadicado",
                    "BaseResumenColocacion.montoEstadoDevuelto",
                    "BaseResumenColocacion.montoEstadoPreaprobado",
                ],
                timeDimensions: [],
                dimensions: [],
                segments: [],
                filters: [
                    {
                        dimension: "BaseResumenColocacion.fechaCorte",
                        operator: "contains",
                        values: props.fecha !== "" ? [props.fecha] : [],
                    },
                    {
                        dimension: "Usuarios.idCedula",
                        operator: "contains",
                        values: props.cedula !== "" ? [props.cedula] : [],
                    },
                    {
                        dimension: "BaseResumenColocacion.zona",
                        operator: "contains",
                        values: props.zona !== "" ? [props.zona] : [],
                    },
                ],
            }}
            cubejsApi={cubejsApi}
            render={renderChart(boxRender2, props)}
        />
    );
};

export default BoxRender2;
