import React from "react";
import { QueryRenderer } from "@cubejs-client/react";
import { Spin, Col } from "antd";
import Chart from "react-apexcharts";
import cubejsApi from "../../../../cubejsApiBQ";

const gaugeCartera = ({ resultSet, props }) => {
    const LABELS = [
        "Meta Ind. Cartera 30",
        "Meta Ind. Cartera Total",
        "Indicador Mora 30",
        "Indicador Mora Total",
    ]; //#cb2d3e #ef473a  #36D1DC #5B86E5
    const COLORSI = ["#36D1DC", "#36D1DC", "#ff9068", "#ff9068"];
    const COLORSF = ["#5B86E5", "#5B86E5", "#fd746c", "#fd746c"];

    return (
        <div
            style={{
                width: "100%",
            }}
        >
            {resultSet.seriesNames().map((s, index) => {
                const options = {
                    chart: {
                        offsetY: 30,
                        toolbar: {
                            show: false,
                        },
                    },
                    colors: [COLORSF[index]],
                    plotOptions: {
                        radialBar: {
                            startAngle: -135,
                            endAngle: 135,
                            hollow: {
                                margin: 0,
                                size: "67%",
                                background: "#fff",
                                image: undefined,
                                imageOffsetX: 0,
                                imageOffsetY: 0,
                                position: "front",
                            },
                            track: {
                                strokeWidth: "70%",
                                margin: 0, // margin is in pixels
                                dropShadow: {
                                    enabled: true,
                                    top: -1,
                                    left: 0,
                                    bottom: -1,
                                    blur: 3,
                                    opacity: 0.35,
                                },
                            },

                            dataLabels: {
                                showOn: "always",
                                name: {
                                    offsetY: 80,
                                    show: true,
                                    color: "#888",
                                    fontSize: "16px",
                                },
                                value: {
                                    formatter: function (val) {
                                        return parseFloat(val).toFixed(2) + "%";
                                    },
                                    offsetY: -10,
                                    color: "#111",
                                    fontSize: "20px",
                                    show: true,
                                },
                            },
                        },
                    },
                    fill: {
                        type: "gradient",
                        gradient: {
                            shade: "dark",
                            type: "horizontal",
                            shadeIntensity: 0.5,
                            gradientToColors: [COLORSI[index]],
                            inverseColors: true,
                            opacityFrom: 1,
                            opacityTo: 4,
                            stops: [0, 100],
                        },
                    },
                    stroke: {
                        lineCap: "round",
                    },
                    labels: [LABELS[index]],
                };
                var series;
                if (resultSet.totalRow()[s.key] === null) {
                    series = [0];
                } else {
                    series = [resultSet.totalRow()[s.key]];
                    //series = [100];
                }

                return (
                    <Col key={index} xs={24} lg={12}>
                        <Chart
                            style={{ margin: "-50px" }}
                            options={options}
                            series={[series]}
                            height="200px"
                            type="radialBar"
                        />
                    </Col>
                );
            })}
        </div>
    );
};

const renderChart = (Component, props) => ({ resultSet, error }) =>
    (resultSet && <Component resultSet={resultSet} props={props} />) || (
        <Spin />
    );

const GaugeCartera = (props) => (
    <QueryRenderer
        query={{
            measures: [
                "ResumenMicrocredito.promedioMeta30",
                "ResumenMicrocredito.promedioMetaTotal",
                "ResumenMicrocredito.indicadorCarteraVencida30",
                "ResumenMicrocredito.indicadorCarteraMoraTotal",
            ],
            filters: [
                {
                    dimension: "ResumenMicrocredito.fechaCorte",
                    operator: "contains",
                    values: props.fecha !== "" ? [props.fecha] : [],
                },
                {
                    dimension: "Usuarios.idCedula",
                    operator: "contains",
                    values: props.cedula !== "" ? [props.cedula] : [],
                },
                {
                    dimension: "Usuarios.zona",
                    operator: "contains",
                    values: props.zona !== "" ? [props.zona] : [],
                },
                // {
                //   dimension: "ResumenMicrocredito.plaza",
                //   operator: "contains",
                //   values: [props.plazas]
                // }
            ],
            dimensions: [],
        }}
        cubejsApi={cubejsApi}
        render={renderChart(gaugeCartera, props)}
    />
);

export default GaugeCartera;
