import React from "react";
import { QueryRenderer } from "@cubejs-client/react";
import { Col, Spin, Row, Statistic, Typography } from "antd";
import cubejsApi from "../../../cubejsApiBQ";

var valor;
var color;
const precision = [2, 0, 0];
const prefix = ["$", "#", "#"]; //"#";
const { Text } = Typography;
const faltaGauge = ({ resultSet, props }) => {
    const name = props.faltan;
    valor = resultSet.loadResponse.data[0][name[props.index]];
    // valor = valor * -1;
    if (valor === null) {
        valor = "-";
        color = "#89968a";
    } else if (valor < 0) color = "#13cf1c";
    else if (valor > 0) color = "#cf1322";
    else color = "#769995";
    return (
        <Row gutter={[0, 0]} type="flex" justify="center">
            <Col
                span={24}
                style={{
                    display: "flex",
                    justifyContent: "center",
                }}
            >
                <Text
                    style={{
                        fontSize: "14px",
                        textAlign: "center",
                    }}
                >
                    Falta para la meta
                </Text>
            </Col>
            <Col
                style={{
                    display: "flex",
                    justifyContent: "center",
                }}
            >
                <Statistic
                    value={valor}
                    precision={precision[props.index]}
                    valueStyle={{ color: color }}
                    thousandSeparator={true}
                    prefix={prefix[props.index]}
                />
            </Col>
        </Row>
    );
};
const renderChart = (Component, props) => ({ resultSet, error }) =>
    (resultSet && <Component resultSet={resultSet} props={props} />) || (
        <Spin />
    );

const FaltaGauge = (props) => {
    const measures = [
        "ResumenMicrocredito.faltaMonto",
        "ResumenMicrocredito.faltaCantidadOperaciones",
        "ResumenMicrocredito.faltaSeguros",
    ];

    return (
        <QueryRenderer
            query={{
                renewQuery: true,
                measures: measures,
                timeDimensions: [],
                filters: [
                    {
                        dimension: "ResumenMicrocredito.fechaCorte",
                        operator: "contains",
                        values: props.fecha !== "" ? [props.fecha] : [],
                    },
                    {
                        dimension: "Usuarios.idCedula",
                        operator: "contains",
                        values: props.cedula !== "" ? [props.cedula] : [],
                    },
                    {
                        dimension: "ResumenMicrocredito.plaza",
                        operator: "contains",
                        values: props.plazas !== "" ? [props.plazas] : [],
                    },
                    {
                        dimension: "Usuarios.zona",
                        operator: "contains",
                        values: props.zona !== "" ? [props.zona] : [],
                    },
                ],
                dimensions: [],
            }}
            cubejsApi={cubejsApi}
            render={renderChart(faltaGauge, props)}
        />
    );
};

export default FaltaGauge;
