import React from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
// import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
// import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import logo from "../img/logo.png";
import credifinanciera from "../img/logo.png";

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {"Copyright © "}
            {new Date().getFullYear()}
            {"."}
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    "@global": {
        body: {
            backgroundColor: theme.palette.common.white,
        },
    },
    paper: {
        marginTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "#fff",
        padding: "30px",
        boxShadow: "1px 1px 1px #ccc",
        borderRadius: "5px",
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        padding: "10px",
        background: "#3B47B3",
        margin: theme.spacing(4, 0, 1),
    },
    logo: {
        padding: "10px",
    },

    notchedOutline: {},

    focused: {
        "& $notchedOutline": {
            borderColor: "#3B47B3!important",
            //borderColor: "#ccc!important",
        },
    },
}));

const SignIn = (props) => {
    const classes = useStyles();

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <div className={classes.logo}>
                    <img
                        width="300px"
                        src={credifinanciera}
                        alt="credifinanciera"
                    />
                </div>
                <form className={classes.form} noValidate>
                    <TextField
                        InputProps={{
                            classes: {
                                notchedOutline: classes.notchedOutline,
                                focused: classes.focused,
                            },
                        }}
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        //id="email"
                        label="Correo"
                        type="email"
                        placeholder="Ejemplo@email.com"
                        value={props.user}
                        onChange={(e) =>
                            props.updateParentState({
                                user: e.target.value,
                            })
                        }
                        onKeyDown={(e) => {
                            if (e.key === "Enter") props.onPressLogin();
                        }}
                    />
                    <TextField
                        InputProps={{
                            classes: {
                                notchedOutline: classes.notchedOutline,
                                focused: classes.focused,
                            },
                        }}
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        label="Contraseña"
                        type="password"
                        value={props.password}
                        onChange={(e) =>
                            props.updateParentState({
                                password: e.target.value,
                            })
                        }
                        onKeyDown={(e) => {
                            if (e.key === "Enter") props.onPressLogin();
                        }}
                    />
                    {/* <FormControlLabel
                            control={<Checkbox value="remember" color="primary" />}
                            label="Remember me"
                        /> */}
                    <Button
                        //type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={props.onPressLogin}
                    >
                        Ingresar
                    </Button>

                    {/* <Grid container>
                        <Grid item xs>
                            <Link href="#" variant="body2">
                                Forgot password?
                            </Link>
                        </Grid>
                        <Grid item>
                            <Link href="#" variant="body2">
                                {"Don't have an account? Sign Up"}
                            </Link>
                        </Grid>
                    </Grid> */}
                </form>
            </div>
            <Box mt={5}>
                <Typography component="h6" align="center">
                    <Link color="inherit" href="https://d4d.com.co/">
                        Powered By <img width="100px" src={logo} alt="logo" />
                    </Link>{" "}
                </Typography>
                <Copyright />
            </Box>
        </Container>
    );
};

export default SignIn;
