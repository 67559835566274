import React from "react";
import { QueryRenderer } from "@cubejs-client/react";
import { Spin, Row, Col, Card, Typography } from "antd";
import NumberFormat from "react-number-format";
import cubejsApi from "../../../cubejsApiBQ";

import "../../../styles/boxRender.css";
import CantidadesCredipoliza from "./CantidadesCredipoliza";
import PorcentajesCredipoliza from "./PorcentajesCredipoliza";

const { Title, Text } = Typography;

const COLORS_SERIES = [
    "#b1abe9",
    "#72bae8",
    "#8dc1ca",
    "#afb9cb",
    "#a4beb8",
    "#a4badd",
    "#5cc8d6",
    "#80c6b8",
    "#98c3a6",
    "#96c593",
];

const medidasCredipoliza = ({
    resultSet,
    props,
    fechaCorte,
    porcentajes,
    cantidades,
}) => {
    const NAME_SERIES = [
        "Radicaciones",
        "Análisis Fabrica",
        "Espera Pagaré Desmat.",
        "Pin Generado",
        "Otros Estados",
        "Desembolsos",
    ];

    return (
        <Row gutter={[0, 20]} type="flex" justify="space-around">
            {resultSet.seriesNames().map((s, index) => {
                return (
                    <Col key={index} xs={24} lg={11}>
                        <Card
                            size="small"
                            className="topCard"
                            style={{
                                borderTop: "3px solid " + COLORS_SERIES[index],
                            }}
                            bodyStyle={{ padding: "5px 10px 0px 10px" }}
                        >
                            <Row style={{ marginBottom: "5px" }}>
                                <Col>
                                    <Text className="cardTitle">
                                        {NAME_SERIES[index]}
                                    </Text>
                                </Col>
                            </Row>
                            <Row
                                gutter={0}
                                type="flex"
                                justify="space-between"
                                align="middle"
                            >
                                <Col xs={10}>
                                    <Title
                                        style={{
                                            fontSize: "16px",
                                        }}
                                    >
                                        <NumberFormat
                                            value={resultSet.totalRow()[s.key]}
                                            decimalScale={1}
                                            displayType={"text"}
                                            thousandSeparator={true}
                                            prefix={"$"}
                                        />
                                    </Title>
                                </Col>
                                <Col xs={3}>
                                    <CantidadesCredipoliza
                                        {...props}
                                        index={index}
                                        cantidades={cantidades}
                                        fechaCorte={fechaCorte}
                                        cedula={props.cedula}
                                        zona={props.zona}
                                    />
                                </Col>
                                <Col xs={6}>
                                    <PorcentajesCredipoliza
                                        {...props}
                                        index={index}
                                        porcentajes={porcentajes}
                                        fechaCorte={fechaCorte}
                                        cedula={props.cedula}
                                        zona={props.zona}
                                    />
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                );
            })}
        </Row>
    );
};

const renderChart = (
    Component,
    props,
    fechaCorte,
    porcentajes,
    cantidades
) => ({ resultSet, error }) =>
    (resultSet && (
        <Component
            resultSet={resultSet}
            props={props}
            fechaCorte={fechaCorte}
            porcentajes={porcentajes}
            cantidades={cantidades}
        />
    )) || <Spin />;

const MedidasCredipoliza = (props) => {
    const measures = [
        "ResumenCp.vrTotal",
        "ResumenCp.vrAnalisisFabrica",
        "ResumenCp.vrEsperaPagareDesma",
        "ResumenCp.vrPINGenerado",
        "ResumenCp.vrOtrosEstados",
        "ResumenCp.vrDesembolsos",
    ];
    const fechaCorte = "ResumenCp.fechaCorte";
    const cantidades = [
        "ResumenCp.cantTotal",
        "ResumenCp.cantAnalisisFabrica",
        "ResumenCp.cantEsperaPagareDesma",
        "ResumenCp.cantPINGenerado",
        "ResumenCp.cantOtrosEstados",
        "ResumenCp.cantDesembolsos",
    ];
    const porcentajes = [
        "",
        "",
        "",
        "",
        "",
        "ResumenCp.porcentajeComparacionDesembolsos",
    ];

    return (
        <QueryRenderer
            query={{
                renewQuery: true,
                measures: measures,
                timeDimensions: [],
                filters: [
                    {
                        dimension: fechaCorte,
                        operator: "contains",
                        values: props.fecha !== "" ? [props.fecha] : [],
                    },
                    {
                        dimension: "Usuarios.idCedula",
                        operator: "contains",
                        values: props.cedula !== "" ? [props.cedula] : [],
                    },
                    {
                        dimension: "Usuarios.zona",
                        operator: "contains",
                        values: props.zona !== "" ? [props.zona] : [],
                    },
                ],
                dimensions: [],
            }}
            cubejsApi={cubejsApi}
            render={renderChart(
                medidasCredipoliza,
                props,
                fechaCorte,
                porcentajes,
                cantidades
            )}
        />
    );
};

export default MedidasCredipoliza;
