import React from "react";
import { QueryRenderer } from "@cubejs-client/react";
import { Spin } from "antd";
import { AutoComplete } from "antd";
import cubejsApi from "../../cubejsApiBQ";

const searchMicrocredito = ({ resultSet, props }) => {
    var dataSource = [];
    var cedulas = [];
    resultSet.loadResponse.data.map((result, index) => {
        cedulas[index] = result["ResumenMicrocredito.identificacionAsesor"];

        return (dataSource[index] = result["ResumenMicrocredito.asesor"]);
    });

    if (props.cargo) {
        const cedula = cedulas[dataSource.indexOf(props.nombre)];
        props.setCedula(cedula);
    }

    return (
        <AutoComplete
            style={{
                width: "100%",
                marginBottom: "25px",
            }}
            disabled={props.cargo}
            allowClear
            size="large"
            dataSource={dataSource}
            placeholder="Asesor"
            defaultValue={dataSource[cedulas.indexOf(props.cedula)]}
            filterOption={(inputValue, option) => {
                return (
                    option.props.children
                        .toUpperCase()
                        .indexOf(inputValue.toUpperCase()) !== -1
                );
            }}
            onSelect={(value) => {
                props.setCedula(cedulas[dataSource.indexOf(value)]);
            }}
            onSearch={() => {
                props.setCedula("");
                props.setPunto("");
                props.setPlazas("");
            }}
        />
    );
};

const renderChart = (Component, props) => ({ resultSet, error }) =>
    (resultSet && <Component resultSet={resultSet} props={props} />) || (
        <Spin />
    );

const SearchMicrocredito = (props) => (
    <QueryRenderer
        query={{
            renewQuery: true,
            measures: [],
            timeDimensions: [],
            dimensions: [
                "ResumenMicrocredito.asesor",
                "ResumenMicrocredito.identificacionAsesor",
            ],
            segments: [],
            filters: [
                {
                    dimension: "ResumenMicrocredito.asesor",
                    operator: "notContains",
                    values: ["0"],
                },
                {
                    dimension: "Usuarios.zona",
                    operator: "contains",
                    values: props.zona !== "" ? [props.zona] : [],
                },
                {
                    dimension: "Usuarios.producto",
                    operator: "contains",
                    values: ["microcredito"],
                },
                {
                    dimension: "ResumenMicrocredito.zona",
                    operator: "contains",
                    values: props.punto !== "" ? [props.punto] : [],
                },
            ],
        }}
        cubejsApi={cubejsApi}
        render={renderChart(searchMicrocredito, props)}
    />
);

export default SearchMicrocredito;
